import { SysDataTypeId, SysParameterTypeId } from '../../../api/generated';

export type EntryPointUpdate = {
  entryPointId: number;
  name: string;
  description: string;
  masterTemplateId: number;
  workflowId: number;
  queryStringName: string;
  parameters: EntryPointParameter[];
};

export enum EntryPointEditFormTab {
  BasicInfo = 'BasicInfo',
  Parameters = 'Parameters',
  DecisionParameters = 'DecisionParameters',
}

export type EntryPointParameter = {
  id?: number;
  name?: string;
  description?: string | null;
  isDecision?: boolean;
  type?: SysDataTypeId;
  sysParameterTypeId?: SysParameterTypeId;
};

export enum EntrypointViewTab {
  Issues = 0,
  Details = 1,
}
