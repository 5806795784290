import React, { useCallback, useEffect } from 'react';

import { useTokenContext } from '../../providers/TokenProvider';
import { useRoles } from '../../hooks/useRoles';
import useAppNavigate from '../../hooks/useAppNavigate';
import { AppRoute } from '../../route/AppRoute';
import Overlay from '../../components/Overlay';

const Logout = () => {
  const navigate = useAppNavigate();
  const { clearToken } = useTokenContext();
  const { clearRoles } = useRoles();

  const handleLogout = useCallback(() => {
    clearToken();
    clearRoles();

    navigate(AppRoute.Home);
  }, [clearToken, clearRoles, navigate]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <Overlay label={'Logout in progess...'} />;
};

export default Logout;
