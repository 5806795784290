import { MasterTemplate, useCreateMasterTemplateMutation } from '../../../../api/generated';
import useToast from '../../../../hooks/useToast';
import SidePanel from '../../../../components/SidePanel';
import MasterTemplateEditForm from './MasterTemplateEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

export default function MasterTemplateAdd(props: Props) {
  const [createMasterTemplate, { isLoading }] = useCreateMasterTemplateMutation();

  const toast = useToast();

  const defaultMasterTemplate: Partial<MasterTemplate> = {
    masterTemplateId: 0,
    name: '',
    description: '',
  };

  return (
    <SidePanel title="Create Master Template" onClose={props.onSidebarClose} visible={props.sidebarVisible} size="wide">
      <MasterTemplateEditForm
        masterTemplate={defaultMasterTemplate as MasterTemplate}
        isNew={true}
        isLoading={isLoading}
        onConfirm={async (masterTemplate: any) => {
          try {
            await createMasterTemplate({
              input: {
                name: masterTemplate.name,
                description: masterTemplate.description,
              },
            })
              .unwrap()
              .then(async (_) => {
                toast.success(`Master Template '${masterTemplate.name}' has been created successfully.`);
                props.onConfirm();
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={props.onSidebarClose}
      />
    </SidePanel>
  );
}
