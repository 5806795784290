export function DependencyDetail({ dependency, type }: { dependency: any[]; type: string }) {
  if (dependency.length > 0) {
    return (
      <ul>
        {dependency.map((st, index) => (
          <li key={`${type}-${index}`}>{st?.name}</li>
        ))}
      </ul>
    );
  }
  return <>-</>;
}
