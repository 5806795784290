import { ActionType } from '../../../../common/actionTypes';
import FaMousePointerIcon from '../../../../components/Icons/FaMousePointerIcon';
import NoItemSelected from '../../../../components/NoItemSelected';
import LinkAdd from './LinkAdd';
import LinkEdit from './LinkEdit';
import LinkView from './LinkView';

type Props = {
  linkId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

function LinkSidebar({ linkId, sidebarVisible, onSidebarClose, action, setAction, refetch }: Props) {
  if (action === ActionType.Add) {
    return (
      <LinkAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (linkId && action === ActionType.Edit) {
    return (
      <LinkEdit
        linkId={linkId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (linkId && action === ActionType.View) {
    return <LinkView id={linkId} />;
  } else {
    return (
      <NoItemSelected icon={<FaMousePointerIcon />} title={'No link selected'} note={'Select any to see its details'} />
    );
  }
}

export default LinkSidebar;
