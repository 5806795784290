import { useWorkflowsQuery, Workflow } from '../../../../api/generated';
import { useState } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { WorkflowEditFormTab } from './WorkflowsTypes';
import { Formik } from 'formik';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import BasicInfoTab from './BasicInfoTab';
import { useValidationSchema } from './useValidationSchema';
import WorkflowType from './BasicInfoTab/components/WorkflowType';

type Props = {
  workflow: Workflow;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (workflow: any) => void;
  onClose: () => void;
};

function WorkflowEditForm({ workflow, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element | null {
  const { data: workflows } = useWorkflowsQuery();
  const validationSchema = useValidationSchema(
    workflows?.allWorkflows?.filter((item) => item?.name !== workflow.name).map((item) => item?.name || '') || [],
  );

  const [emailValue, setEmailValue] = useState<boolean>(workflow.email);
  const [smsValue, setSmsValue] = useState<boolean>(workflow.sms);
  const [notificationValue, setNotificationValue] = useState<boolean>(workflow.notification);

  const [tab, setTab] = useState<WorkflowEditFormTab>(WorkflowEditFormTab.BasicInfo);
  const tabItems: MenuItem[] = [
    {
      label: 'Basic info',
      command: () => setTab(WorkflowEditFormTab.BasicInfo),
    },
  ];

  return (
    <Formik
      initialValues={workflow}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        onConfirm({
          ...workflow,
          name: formik.name,
          description: formik.description,
          email: emailValue,
          sms: smsValue,
          notification: notificationValue,
        });
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu model={tabItems} />
          </div>
          <div className="side-panel-content side-panel-content-section workflow-edit-form">
            {tab === WorkflowEditFormTab.BasicInfo && (
              <BasicInfoTab
                workflowsTypes={
                  <>
                    <WorkflowType
                      name="Email"
                      value={emailValue}
                      setValue={setEmailValue}
                      isLastOneSelected={!smsValue && !notificationValue}
                    />
                    <WorkflowType
                      name="SMS"
                      value={smsValue}
                      setValue={setSmsValue}
                      isLastOneSelected={!emailValue && !notificationValue}
                    />
                    <WorkflowType
                      name="Notification"
                      value={notificationValue}
                      setValue={setNotificationValue}
                      isLastOneSelected={!emailValue && !smsValue}
                    />
                  </>
                }
              />
            )}
          </div>
          <div className="side-panel-controls">
            <Button
              label={isNew ? 'Create' : 'Save'}
              type="submit"
              onClick={submitForm}
              disabled={isLoading}
              className="p-button-sm"
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}

export default WorkflowEditForm;
