import { Workflow } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaRouteIcon from '../../../components/Icons/FaRouteIcon';

type Props = {
  workflow: Workflow;
  setSelected: () => void;
  isSelected: boolean;
  onProperties: () => void;
  onDelete: () => void;
};

function WorkflowEntry(props: Props) {
  return (
    <EntryItem
      name={props.workflow.name}
      description={props.workflow.description}
      deleteTitle="Delete Workflow"
      deleteMessage={
        <>
          Are you sure you want to delete workflow <span className="font-bold">{props.workflow.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaRouteIcon />
            Workflow
          </div>
        </>
      }
      {...props}
    />
  );
}

export default WorkflowEntry;
