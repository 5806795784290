import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { useCallback } from 'react';

import { Parameter, ParameterEnum, SubTemplateConditionalUseCondition, SysDataTypeId } from '../../../../api/generated';
import { SubTemplateConditionalUseConditionValue } from '../../../../api/generated';
import FaTrashWhiteIcon from '../../../../components/Icons/FaTrashWhiteIcon';
import DecimalInput from '../../../../components/DecimalInput';
import FaEmptySetIcon from '../../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../../components/NoItemSelected';

type Props = {
  conditions: SubTemplateConditionalUseCondition[];
  setConditions: (conditions: SubTemplateConditionalUseCondition[]) => void;
  parameters: Parameter[];
};

function ConditionalUseTab({ conditions, setConditions, parameters }: Props) {
  const onConditionUpdate = useCallback(
    (index: number, updatedValues: SubTemplateConditionalUseConditionValue) => {
      const copy: SubTemplateConditionalUseCondition[] = [...conditions];
      const conditionCopy = { ...copy[index] };
      conditionCopy.value = {
        ...conditionCopy.value,
        ...updatedValues,
      };
      copy[index] = conditionCopy;
      setConditions(copy);
    },
    [conditions, setConditions],
  );

  const resetCondition = useCallback(() => {
    setConditions([]);
  }, [setConditions]);

  const setCondition = useCallback(
    (parameterId: number, defaultValue: SubTemplateConditionalUseConditionValue) => {
      const condition = [
        {
          parameterId: parameterId,
          value: defaultValue,
        } as SubTemplateConditionalUseCondition,
      ];
      setConditions(condition);
    },
    [setConditions],
  );

  if (parameters.length === 0) {
    return (
      <NoItemSelected
        icon={<FaEmptySetIcon />}
        title={'No decision parameters set'}
        note={'Start by adding a parameter!'}
      />
    );
  }
  return (
    <div className="conditional-use">
      <div className="field">
        <label htmlFor="parameter">Select conditional parameter</label>
        <div className="d-flex gap-2">
          <Dropdown
            id="conditions"
            name="conditions"
            value={conditions[0]?.parameterId || ''}
            placeholder="-- not set --"
            className={classNames('w-full', { 'p-invalid': false })}
            onChange={(event) => {
              const parameterById: any = parameters.find((p: any) => p.id === event.target.value);
              const defaultConditionValue = getDefaultConditionValue(parameterById?.type);
              setCondition(event.target.value, defaultConditionValue);
            }}
            options={parameters?.map((parameter: any) => {
              return {
                value: parameter.id,
                label: parameter.name,
              };
            })}
          />
          {conditions && conditions.length > 0 && (
            <Button className="p-button-sm" icon={<FaTrashWhiteIcon />} onClick={resetCondition} />
          )}
        </div>
      </div>
      {conditions.map((condition, index) => (
        <ConditionalContent
          key={condition.parameterId}
          index={index}
          condition={condition}
          onConditionUpdate={onConditionUpdate}
          parameters={parameters}
        />
      ))}
    </div>
  );
}

const formatDate = (date: Date) => `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

const getFormattedDate = (dateStr: string) => {
  const date = new Date();
  const parts = (dateStr || formatDate(date)).split('.').reverse();
  return new Date(`${parts}`);
};

function getDefaultConditionValue(type: SysDataTypeId | undefined): SubTemplateConditionalUseConditionValue {
  switch (type) {
    case SysDataTypeId.Bool:
      return { bool: false };
    case SysDataTypeId.DateTime:
      const date = new Date();
      const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      return { dateFrom: formattedDate, dateTo: formattedDate };
    case SysDataTypeId.Double:
      return { doubleFrom: 0, doubleTo: 0 };
    case SysDataTypeId.Enum:
      return { enums: [] };
    case SysDataTypeId.Int:
      return { intFrom: 0, intTo: 0 };
    case SysDataTypeId.String:
      return { string: '' };
    default:
      return {};
  }
}

function ConditionalContent({
  index,
  condition,
  onConditionUpdate,
  parameters,
}: {
  index: number;
  condition: SubTemplateConditionalUseCondition;
  onConditionUpdate: (index: number, value: SubTemplateConditionalUseConditionValue) => void;
  parameters: Parameter[];
}) {
  const parameter: any = parameters.find((p: any) => p.id === condition.parameterId);
  let conditionalComponent;

  switch (parameter?.type) {
    case SysDataTypeId.Bool:
      conditionalComponent = (
        <InputSwitch
          checked={!!condition.value?.bool}
          onChange={(e) => onConditionUpdate(index, { ...condition.value, bool: e.target.value })}
        />
      );
      break;
    case SysDataTypeId.Int:
      conditionalComponent = (
        <div className="d-flex gap-2">
          <span>From:</span>
          <InputText
            value={condition?.value?.intFrom?.toString() || ''}
            onChange={(e) => {
              const copy = Object.assign({}, condition.value);
              copy.intFrom = parseInt(e.target.value);
              onConditionUpdate(index, copy);
            }}
          />
          <span>To:</span>
          <InputText
            value={condition?.value?.intTo?.toString() || ''}
            onChange={(e) => {
              const copy = Object.assign({}, condition.value);
              copy.intTo = parseInt(e.target.value);
              onConditionUpdate(index, copy);
            }}
          />
        </div>
      );
      break;
    case SysDataTypeId.Double:
      conditionalComponent = (
        <div className="d-flex gap-2">
          <span>From:</span>
          <DecimalInput
            value={condition?.value?.doubleFrom ? condition.value.doubleFrom.toString().replace('.', ',') : ''}
            onChange={(newFloatValue) => {
              const copy = Object.assign({}, condition.value);
              copy.doubleFrom = newFloatValue;
              onConditionUpdate(index, copy);
            }}
          />
          <span>To:</span>
          <DecimalInput
            value={condition?.value?.doubleTo ? condition.value.doubleTo.toString().replace('.', ',') : ''}
            onChange={(newFloatValue) => {
              const copy = Object.assign({}, condition.value);
              copy.doubleTo = newFloatValue;
              onConditionUpdate(index, copy);
            }}
          />
        </div>
      );
      break;
    case SysDataTypeId.DateTime:
      conditionalComponent = (
        <div className="d-flex gap-2">
          <span>From</span>
          <Calendar
            id="sampleValueDateFrom"
            name="sampleValueDateFrom"
            value={getFormattedDate(condition.value['dateFrom'])}
            dateFormat="dd.mm.yy"
            placeholder="Select value"
            className=""
            onChange={(e: any) => {
              const newDate = Array.isArray(e.value) ? e.value[0] : e.value;
              if (newDate instanceof Date) {
                onConditionUpdate(index, {
                  ...condition.value,
                  dateFrom: formatDate(newDate),
                });
              }
            }}
          />

          <span>To</span>
          <Calendar
            id="sampleValueDateTo"
            name="sampleValueDateTo"
            value={getFormattedDate(condition.value['dateTo'])}
            dateFormat="dd.mm.yy"
            placeholder="Select value"
            className=""
            onChange={(e: any) => {
              const newDate = Array.isArray(e.value) ? e.value[0] : e.value;
              if (newDate instanceof Date) {
                onConditionUpdate(index, {
                  ...condition.value,
                  dateTo: formatDate(newDate),
                });
              }
            }}
          />
        </div>
      );
      break;
    case SysDataTypeId.Enum:
      conditionalComponent = parameter.enumItems?.map((entry: ParameterEnum, entryIndex: number) => (
        <div className="field-checkbox field" key={entryIndex}>
          <div className="field-checkbox-content">
            <Checkbox
              name={`enum-${entryIndex.toString()}`}
              value={entry.key}
              checked={!!condition?.value?.enums?.some((e) => e === entry.key)}
              onChange={(e) => {
                const conditionEnumsCopy = [...(condition.value.enums || [])];
                const currentIndex = conditionEnumsCopy.indexOf(e.target.value);

                if (currentIndex === -1) {
                  conditionEnumsCopy.push(e.target.value);
                } else {
                  conditionEnumsCopy.splice(currentIndex, 1);
                }

                const copy = Object.assign({}, condition.value);
                copy.enums = conditionEnumsCopy;
                onConditionUpdate(index, copy);
              }}
            />
            <label htmlFor={`enum-${entryIndex.toString()}`}>{entry.value}</label>
          </div>
        </div>
      ));
      break;
    case SysDataTypeId.String:
      conditionalComponent = (
        <InputText
          value={condition?.value?.string || ''}
          onChange={(e) => {
            const copy = Object.assign({}, condition.value);
            copy.string = e.target.value;
            onConditionUpdate(index, copy);
          }}
          className="w-full"
        />
      );
      break;
    default:
  }

  return (
    <div className="field">
      <label htmlFor={parameter?.sysDataTypeId}>True conditional values</label>
      <div id={parameter?.sysDataTypeId}>{conditionalComponent}</div>
    </div>
  );
}

export default ConditionalUseTab;
