export const PacketaLogoSmall = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72 0H0V72H72V0Z" fill="#BA1B02" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2574 32.4468L36.404 32.8351L35.5506 32.4468L23.8359 26.6215L29.4217 24.4855L41.4467 30.3497L37.2574 32.4468ZM49.0496 26.6215L44.8603 28.6797L32.6802 23.2428L36.4816 21.7671L49.0496 26.6215Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8676 18.0388L52.3061 24.0194L50.1339 25.767L36.4409 20.4078L22.7091 25.7281L20.5368 23.9806L35.9754 18H36.8676V18.0388ZM50.483 26.233L53.0431 25.2621L51.1424 41.1068L50.7545 41.8058L37.2167 54L36.79 51.2039L48.8538 40.369L50.483 26.233ZM22.0884 41.8058L21.7005 41.1068L19.7998 25.2621L22.3599 26.233L24.0279 40.3301L36.0918 51.1651L35.6651 53.9612L22.0884 41.8058Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1708 29.1841L49.0499 26.7764L47.537 39.5531L36.5205 49.456L37.1024 34.1162L41.9512 31.1259L41.6409 38.6599L44.6277 36.5628L45.1708 29.1841Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7443 34.1162L36.3261 49.456L25.3485 39.5531L23.7969 26.7764L35.7443 34.1162Z"
        fill="white"
      />
    </svg>
  );
};
