import { useState } from 'react';
import { Image, useImagesQuery } from '../../../../api/generated';
import { useValidationSchema } from './useValidationSchema';
import { ImageEditFormTab } from './ImagesTypes';
import { MenuItem } from 'primereact/menuitem';
import { Formik } from 'formik';
import { TabMenu } from 'primereact/tabmenu';
import BasicInfoTab from './BasicInfoTab';
import { Button } from 'primereact/button';

type Props = {
  image: Image;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (image: any) => void;
  onClose: () => void;
};

function ImageEditForm({ image, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element {
  const { data: images } = useImagesQuery();
  const validationSchema = useValidationSchema(
    images?.allImages?.filter((item) => item?.name !== image.name).map((item) => item?.name || '') || [],
  );

  const [tab, setTab] = useState<ImageEditFormTab>(ImageEditFormTab.BasicInfo);
  const tabItems: MenuItem[] = [
    {
      label: 'Basic info',
      command: () => setTab(ImageEditFormTab.BasicInfo),
    },
  ];

  return (
    <Formik
      initialValues={image}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        onConfirm(formik);
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu model={tabItems} />
          </div>
          <div className="side-panel-content side-panel-content-section workflow-edit-form">
            {tab === ImageEditFormTab.BasicInfo && <BasicInfoTab />}
          </div>
          <div className="side-panel-controls">
            <Button
              label={isNew ? 'Create' : 'Save'}
              type="submit"
              onClick={submitForm}
              disabled={isLoading}
              className="p-button-sm"
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}

export default ImageEditForm;
