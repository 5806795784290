function FaRouteIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 8H10.25C9.53125 8 9 8.5625 9 9.25C9 9.96875 9.53125 10.5 10.25 10.5H13.25C14.75 10.5 16 11.75 16 13.25C16 14.7812 14.75 16 13.25 16H4.34375C4.625 15.7188 4.9375 15.3125 5.28125 14.875C5.375 14.75 5.46875 14.625 5.53125 14.5H13.25C13.9375 14.5 14.5 13.9688 14.5 13.25C14.5 12.5625 13.9375 12 13.25 12H10.25C8.71875 12 7.5 10.7812 7.5 9.25C7.5 7.75 8.71875 6.5 10.25 6.5H11.6875C10.875 5.46875 10 4.09375 10 3C10 1.34375 11.3125 0 13 0C14.6562 0 16 1.34375 16 3C16 4.25 14.875 5.84375 14 6.90625C13.4375 7.5625 13 8 13 8ZM14.1875 3.96875C14.4062 3.5 14.5 3.1875 14.5 3C14.5 2.1875 13.8125 1.5 13 1.5C12.1562 1.5 11.5 2.1875 11.5 3C11.5 3.1875 11.5625 3.5 11.7812 3.96875C12.0312 4.40625 12.3438 4.90625 12.6875 5.375C12.7812 5.5 12.875 5.625 13 5.75C13.0938 5.625 13.1875 5.5 13.2812 5.375C13.625 4.90625 13.9375 4.40625 14.1875 3.96875ZM4 14.9062C3.875 15.0312 3.78125 15.125 3.6875 15.25L3.65625 15.2812C3.5625 15.4062 3.4375 15.5312 3.34375 15.625C3.34375 15.625 3.34375 15.625 3.34375 15.6562C3.125 15.875 3 16 3 16C3 16 2.53125 15.5625 1.96875 14.9062C1.09375 13.8438 0 12.25 0 11C0 9.34375 1.3125 8 3 8C4.65625 8 6 9.34375 6 11C6 12.0938 5.09375 13.4688 4.28125 14.5C4.1875 14.6562 4.09375 14.7812 4 14.9062ZM4.1875 11.9688C4.40625 11.5 4.5 11.1875 4.5 11C4.5 10.1875 3.8125 9.5 3 9.5C2.15625 9.5 1.5 10.1875 1.5 11C1.5 11.1875 1.5625 11.5 1.78125 11.9688C2.03125 12.4062 2.34375 12.9062 2.6875 13.375C2.78125 13.5 2.875 13.625 3 13.75C3.09375 13.625 3.1875 13.5 3.28125 13.375C3.625 12.9062 3.9375 12.4062 4.1875 11.9688Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaRouteIcon;
