import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SubTemplate, useSubTemplatesQuery, useDeleteSubTemplateMutation } from '../../api/generated';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import HeaderPanel from '../../components/HeaderPanel';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import SubTemplateList from './components/SubTemplateList';
import SubTemplateSidebar from './components/SubTemplateSidebar';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import FaFileLinesIcon from '../../components/Icons/FaFileLinesIcon';

function SubTemplates() {
  const { data: subTemplates, isLoading, refetch, error } = useSubTemplatesQuery({});
  const [selectedSubTemplateId, selectSubTemplateId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteSubTemplate] = useDeleteSubTemplateMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const navigate = useNavigate();
  const toast = useToast();

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const selectSubTemplate = useCallback((SubTemplateId: number) => {
    selectSubTemplateId(SubTemplateId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addSubTemplate = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editSubTemplate = useCallback((SubTemplateId: number) => {
    selectSubTemplateId(SubTemplateId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const openEditor = useCallback(
    (SubTemplateId: number) => {
      navigate(generatePath(AppRoute.SubTemplateEditor, { id: SubTemplateId.toString() }));
    },
    [navigate],
  );

  const tryToDeleteSubTemplate = useCallback(
    async (SubTemplateId: number) => {
      try {
        await deleteSubTemplate({
          id: SubTemplateId.toString(),
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Sub-template '${
                subTemplates?.allSubTemplates?.find((item) => item?.subTemplateId === SubTemplateId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectSubTemplateId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteSubTemplate, subTemplates, refetch, toast],
  );

  const filteredSubTemplates = useMemo(() => {
    if (searchString) {
      return subTemplates?.allSubTemplates?.filter((SubTemplate) =>
        SubTemplate?.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return subTemplates?.allSubTemplates;
  }, [subTemplates?.allSubTemplates, searchString]);

  return (
    <Page className="page-sub-templates" windowTitle={subTemplatesPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <SubTemplateSidebar
            subTemplateId={selectedSubTemplateId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectSubTemplateId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={subTemplatesPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addSubTemplate} />
        </HeaderPanel>
        <div className="sub-templates-content">
          <SubTemplateList
            subTemplates={(filteredSubTemplates as SubTemplate[]) || []}
            selectedSubTemplateId={selectedSubTemplateId}
            onSelect={selectSubTemplate}
            onEditor={openEditor}
            onProperties={editSubTemplate}
            onDelete={tryToDeleteSubTemplate}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const subTemplatesPage: IAppPage = {
  title: 'Sub-templates',
  icon: <FaFileLinesIcon />,
  path: () => AppRoute.SubTemplates,
  page: <SubTemplates />,
  require: [],
};

export default subTemplatesPage;
