import { SubTemplateConditionalUseConditionValue, SysDataTypeId, SysParameterTypeId } from '../../../api/generated';

export enum SubTemplateViewTab {
  Details,
  References,
}

export enum SubTemplateEditFormTab {
  BasicInfo = 'BasicInfo',
  Parameters = 'Parameters',
  DecisionParameters = 'DecisionParameters',
  ConditionalUse = 'ConditionalUse',
}

export type SubTemplateUpdate = {
  subTemplateId: number;
  name: string;
  description: string;
  parameters: SubTemplateParameter[];
  conditions: SubTemplateConditionalUse[];
};

export type SubTemplateConditionalUse = {
  parameterId: number;
  value: SubTemplateConditionalUseConditionValue;
};

export type SubTemplateParameter = {
  id?: number;
  name?: string;
  description?: string | null;
  isDecision?: boolean;
  type?: SysDataTypeId;
  sysParameterTypeId?: SysParameterTypeId;
};
