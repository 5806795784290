import { FormikField } from '../../../../../components/FormikField';
import React from 'react';

type Props = {
  workflowsTypes: React.ReactNode;
};

function BasicInfoTab(props: Props) {
  return (
    <>
      <FormikField label="Name" type="text" name="name" />
      <FormikField label="Description" type="textarea" name="description" />

      <div className="field">
        <label htmlFor="type">Type</label>
        {props.workflowsTypes}
      </div>
    </>
  );
}

export default BasicInfoTab;
