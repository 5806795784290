import {
  SubTemplate,
  useAssignSubTemplateParametersMutation,
  useSubTemplateQuery,
  useUpdateSubTemplateConditionalUseMutation,
  useUpdateSubTemplateMutation,
} from '../../../api/generated';
import useToast from '../../../hooks/useToast';
import SidePanel from '../../../components/SidePanel';
import SubTemplateEditForm from './SubTemplateEditForm';
import { SubTemplateUpdate } from './SubTemplateTypes';

type Props = {
  subTemplateId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function SubTemplateEdit({ subTemplateId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useSubTemplateQuery({ id: subTemplateId.toString() });
  const subTemplate = data?.subTemplateById;

  const toast = useToast();

  const [updateSubTemplate, { isLoading }] = useUpdateSubTemplateMutation();
  const [assignSubTemplateParameters] = useAssignSubTemplateParametersMutation();
  const [updateSubTemplateConditionalUse] = useUpdateSubTemplateConditionalUseMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <SubTemplateEditForm
        subTemplate={subTemplate as SubTemplate}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (subTemplate: SubTemplateUpdate) => {
          await updateSubTemplate({
            id: subTemplateId.toString() || '',
            input: {
              name: subTemplate.name,
              description: subTemplate.description,
            },
          })
            .unwrap()
            .then(async () => {
              await assignSubTemplateParameters({
                id: subTemplateId.toString() || '',
                input: {
                  parameters: subTemplate.parameters?.map((param) => ({
                    parameterId: param.id as number,
                    isDecision: param.isDecision as boolean,
                  })),
                },
              }).catch(toast.error);
            })
            .then(async () => {
              await updateSubTemplateConditionalUse({
                id: subTemplateId.toString() || '',
                input: {
                  conditions: subTemplate.conditions,
                },
              }).catch(toast.error);
            })
            .then(() => {
              toast.success(`Sub-template '${subTemplate.name}' has been updated successfully.`);
              onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default SubTemplateEdit;
