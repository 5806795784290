import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { checkAuth } from '../auth/authUtils';
import { useAuth } from '../providers/AuthProvider';
import { ApiRole } from '../api/ApiRole.types';
import { useRoles } from '../hooks/useRoles';
import { AppRoute } from './AppRoute';
import { useTokenContext } from '../providers/TokenProvider';
import NotAllowed from '../pages/NotAllowed';

interface ProtectedRouteProps {
  require?: ApiRole[];
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const roles = useRoles();
  const { token } = useTokenContext();
  const hasRequirecRole = checkAuth(roles.getRoles(), props.require, token);
  const location = useLocation();
  const { setLastAttemptedRoute } = useAuth();

  if (!token) {
    setLastAttemptedRoute(location.pathname);
    return <Navigate to={AppRoute.Home} />;
  }

  if (!hasRequirecRole) {
    return <NotAllowed />;
  }

  return <>{props.children}</>;
};

export default ProtectedRoute;
