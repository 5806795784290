import { classNames } from 'primereact/utils';

type Props = {
  isSelected?: boolean;
  setSelected?: () => void;
  onDoubleClick?: () => void;
  children: React.ReactNode;
};
function EntryCard(props: Props) {
  return (
    <div
      className={classNames('entry-card', { selected: props.isSelected })}
      onClick={props.setSelected}
      onDoubleClick={props.onDoubleClick}
    >
      {props.children}
    </div>
  );
}

export default EntryCard;
