import React from 'react';
import MobileIcon from '../../../../Icons/MobileIcon';

type Props = {
  subject?: string;
  body?: string;
};

export default function NotificationWithSubjectPreview(props: Props) {
  return (
    <>
      <MobileIcon />
      <div className="notification-preview">
        <div className="subject" dangerouslySetInnerHTML={{ __html: props.subject || '' }} />
        <div dangerouslySetInnerHTML={{ __html: props.body || '' }} />
      </div>
    </>
  );
}
