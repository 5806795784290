import { useCallback, useEffect, useState } from 'react';
import {
  EntryPointStatisticsBasic2Query,
  EntryPointsQuery,
  useEntryPointStatisticsBasic2Query,
  useEntryPointsQuery,
} from '../../api/generated';

// Types based on generated queries
type EntryPoint = NonNullable<NonNullable<EntryPointsQuery['allEntryPoints']>[number]>;
type EntryPointStatistic = NonNullable<
  NonNullable<EntryPointStatisticsBasic2Query['allEntryPointStatisticsBasic']>[number]
>;

export interface CombinedEntryPoint extends EntryPoint {
  statistics?: Omit<EntryPointStatistic, 'entryPointId'>;
}

/**
 * Fetches entry points and statistics and combines them into a single object based on boolean flag.
 * @param fetchStatistics - Whether to fetch statistics or not.
 */
export function useEntryPointsWithStatistics(fetchStatistics: boolean) {
  const {
    data: entryPoints,
    isLoading: isLoadingEntryPoints,
    refetch: refetchEntryPoints,
    error: errorEntryPoints,
  } = useEntryPointsQuery({});

  const {
    data: statistics,
    isLoading: isLoadingStatistics,
    refetch: refetchStatistics,
    error: errorStatistics,
  } = useEntryPointStatisticsBasic2Query({}, { skip: !fetchStatistics });

  const [combinedData, setCombinedData] = useState<CombinedEntryPoint[]>([]);

  useEffect(() => {
    const validEntryPoints = (entryPoints?.allEntryPoints?.filter(
      (entryPoint): entryPoint is EntryPoint => !!entryPoint,
    ) || []) as EntryPoint[];
    const validStatistics = fetchStatistics
      ? statistics?.allEntryPointStatisticsBasic?.filter((stat): stat is EntryPointStatistic => !!stat) || []
      : [];

    const newCombinedData = validEntryPoints.map((entryPoint) => {
      const statistic = validStatistics.find((stat) => stat.entryPointId === entryPoint.entryPointId);
      return {
        ...entryPoint,
        statistics: statistic ? { ...statistic } : undefined,
      };
    });

    setCombinedData(newCombinedData);
  }, [entryPoints, statistics, fetchStatistics]);

  const refetch = useCallback(() => {
    refetchEntryPoints();
    if (fetchStatistics) {
      refetchStatistics();
    }
  }, [refetchEntryPoints, refetchStatistics, fetchStatistics]);

  return {
    data: combinedData,
    isLoading: isLoadingEntryPoints || (fetchStatistics && isLoadingStatistics),
    refetch,
    error: errorEntryPoints || errorStatistics,
  };
}
