function FaCodeIcon() {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3867 1.4375L7.73047 13.2188C7.62891 13.5234 7.29883 13.7012 6.96875 13.5996C6.66406 13.498 6.48633 13.168 6.58789 12.8379L10.2441 1.05664C10.3457 0.751953 10.6758 0.574219 11.0059 0.675781C11.3105 0.777344 11.4883 1.10742 11.3867 1.4375ZM13.6719 3.64648L16.9219 6.69336C17.0488 6.79492 17.125 6.97266 17.125 7.125C17.125 7.30273 17.0488 7.45508 16.9219 7.58203L13.6719 10.6289C13.418 10.8574 13.0371 10.8574 12.8086 10.6035C12.5801 10.3496 12.5801 9.96875 12.834 9.74023L15.6016 7.125L12.834 4.53516C12.5801 4.30664 12.5801 3.92578 12.8086 3.67188C13.0371 3.41797 13.418 3.41797 13.6719 3.64648ZM5.14062 4.53516L2.37305 7.125L5.14062 9.74023C5.39453 9.96875 5.39453 10.3496 5.16602 10.6035C4.9375 10.8574 4.55664 10.8574 4.30273 10.6289L1.05273 7.58203C0.925781 7.45508 0.875 7.30273 0.875 7.125C0.875 6.97266 0.925781 6.79492 1.05273 6.69336L4.30273 3.64648C4.55664 3.41797 4.9375 3.41797 5.16602 3.67188C5.39453 3.92578 5.39453 4.30664 5.14062 4.53516Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaCodeIcon;
