import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useCodeBlockDetailQuery,
  useLazyCodeBlockPreviewByIdQuery,
  useLazyCodeBlockPreviewByJsBodyQuery,
  useUpdateCodeBlockMutation,
} from '../../api/generated';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import FetchInProgress from '../../components/FetchInProgress';
import useAppNavigate from '../../hooks/useAppNavigate';
import Page from '../../components/Page';
import MonacoEditor from '../../components/MonacoEditor';
import ContentWithHeader from '../../components/ContentWithHeader';
import HeadingWithIcon from '../../components/HeadingWithIcon';
import FaBracketsCurlyIcon from '../../components/Icons/FaBracketsCurlyIcon';
import useToast from '../../hooks/useToast';
import SavePanel from '../../components/Editor/components/SavePanel';
import Overlay from '../../components/Overlay';
import NoItemSelected from '../../components/NoItemSelected';
import FaEmptySetIcon from '../../components/Icons/FaEmptySetIcon';
import useFetchContentPreview from '../../components/Editor/hooks/useFetchContentPreview';
import { RequestError } from '../../api/graphqlBaseQueryTypes';
import FaCodeIcon from '../../components/Icons/FaCodeIcon';
import TopBar from '../../components/Editor/components/TopBar';

function SnippetEditor() {
  const { id } = useParams();
  const { data: codeBlock, isLoading } = useCodeBlockDetailQuery({ id: id || '' });
  const [update, { isLoading: isSaving }] = useUpdateCodeBlockMutation();
  const [getSavedPreview, { isFetching: isLoadingSavedPreview, isError: hasSavedPreviewError }] =
    useLazyCodeBlockPreviewByIdQuery({});
  const [savedPreviewError, setSavedPreviewError] = useState<string | undefined>();
  const [getTempPreview, { isFetching: isLoadingTempPreview, isError: hasTempPreviewError }] =
    useLazyCodeBlockPreviewByJsBodyQuery({});
  const [tempPreviewError, setTempPreviewError] = useState<string | undefined>();

  const [action, setAction] = useState<ActionType>(ActionType.Edit);
  const [jsBody, setJsBody] = useState('');
  const [jsSavedPreview, setJsSavedPreview] = useState('');
  const [jsTempPreview, setJsTempPreview] = useState('');

  const navigate = useAppNavigate();
  const toast = useToast();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);

  const getContentIdOrThrow = (contentId: number | undefined) => {
    const id = contentId?.toString();
    if (id) return id;
    throw new Error('Missing contentId');
  };

  const handlePreview = useCallback(
    async (contentId: string) => {
      await getSavedPreview({
        id: contentId,
      })
        .unwrap()
        .then((data) => {
          setSavedPreviewError(undefined);
          data?.codeBlockPreviewById && setJsSavedPreview(data?.codeBlockPreviewById);
        })
        .catch((e) => {
          const error = e as RequestError;
          setSavedPreviewError(error.errors.map((error) => error.message).join('. '));
          console.error(e);
        });
      await getTempPreview({
        jsBody: jsBody,
      })
        .unwrap()
        .then((data) => {
          setTempPreviewError(undefined);
          data?.codeBlockPreviewByJsBody && setJsTempPreview(data?.codeBlockPreviewByJsBody);
        })
        .catch((e) => {
          const error = e as RequestError;
          setTempPreviewError(error.errors.map((error) => error.message).join('. '));
          console.error(e);
        });
    },
    [getSavedPreview, getTempPreview, jsBody],
  );

  const handleSave = useCallback(async () => {
    await update({
      id: getContentIdOrThrow(codeBlock?.codeBlockById?.codeBlockId),
      input: {
        name: codeBlock?.codeBlockById?.name,
        description: codeBlock?.codeBlockById?.description,
        jsBody: jsBody,
      },
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          setShowSaveSuccessful(false);
        }, 2000);
        setUnsavedChanges(false);
        setShowSaveSuccessful(true);
      })
      .then(() => handlePreview(getContentIdOrThrow(codeBlock?.codeBlockById?.codeBlockId)))
      .catch(toast.error);
  }, [
    handlePreview,
    update,
    codeBlock?.codeBlockById?.codeBlockId,
    codeBlock?.codeBlockById?.name,
    codeBlock?.codeBlockById?.description,
    jsBody,
    toast.error,
  ]);

  if (!id || codeBlock?.codeBlockById === null) {
    navigate(AppRoute.Snippets);
  }

  useEffect(() => {
    const placeholder = '/**\n    You can start writing code here...\n*/\n';
    const jsBody = codeBlock?.codeBlockById?.jsBody;
    setJsBody(jsBody || placeholder);
  }, [codeBlock?.codeBlockById?.jsBody, setJsBody]);

  /** Fetch template preview */
  useFetchContentPreview(
    action,
    () =>
      codeBlock?.codeBlockById?.codeBlockId &&
      handlePreview(getContentIdOrThrow(codeBlock?.codeBlockById?.codeBlockId)),
  );

  return isLoading ? (
    <FetchInProgress />
  ) : (
    <>
      <Page
        className={classNames('page-snippet-editor content-editor', {
          'snippet-preview': action === ActionType.View,
        })}
        windowTitle={`Edit ${codeBlock?.codeBlockById?.name}`}
        topBar={
          <TopBar
            previousPath={AppRoute.Snippets}
            title={'Snippet'}
            icon={<FaCodeIcon />}
            contentName={codeBlock?.codeBlockById?.name || '...'}
            previewVisible={true}
            isLoading={isLoading}
            setAction={setAction}
          />
        }
      >
        <ContentWithHeader
          header={<HeadingWithIcon label="Code editor" icon={<FaBracketsCurlyIcon />} />}
          contentHeader={
            <div className="d-flex">
              <div className="flex-grow-1 compile"></div>
              <SavePanel
                onSave={handleSave}
                isLoading={isLoading}
                isSaving={isSaving}
                unsavedChanges={unsavedChanges}
                saveSuccessful={showSaveSuccessful}
              />
            </div>
          }
        >
          {action === ActionType.View && (
            <div className="split-panel">
              {unsavedChanges && (
                <Preview
                  isLoading={isLoadingTempPreview}
                  title={'Draft'}
                  content={jsTempPreview}
                  hasError={hasTempPreviewError}
                  error={tempPreviewError}
                />
              )}
              <Preview
                isLoading={isLoadingSavedPreview}
                title={unsavedChanges ? 'Saved version' : undefined}
                content={jsSavedPreview}
                hasError={hasSavedPreviewError}
                error={savedPreviewError}
              />
            </div>
          )}
          {action === ActionType.Edit && (
            <MonacoEditor
              value={jsBody}
              setValue={(value) => {
                setJsBody(value);
                setUnsavedChanges(true);
              }}
            />
          )}
        </ContentWithHeader>
      </Page>
    </>
  );
}

function Preview({
  isLoading,
  hasError,
  title,
  error,
  content,
}: {
  isLoading: boolean;
  hasError: boolean;
  title: string | undefined;
  error?: string | undefined;
  content: string;
}) {
  let preview: React.ReactNode;

  if (isLoading) {
    preview = <Overlay label="Generating preview" />;
  } else if (hasError) {
    preview = (
      <NoItemSelected
        icon={<FaEmptySetIcon />}
        title={error ? 'No preview available' : 'No preview'}
        note={error || 'The preview cannot be generated.'}
      />
    );
  } else {
    preview = <>{content}</>;
  }
  return (
    <div className="editor-preview">
      {title && <div className="preview-title">{title}</div>}
      <div className="preview-content">{preview}</div>
    </div>
  );
}

const snippetEditorPage: IAppPage = {
  title: 'Snippet Editor',
  path: () => AppRoute.SnippetEditor,
  page: <SnippetEditor />,
  require: [],
  isHidden: true,
};

export default snippetEditorPage;
