import React, { useState } from 'react';
import { AppRoute } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import ContentWithHeader from '../ContentWithHeader';
import HeadingWithIcon from '../HeadingWithIcon';
import DecisionsSection from './components/parameters/DecisionsSection';
import DefaultLanguageSettings from './components/languages/DefaultLanguageSettings';
import LanguagesSection from './components/languages/LanguagesSection';
import LanguagesSectionHeader from './components/languages/LanguagesSectionHeader';
import NotificationTypeSection from './components/parameters/NotificationTypeSection';
import FaBinaryIcon from '../Icons/FaBinaryIcon';
import UnsavedChangesDialog from './components/UnsavedChangesDialog';
import { EntryPointEditorStore, SafeContentParameters } from './editorTypes';

type Props = {
  path: AppRoute;
  decisionParams: SafeContentParameters[];
  systemParams: SafeContentParameters[];
  action: ActionType;
  store: EntryPointEditorStore;
  onChange: (store: Partial<EntryPointEditorStore>) => void;
  unsavedChanges: boolean;
  onSave: () => void;
  templateId: string;
  children: React.ReactNode;
};

export default function Editor(props: Props) {
  const langs = props.systemParams.find((p) => p?.parameter?.name === 'SysLanguage');
  const messageType = props.systemParams.find((p) => p?.parameter?.name === 'SysMessageType');

  const [languageSettingsVisible, setLanguageSettingsVisible] = useState(false);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);

  const hasLangs = langs?.parameter?.enumItems && langs?.parameter?.enumItems.length > 0;

  return (
    <div className="editor">
      <ContentWithHeader hasShowButton header={<HeadingWithIcon label="Parameters" icon={<FaBinaryIcon />} />}>
        <>
          <DecisionsSection
            decisionParams={props.decisionParams}
            decisions={props.store.decisions as any}
            onChange={(id: number, value: string) => {
              const copy = new Map(props.store.decisions);
              copy?.set(id, value);

              props.onChange({ decisions: copy });
            }}
          />
          <NotificationTypeSection
            notificationType={messageType}
            value={props.store.messageType}
            onChange={(id: number, value: string) => {
              const copy = new Map(props.store.decisions);
              copy?.set(id, value);

              props.onChange({ decisions: copy, messageType: value });
            }}
          />
        </>
      </ContentWithHeader>
      {hasLangs && (
        <ContentWithHeader
          hasShowButton
          header={
            <>
              <UnsavedChangesDialog
                message="There are unsaved changes. Do you want to save changes before changing languages?"
                acceptLabel="Save"
                accept={async () => {
                  await props.onSave();
                  setLanguageSettingsVisible(true);
                }}
                reject={() => setShowUnsavedChanges(false)}
                visible={showUnsavedChanges}
              />
              <LanguagesSectionHeader
                defaultLanguage={props.store.defaultLanguage}
                setLanguageSettingsVisible={async () => {
                  if (props.unsavedChanges) {
                    setShowUnsavedChanges(true);
                  } else {
                    setLanguageSettingsVisible(true);
                  }
                }}
              />
            </>
          }
        >
          <DefaultLanguageSettings
            templateId={props.templateId}
            defaultLanguage={props.store.defaultLanguage}
            langs={langs?.parameter?.enumItems?.map((lang) => lang.key) || []}
            langsUsingDefault={props.store.langsUsingDefault}
            sidebarVisible={languageSettingsVisible}
            onSidebarClose={(data) => {
              setLanguageSettingsVisible(false);
              data &&
                props.onChange({
                  defaultLanguage: data.defaultLanguage,
                  langsUsingDefault: data.langsUsingDefault,
                });
              props.onSave();
            }}
            path={props.path}
          />
          <LanguagesSection
            langs={langs}
            store={props.store}
            onChange={(id: number, value: string) => {
              const copy = new Map(props.store.decisions);
              copy?.set(id, value);

              props.onChange({ decisions: copy });
            }}
          />
        </ContentWithHeader>
      )}
      {props.children}
    </div>
  );
}
