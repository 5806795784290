import { classNames } from 'primereact/utils';

type Props = {
  maxContentCount: number;
  missingContentCount: number;
};

export default function StatisticsSemaphore(props: Props) {
  return props.missingContentCount === 0 && props.maxContentCount === 0 ? (
    <></>
  ) : (
    <div
      className={classNames('content-semaphore', {
        success: props.missingContentCount === 0 && props.maxContentCount > 0,
        warning: props.missingContentCount > 0 && props.missingContentCount < props.maxContentCount,
        error: props.missingContentCount === props.maxContentCount,
      })}
    >
      {props.maxContentCount - props.missingContentCount}/{props.maxContentCount}
    </div>
  );
}
