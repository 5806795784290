import { ActionType } from '../../../common/actionTypes';
import FaMousePointerIcon from '../../../components/Icons/FaMousePointerIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import SnippetView from './SnippetView';
import SnippetAdd from './SnippetAdd';
import SnippetEdit from './SnippetEdit';

type Props = {
  codeBlockId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

function SnippetSidebar({ codeBlockId, sidebarVisible, onSidebarClose, action, setAction, refetch }: Props) {
  if (action === ActionType.Add) {
    return (
      <SnippetAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (codeBlockId && action === ActionType.Edit) {
    return (
      <SnippetEdit
        codeBlockId={codeBlockId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (codeBlockId && action === ActionType.View) {
    return <SnippetView id={codeBlockId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No snippet selected'}
        note={'Select any to see its details'}
      />
    );
  }
}

export default SnippetSidebar;
