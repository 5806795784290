import { useState } from 'react';
import { Image, useImageQuery } from '../../../../api/generated';
import { ImageViewTab } from './ImagesTypes';
import { MenuItem } from 'primereact/menuitem';
import FaImageIcon from '../../../../components/Icons/FaImageIcon';
import { TabMenu } from 'primereact/tabmenu';
import Overlay from '../../../../components/Overlay';
import DetailsTab from './DetailsTab';
import ReferencesTab from './ReferencesTab';

type Props = {
  id: number;
};

function ImageView(props: Props) {
  const { data, isFetching } = useImageQuery({ id: props.id });
  const image = data?.imageById;

  const [activeIndex, setActiveIndex] = useState<number>(ImageViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (!isFetching && image) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaImageIcon />
          </div>
          <div className="name">{image.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === ImageViewTab.Details && <DetailsTab image={image as Image} />}
          {activeIndex === ImageViewTab.References && <ReferencesTab id={props.id} />}
        </div>
      </div>
    );
  } else {
    return isFetching ? <Overlay label="Loading image" /> : <>Image not found!</>;
  }
}

export default ImageView;
