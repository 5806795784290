import { ActionType } from '../../../../common/actionTypes';
import MasterTemplateView from './MasterTemplateView';
import NoItemSelected from '../../../../components/NoItemSelected';
import FaMousePointerIcon from '../../../../components/Icons/FaMousePointerIcon';
import MasterTemplateAdd from './MasterTemplateAdd';
import MasterTemplateEdit from './MasterTemplateEdit';

type Props = {
  masterTemplateId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

export default function MasterTemplateSidebar(props: Props) {
  if (props.action === ActionType.Add) {
    return (
      <MasterTemplateAdd
        sidebarVisible={props.sidebarVisible}
        onSidebarClose={props.onSidebarClose}
        onConfirm={() => {
          props.setAction(ActionType.View);
          props.refetch();
        }}
      />
    );
  } else if (props.masterTemplateId && props.action === ActionType.Edit) {
    return (
      <MasterTemplateEdit
        masterTemplateId={props.masterTemplateId}
        sidebarVisible={props.sidebarVisible}
        onSidebarClose={props.onSidebarClose}
        onConfirm={() => {
          props.setAction(ActionType.View);
          props.refetch();
        }}
      />
    );
  } else if (props.masterTemplateId && props.action === ActionType.View) {
    return <MasterTemplateView id={props.masterTemplateId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No master template selected'}
        note={'Select any to see its details'}
      />
    );
  }
}
