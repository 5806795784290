import { Workflow } from '../../../api/generated';
import NoItemSelected from '../../../components/NoItemSelected';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import WorkflowEntry from './WorkflowEntry';

type Props = {
  workflows: Workflow[] | null;
  selectedWorkflowId: number | undefined;
  onSelect: (workflowId: number) => void;
  onProperties: (workflowId: number) => void;
  onDelete: (workflowId: number) => void;
};

function WorkflowList(props: Props) {
  return (
    <>
      {props.workflows && props.workflows.length > 0 ? (
        props.workflows.map((workflow) => (
          <WorkflowEntry
            key={workflow.workflowId}
            workflow={workflow as Workflow}
            isSelected={workflow.workflowId === props.selectedWorkflowId}
            setSelected={() => props.onSelect(workflow.workflowId)}
            onProperties={() => props.onProperties(workflow.workflowId)}
            onDelete={() => props.onDelete(workflow.workflowId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No workflows'} note={'Start by adding a workflow!'} />
      )}
    </>
  );
}

export default WorkflowList;
