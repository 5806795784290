import { addCustomYupMethods, ValidationMessage } from '../../../../common/validationUtils';
import * as Yup from 'yup';

addCustomYupMethods();

const basicSchema = (imageNames: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(50)
      //@ts-ignore
      .uniqueIn(imageNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
    alternateText: Yup.string().max(50).required(ValidationMessage.REQUIRED),
    width: Yup.number().required(ValidationMessage.REQUIRED),
    height: Yup.number().required(ValidationMessage.REQUIRED),
    source: Yup.string()
      .required(ValidationMessage.REQUIRED)
      //@ts-ignore
      .base64(),
  });

export function useValidationSchema(imageNames: string[]) {
  return basicSchema(imageNames);
}
