function FaLanguageIcon() {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 3.125C14.5938 3.125 14.875 3.40625 14.875 3.75V3.875H16.75C17.0938 3.875 17.375 4.15625 17.375 4.5C17.375 4.84375 17.0938 5.125 16.75 5.125H16.6875L16.625 5.28125C16.3438 6.03125 15.9062 6.75 15.375 7.3125C15.4062 7.34375 15.4375 7.34375 15.4688 7.375L16.0625 7.71875C16.3438 7.90625 16.4375 8.28125 16.2812 8.59375C16.0938 8.875 15.7188 8.96875 15.4062 8.8125L14.8125 8.4375C14.6875 8.375 14.5312 8.28125 14.4062 8.1875C14.0938 8.40625 13.7188 8.625 13.3438 8.78125L13.25 8.84375C12.9375 8.96875 12.5625 8.84375 12.4062 8.53125C12.2812 8.1875 12.4062 7.84375 12.7188 7.6875L12.8438 7.65625C13.0312 7.5625 13.25 7.4375 13.4375 7.34375L13.0312 6.96875C12.8125 6.71875 12.8125 6.3125 13.0312 6.0625C13.2812 5.8125 13.6875 5.8125 13.9375 6.0625L14.375 6.53125H14.4062C14.7812 6.125 15.0938 5.65625 15.3438 5.125H12C11.625 5.125 11.375 4.84375 11.375 4.5C11.375 4.15625 11.625 3.875 12 3.875H13.625V3.75C13.625 3.40625 13.875 3.125 14.25 3.125ZM4.375 6.625L5 5.3125L5.59375 6.625H4.375ZM18 0C19.0938 0 20 0.90625 20 2V10C20 11.125 19.0938 12 18 12H2C0.875 12 0 11.125 0 10V2C0 0.90625 0.875 0 2 0H18ZM18 1.5H10V10.5H18C18.25 10.5 18.5 10.2812 18.5 10V2C18.5 1.75 18.25 1.5 18 1.5ZM5.5625 3.5C5.46875 3.28125 5.21875 3.125 5 3.125C4.75 3.125 4.5 3.28125 4.40625 3.5L2.40625 8C2.28125 8.3125 2.40625 8.6875 2.71875 8.84375C3.03125 8.96875 3.40625 8.84375 3.5625 8.53125L3.84375 7.875H6.125L6.40625 8.53125C6.5625 8.84375 6.9375 8.96875 7.25 8.84375C7.5625 8.6875 7.6875 8.3125 7.5625 8L5.5625 3.5Z"
        fill="#686E75"
      />
    </svg>
  );
}

export default FaLanguageIcon;
