export enum MasterTemplateEditFormTab {
  BasicInfo,
}

export enum MasterTemplateViewTab {
  Details,
  References,
}

export type MasterTemplateUpdate = {
  name: string;
  description: string;
  htmlBody: string;
  mjmlBody: string;
};
