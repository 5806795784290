import { ActionType } from '../../../../common/actionTypes';
import FaMousePointerIcon from '../../../../components/Icons/FaMousePointerIcon';
import NoItemSelected from '../../../../components/NoItemSelected';
import ImageAdd from './ImageAdd';
import ImageEdit from './ImageEdit';
import ImageView from './ImageView';

type Props = {
  imageId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

function ImageSidebar({ imageId, sidebarVisible, onSidebarClose, action, setAction, refetch }: Props) {
  if (action === ActionType.Add) {
    return (
      <ImageAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (imageId && action === ActionType.Edit) {
    return (
      <ImageEdit
        imageId={imageId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (imageId && action === ActionType.View) {
    return <ImageView id={imageId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No image selected'}
        note={'Select any to see its details'}
      />
    );
  }
}

export default ImageSidebar;
