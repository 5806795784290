function FaEyeIcon() {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 7C6 4.8125 7.78125 3 10 3C12.1875 3 14 4.8125 14 7C14 9.21875 12.1875 11 10 11C7.78125 11 6 9.21875 6 7ZM10 9.5C11.375 9.5 12.5 8.40625 12.5 7C12.5 5.625 11.375 4.5 10 4.5C9.96875 4.5 9.9375 4.5 9.90625 4.5C9.96875 4.6875 10 4.84375 10 5C10 6.125 9.09375 7 8 7C7.8125 7 7.65625 7 7.5 6.9375C7.5 6.96875 7.5 7 7.5 7C7.5 8.40625 8.59375 9.5 10 9.5ZM3.96875 2.53125C5.4375 1.15625 7.46875 0 10 0C12.5 0 14.5312 1.15625 16 2.53125C17.4688 3.875 18.4375 5.5 18.9062 6.625C19 6.875 19 7.15625 18.9062 7.40625C18.4375 8.5 17.4688 10.125 16 11.5C14.5312 12.875 12.5 14 10 14C7.46875 14 5.4375 12.875 3.96875 11.5C2.5 10.125 1.53125 8.5 1.0625 7.40625C0.96875 7.15625 0.96875 6.875 1.0625 6.625C1.53125 5.5 2.5 3.875 3.96875 2.53125ZM10 1.5C7.9375 1.5 6.28125 2.4375 5 3.625C3.78125 4.75 2.96875 6.0625 2.53125 7C2.96875 7.9375 3.78125 9.28125 5 10.4062C6.28125 11.5938 7.9375 12.5 10 12.5C12.0312 12.5 13.6875 11.5938 14.9688 10.4062C16.1875 9.28125 17 7.9375 17.4375 7C17 6.0625 16.1875 4.75 14.9688 3.625C13.6875 2.4375 12.0312 1.5 10 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaEyeIcon;
