import { InputSwitch } from 'primereact/inputswitch';

type Props = {
  name: string;
  value: boolean;
  setValue: (value: boolean) => void;
  isLastOneSelected: boolean;
};

function WorkflowType(props: Props) {
  return (
    <div className="boolean">
      <InputSwitch
        id={props.name.toLowerCase()}
        name={props.name.toLowerCase()}
        checked={props.value}
        onChange={(event) => {
          if (props.isLastOneSelected) {
            return;
          }
          props.setValue(event.value);
        }}
      />
      <span>{props.name}</span>
    </div>
  );
}

export default WorkflowType;
