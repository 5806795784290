import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ParameterInput, useAllDeepParametersByTemplateIdQuery } from '../../../api/generated';
import ContentWithHeader from '../../ContentWithHeader';
import HeadingWithIcon from '../../HeadingWithIcon';
import { SafeContentParameters } from '../editorTypes';
import DecisionsSection from './parameters/DecisionsSection';
import { BooleanAsString } from '../../../common/booleanAsString';

type Props = {
  templateId: number;
  compactMode?: boolean;
  onValueChange: (parameterInputs: ParameterInput[]) => void;
};

export function NestedParameterSection(props: Props) {
  const { data } = useAllDeepParametersByTemplateIdQuery({ id: props.templateId });
  const [valuesMap, setValuesMap] = useState<Map<number, string>>(new Map());
  const initializedRef = useRef(false);

  const nestedParameters: SafeContentParameters[] = useMemo(() => {
    return (
      (data?.templateAllSubTemplateDecisionParameters
        ?.filter((param) => param)
        .map((param) => ({ parameterId: param!.parameterId, parameter: param })) as SafeContentParameters[]) || []
    );
  }, [data?.templateAllSubTemplateDecisionParameters]);

  const onChange = useCallback(
    (parameterInputs: ParameterInput[]) => {
      props.onValueChange(parameterInputs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onValueChange],
  );

  const setNewValuesMap = useCallback(
    (newValues: Map<number, string>) => {
      setValuesMap(newValues);
      onChange(Array.from(newValues, ([key, value]) => ({ parameterId: key, value })));
    },
    [onChange],
  );

  useEffect(() => {
    if (!data || initializedRef.current) {
      return;
    }
    const parameters = data?.templateAllSubTemplateDecisionParameters?.filter((param) => param) || [];

    setNewValuesMap(
      new Map(
        parameters.map((param) => [
          param!.parameterId,
          param!.enumItems ? param!.enumItems[0].key : BooleanAsString.True,
        ]),
      ),
    );
    initializedRef.current = true;
  }, [data, setNewValuesMap]);

  return (
    <ContentWithHeader
      header={<HeadingWithIcon label="Nested parameters" icon={<i className="fa-solid fa-binary" />} />}
    >
      <div className={props.compactMode ? 'compact' : ''}>
        <DecisionsSection
          decisionParams={nestedParameters}
          decisions={valuesMap as any}
          onChange={(id: number, value: string) => {
            setValuesMap(new Map(valuesMap.set(id, value)));
            onChange(Array.from(valuesMap, ([key, value]) => ({ parameterId: key, value }))); // TODO: fix this
          }}
        />
      </div>
    </ContentWithHeader>
  );
}
