import { SysDataTypeId, SysParameterTypeId } from '../../../../api/generated';
import EntriesSelection from '../../../../components/EntriesSelection';
import { EntryPointParameter } from '../EntryPointsTypes';

type Props = {
  parameters: EntryPointParameter[];
  setParameters: (parameters: EntryPointParameter[]) => void;
  activeParameters: EntryPointParameter[];
  setActiveParameters: (activeParameters: EntryPointParameter[]) => void;
};

function DecisionParametersTab(props: Props) {
  return (
    <EntriesSelection
      availableItemsLabel="Available parameters"
      availableEntries={props.activeParameters.filter(
        (param) => !param.isDecision && (param.type === SysDataTypeId.Bool || param.type === SysDataTypeId.Enum),
      )}
      selectedItemsLabel="Selected parameters"
      selectedEntries={props.activeParameters.filter(
        (param) => param.isDecision && (param.type === SysDataTypeId.Bool || param.type === SysDataTypeId.Enum),
      )}
      getId={(entry) => entry.id as number}
      getName={(entry) => entry.name as string}
      getTooltip={(entry) => entry.description as string}
      getIsSelectable={(entry) => entry.sysParameterTypeId !== SysParameterTypeId.System}
      onSelect={(param) => {
        props.setActiveParameters(
          props.activeParameters.map((parameter) => {
            if (parameter.id === param.id) {
              return { ...param, isDecision: true };
            } else {
              return parameter;
            }
          }),
        );
      }}
      onUnselect={(param) => {
        props.setActiveParameters(
          props.activeParameters.map((parameter) => {
            if (parameter.id === param.id) {
              return { ...param, isDecision: false };
            } else {
              return parameter;
            }
          }),
        );
      }}
    />
  );
}

export default DecisionParametersTab;
