export enum WorkflowEditFormTab {
  BasicInfo,
}

export enum WorkflowViewTab {
  Details,
  References,
}

export type WorkflowUpdate = {
  workflowId: number;
  name: string;
  description: string;
  email: boolean;
  sms: boolean;
  notification: boolean;
};
