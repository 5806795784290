import { ApiRole } from '../../api/ApiRole.types';

import { AppRoute, appRoutes, IAppPage } from '../../route/AppRoute';

export function getNavBarItems(
  isAuthenticated: boolean,
  activeRoute: string,
  roles: ApiRole[],
  dispatch: (path: AppRoute) => void,
) {
  return appRoutes
    .filter((route) => checkRoute(route, isAuthenticated, roles))
    .map((route) => {
      const item = {
        label: route.title,
        icon: route.icon,
        className: route.path() === activeRoute ? 'active' : '',
        command: () => dispatch(route.path()),
      };
      return item;
    });
}

export function checkRoute(route: IAppPage, isAuthenticated: boolean, roles: ApiRole[]) {
  if (isAuthenticated) {
    if (route.isHidden) {
      return false;
    }

    // Allow access to page by default if no role specified
    if (route.require?.length === 0) {
      return true;
    }

    return route.require ? route.require.some((require) => roles.some((role) => role === require)) : true;
  } else {
    return false;
  }
}
