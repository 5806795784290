import { EntryPoint } from '../../../api/generated';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import { CombinedEntryPoint } from '../useEntryPointsWithStatistics';
import EntryPointEntry from './EntryPointEntry';

type Props = {
  entryPoints: CombinedEntryPoint[];
  selectedEntryPointId: number | undefined;
  onSelect: (entryPointId: number) => void;
  onEditor: (entryPointId: number) => void;
  onProperties: (entryPointId: number) => void;
  onDelete: (entryPointId: number) => void;
};

function EntryPointList(props: Props) {
  return (
    <>
      {props.entryPoints.length > 0 ? (
        props.entryPoints.map((entry) => (
          <EntryPointEntry
            key={entry.entryPointId}
            entryPoint={entry as EntryPoint}
            isSelected={entry.entryPointId === props.selectedEntryPointId}
            setSelected={() => props.onSelect(entry.entryPointId)}
            onEditor={() => props.onEditor(entry.entryPointId)}
            onProperties={() => props.onProperties(entry.entryPointId)}
            onDelete={() => props.onDelete(entry.entryPointId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No entry point'} note={'Start by adding an entry point!'} />
      )}
    </>
  );
}

export default EntryPointList;
