import { CodeBlock } from '../../../api/generated';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import SnippetEntry from './SnippetEntry';

type Props = {
  codeBlocks: CodeBlock[] | null;
  selectedCodeBlockId: number | undefined;
  onSelect: (entryPointId: number) => void;
  onEditor: (entryPointId: number) => void;
  onProperties: (entryPointId: number) => void;
  onDelete: (entryPointId: number) => void;
};

function SnippetList(props: Props) {
  return (
    <>
      {props.codeBlocks && props.codeBlocks.length > 0 ? (
        props.codeBlocks.map((entry) => (
          <SnippetEntry
            key={entry.codeBlockId}
            codeBlock={entry as CodeBlock}
            isSelected={entry.codeBlockId === props.selectedCodeBlockId}
            setSelected={() => props.onSelect(entry.codeBlockId)}
            onEditor={() => props.onEditor(entry.codeBlockId)}
            onProperties={() => props.onProperties(entry.codeBlockId)}
            onDelete={() => props.onDelete(entry.codeBlockId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No snippets'} note={'Start by adding a snippet!'} />
      )}
    </>
  );
}

export default SnippetList;
