import { SysDataTypeId, SysParameterTypeId } from '../../../../api/generated';

export enum LinkEditFormTab {
  BasicInfo,
  Parameters,
}

export enum LinkViewTab {
  Details,
  References,
}

export type LinkUpdate = {
  linkId: number;
  name: string;
  description: string;
  parameters: LinkParameter[];
};

export type LinkParameter = {
  id?: number;
  name?: string;
  description?: string | null;
  isDecision?: boolean;
  type?: SysDataTypeId;
  sysParameterTypeId?: SysParameterTypeId;
};
