import { Parameter } from '../../../../api/generated';
import ValueOrPlaceholder from '../../../../components/ValueOrPlaceholder';

type Props = {
  parameter: Parameter;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="parameter-details">
        <div className="field">
          <label htmlFor="queryStringDataType">Technical data type</label>
          <div id="queryStringDataType">
            <ValueOrPlaceholder value={props.parameter.queryStringDataType?.name} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="queryStringName">Technical name</label>
          <div id="queryStringName">
            <ValueOrPlaceholder value={props.parameter.queryStringName} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="name">Name</label>
          <div id="name">
            <ValueOrPlaceholder value={props.parameter.name} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="description">Description</label>
          <div id="description">
            <ValueOrPlaceholder value={props.parameter.description} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="provider">Provider</label>
          <div id="provider">
            <ValueOrPlaceholder value={props.parameter.sysCategory} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="sysDataType">Data type</label>
          <div id="sysDataType">
            <ValueOrPlaceholder value={props.parameter.sysDataType?.name} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="sampleValue">Sample data</label>
          <div id="sampleValue">
            <ValueOrPlaceholder value={props.parameter.sampleValue} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="enumItems">Enums</label>
          {props.parameter.enumItems ? (
            props.parameter.enumItems.map((item) => (
              <div key={item.key}>
                {item.key}: {item.value}
              </div>
            ))
          ) : (
            <div>
              <ValueOrPlaceholder value={null} />
            </div>
          )}
        </div>

        <div className="field">
          <label htmlFor="type">Parameter usage</label>
          <div id="sysParameterTypeId">
            <ValueOrPlaceholder value={props.parameter.sysParameterTypeId} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="anonymize">Anonymize</label>
          <div id="anonymize">
            <ValueOrPlaceholder value={props.parameter.anonymize} />
          </div>
        </div>

        <div className="field">
          <label htmlFor="mandatory">Mandatory</label>
          <div id="mandatory">
            <ValueOrPlaceholder value={props.parameter.mandatory} />
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsTab;
