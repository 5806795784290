import { useCreateWorkflowMutation, Workflow } from '../../../../api/generated';
import useToast from '../../../../hooks/useToast';
import SidePanel from '../../../../components/SidePanel';
import WorkflowEditForm from './WorkflowEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function WorkflowsAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createWorkflow, { isLoading }] = useCreateWorkflowMutation();

  const toast = useToast();

  const defaultWorkflow: Partial<Workflow> = {
    workflowId: 0,
    name: '',
    description: '',
    email: true,
    sms: true,
    notification: true,
  };

  return (
    <SidePanel title="Create Workflow" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <WorkflowEditForm
        isLoading={isLoading}
        workflow={defaultWorkflow as Workflow}
        isNew={true}
        onConfirm={async (workflow: any) => {
          try {
            await createWorkflow({
              input: {
                name: workflow.name,
                description: workflow.description,
                email: workflow.email,
                sms: workflow.sms,
                notification: workflow.notification,
              },
            })
              .unwrap()
              .then(async (response) => {
                toast.success(`Workflow '${workflow.name}' has been created successfully.`);
                onConfirm();
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default WorkflowsAdd;
