import { Image, ImageUpdateInput, useImageQuery, useUpdateImageMutation } from '../../../../api/generated';
import SidePanel from '../../../../components/SidePanel';
import useToast from '../../../../hooks/useToast';
import ImageEditForm from './ImageEditForm';

type Props = {
  imageId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function ImageEdit({ imageId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useImageQuery({ id: imageId });
  const image = data?.imageById;

  const toast = useToast();

  const [updateImage, { isLoading }] = useUpdateImageMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <ImageEditForm
        image={image as Image}
        isLoading={isLoading}
        isNew={false}
        onConfirm={async (image: ImageUpdateInput) => {
          try {
            await updateImage({
              id: imageId,
              input: {
                name: image.name,
                description: image.description,
                alternateText: image.alternateText,
                width: image.width,
                height: image.height,
                source: image.source,
              },
            })
              .unwrap()
              .then(async (_response) => {
                toast.success(`Image '${image.name}' has been updated successfully.`);
                onConfirm();
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default ImageEdit;
