import {
  Parameter,
  SysDataTypeId,
  SysParameterTypeId,
  SysQueryStringDataTypeId,
  useCreateParameterMutation,
} from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import ParameterEditForm from './ParameterEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function ParameterAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createParameter, { isLoading }] = useCreateParameterMutation();

  const toast = useToast();

  const defaultParameter: Parameter = {
    parameterId: 0,
    name: '',
    anonymize: false,
    mandatory: false,
    systemName: '',
    queryStringName: '',
    sampleValue: '',
    description: '',
    sysParameterTypeId: SysParameterTypeId.DataHolder,
    sysDataType: {
      name: SysDataTypeId.String,
      sysDataTypeId: SysDataTypeId.String,
    },
    sysDataTypeId: SysDataTypeId.String,
    queryStringDataType: {
      name: SysQueryStringDataTypeId.String,
      sysQueryStringDataTypeId: SysQueryStringDataTypeId.String,
    },
    queryStringDataTypeId: SysQueryStringDataTypeId.String,
    enumItems: [],
    entryPointParameters: [],
    sysCategory: 'system',
  };

  return (
    <SidePanel title="Create Parameter" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <ParameterEditForm
        parameter={defaultParameter}
        isNew={true}
        isLoading={isLoading}
        onConfirm={async (parameter: Parameter) => {
          await createParameter({
            input: {
              name: parameter.name,
              anonymize: parameter.anonymize,
              mandatory: parameter.mandatory,
              description: parameter.description,
              sampleValue: parameter.sampleValue,
              queryStringName: parameter.queryStringName,
              queryStringDataTypeId: parameter.queryStringDataType
                ?.sysQueryStringDataTypeId as SysQueryStringDataTypeId,
              sysDataTypeId: parameter.sysDataType?.sysDataTypeId as SysDataTypeId,
              sysParameterTypeId: parameter.sysParameterTypeId,
              enumItems: parameter.enumItems?.map((item) => ({ key: item.key, value: item.value })),
              sysCategory: parameter.sysCategory,
            },
          })
            .unwrap()
            .then(() => {
              toast.success(`Parameter ${parameter.name} has been created successfully.`);
              onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default ParameterAdd;
