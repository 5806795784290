import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import 'flag-icons/css/flag-icons.min.css';
import 'primeicons/primeicons.css';
import './styles/index.scss';

const container = document.getElementById('root');

// Check if the root container is not null
if (container) {
  // Create a root
  const root = createRoot(container);

  // Initial render: Render the App inside the root
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
