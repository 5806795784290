import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useCallback, useMemo, useState } from 'react';
import { CodeBlock, EntryPointParameter, Image, Link, Parameter, SubTemplate } from '../../../../api/generated';
import { AppRoute } from '../../../../route/AppRoute';
import { TreeItemData } from './TreeItemData';
import { AvailableObjectType } from './AvailableObjectType';
import TreeItemsHeader from './TreeItemsHeader';
import TreeItem from './TreeItem';

type Props = {
  parameters: EntryPointParameter[];
  codeBlocks: CodeBlock[];
  subTemplates: SubTemplate[];
  images: Image[];
  links: Link[];
  usedObjects: string[];
  onObjectSelect: (object: TreeItemData) => void;
  path: AppRoute;
  isDisabled?: boolean;
  enabledTypes?: AvailableObjectType[]; // undefined means all types are enabled
};

export default function EditorTreeItems(props: Props) {
  const [objectSource, setObjectSource] = useState<AvailableObjectType>(AvailableObjectType.Parameter);
  const [expandedKeys, setExpandedKeys] = useState<any>({ 0: true });

  const [filter, setFilter] = useState('');

  const parameterNodes = useMemo(() => {
    let keyIndex = 3;

    const parametersByCategory = new Map<string, any[]>();

    props.parameters?.forEach((param) => {
      const categoryId = param?.parameter?.sysCategory || 'Undefined';
      const categoryItems = parametersByCategory.get(categoryId);

      const copy = categoryItems ? [...categoryItems] : [];

      copy.push(
        createNodeItem(
          AvailableObjectType.Parameter,
          param.parameter,
          param.parameter.parameterId,
          props.usedObjects,
          keyIndex++,
        ),
      );

      parametersByCategory.set(categoryId, copy);

      return parametersByCategory;
    });

    const parents: any[] = [];
    parametersByCategory.forEach((value, key, _map) => {
      parents.push({
        key: parents.length,
        data: {
          name: key,
          isParent: true,
        },
        children: [...value],
      });
    });

    return parents;
  }, [props.parameters, props.usedObjects]);

  const codeBlockNodes = useMemo(() => {
    let keyIndex = 0;
    const children = props.codeBlocks?.map((codeBlock) =>
      createNodeItem(AvailableObjectType.CodeBlock, codeBlock, codeBlock.codeBlockId, props.usedObjects, keyIndex++),
    );
    return children;
  }, [props.codeBlocks, props.usedObjects]);

  const subTemplateNodes = useMemo(() => {
    let keyIndex = 0;
    const children = props.subTemplates?.map((subTemplate) =>
      createNodeItem(
        AvailableObjectType.SubTemplate,
        subTemplate,
        subTemplate.subTemplateId,
        props.usedObjects,
        keyIndex++,
      ),
    );
    return children;
  }, [props.subTemplates, props.usedObjects]);

  const imageNodes = useMemo(() => {
    let keyIndex = 0;
    const children = props.images?.map((image) =>
      createNodeItem(AvailableObjectType.Image, image, image.imageId, props.usedObjects, keyIndex++),
    );
    return children;
  }, [props.images, props.usedObjects]);

  const linkNodes = useMemo(() => {
    let keyIndex = 0;
    const children = props.links?.map((link) =>
      createNodeItem(AvailableObjectType.Link, link, link.linkId, props.usedObjects, keyIndex++),
    );
    return children;
  }, [props.links, props.usedObjects]);

  const nodes = () => {
    if (filter) {
      let count = 0;
      return [...parameterNodes[0].children, ...codeBlockNodes, ...subTemplateNodes, ...imageNodes, ...linkNodes]
        .filter((item) => item.data.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        .map((item) => {
          item.key = count++;
          return item;
        });
    }

    if (objectSource === AvailableObjectType.Parameter) {
      return parameterNodes;
    }
    if (objectSource === AvailableObjectType.CodeBlock) {
      return codeBlockNodes;
    }
    if (objectSource === AvailableObjectType.SubTemplate) {
      return subTemplateNodes;
    }
    if (objectSource === AvailableObjectType.Image) {
      return imageNodes;
    }
    if (objectSource === AvailableObjectType.Link) {
      return linkNodes;
    }
    return [];
  };
  const expandNode = useCallback((node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  }, []);

  const parametersCount = () => {
    let count = 0;
    parameterNodes.forEach((cat) => (count += cat.children.length));
    return count;
  };

  return (
    <TreeTable
      className="available-objects"
      header={
        <TreeItemsHeader
          selected={objectSource}
          onChange={(source) => {
            setObjectSource(source);
            setFilter('');
          }}
          filter={filter}
          onFilterChange={setFilter}
          parametersCount={parametersCount()}
          codeBlocksCount={codeBlockNodes?.length || 0}
          subTemplatesCount={subTemplateNodes?.length || 0}
          imageCount={imageNodes?.length || 0}
          linksCount={linkNodes?.length || 0}
          path={props.path}
        />
      }
      value={nodes()}
      scrollable
      scrollHeight="calc(100vh - 182px)"
      expandedKeys={expandedKeys}
      onToggle={(e) => {
        setExpandedKeys(e.value);
      }}
    >
      <Column
        field="name"
        expander
        body={(node) => (
          <TreeItem
            isDisabled={props.isDisabled || props.enabledTypes?.includes(node.data.type) === false}
            node={node}
            onObjectSelect={props.onObjectSelect}
            showObjectType={filter !== ''}
          />
        )}
      ></Column>
    </TreeTable>
  );
}

export function createNodeItem(
  type: AvailableObjectType,
  object: Parameter | CodeBlock | SubTemplate | Image | Link,
  objectId: number,
  usedObjects: string[],
  index: number,
) {
  const value = `[[${type}#${object.systemName}#${object.name}]]`;

  return {
    key: index,
    data: {
      name: object.name || type.toString(),
      id: objectId,
      type: type,
      parameter: object,
      value: value,
      sampleValue: type === AvailableObjectType.Parameter && (object as Parameter).sampleValue,
      description: object.description,
      isUsed: usedObjects.includes(value),
    } as TreeItemData,
  };
}
