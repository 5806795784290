import { AppRoute, IAppPage } from '../../route/AppRoute';
import FaCrownIcon from '../../components/Icons/FaCrownIcon';
import Page from '../../components/Page';
import { MasterTemplate, useDeleteMasterTemplateMutation, useMasterTemplatesQuery } from '../../api/generated';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import HeaderPanel from '../../components/HeaderPanel';
import { Button } from 'primereact/button';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import { useCallback, useMemo, useState } from 'react';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import MasterTemplateList from './components/MasterTemplateList';
import MasterTemplateSidebar from './components/Sidebar/MasterTemplateSidebar';
import { generatePath, useNavigate } from 'react-router-dom';

function MasterTemplates() {
  const { data: masterTemplates, isLoading, refetch, error } = useMasterTemplatesQuery();
  const [deleteMasterTemplate] = useDeleteMasterTemplateMutation();

  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [selectedMasterTemplateId, selectMasterTemplateId] = useState<number | undefined>();
  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const navigate = useNavigate();
  const toast = useToast();

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const selectMasterTemplate = useCallback((masterTemplateId: number) => {
    selectMasterTemplateId(masterTemplateId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addMasterTemplate = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editMasterTemplate = useCallback((masterTemplateId: number) => {
    selectMasterTemplateId(masterTemplateId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const openMasterTemplateEditor = useCallback(
    (masterTemplateId: number) => {
      navigate(generatePath(AppRoute.MasterTemplateEditor, { id: masterTemplateId.toString() }));
    },
    [navigate],
  );

  const tryToDeleteMasterTemplate = useCallback(
    async (masterTemplateId: number) => {
      try {
        await deleteMasterTemplate({
          id: masterTemplateId.toString(),
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Master Template '${
                masterTemplates?.allMasterTemplates?.find((item) => item?.masterTemplateId === masterTemplateId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectMasterTemplateId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteMasterTemplate, masterTemplates, refetch, toast],
  );

  const filteredMasterTemplates = useMemo(() => {
    if (searchString) {
      return masterTemplates?.allMasterTemplates?.filter((masterTemplate) =>
        masterTemplate?.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return masterTemplates?.allMasterTemplates;
  }, [masterTemplates?.allMasterTemplates, searchString]);

  return (
    <Page className="page-master-templates" windowTitle={masterTemplatesPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <MasterTemplateSidebar
            masterTemplateId={selectedMasterTemplateId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectMasterTemplateId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={masterTemplatesPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addMasterTemplate} />
        </HeaderPanel>
        <div className="master-templates-content">
          <MasterTemplateList
            masterTemplates={(filteredMasterTemplates as MasterTemplate[]) || []}
            selectedMasterTemplateId={selectedMasterTemplateId}
            onSelect={selectMasterTemplate}
            onEditor={openMasterTemplateEditor}
            onProperties={editMasterTemplate}
            onDelete={tryToDeleteMasterTemplate}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const masterTemplatesPage: IAppPage = {
  title: 'Master Templates',
  icon: <FaCrownIcon />,
  path: () => AppRoute.MasterTemplates,
  page: <MasterTemplates />,
  require: [],
};

export default masterTemplatesPage;
