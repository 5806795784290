import { faArrowRight, faFilter, faLock, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';

type Props<T> = {
  availableItemsLabel: string;
  availableEntries: T[];
  selectedItemsLabel: string;
  selectedEntries: T[];
  height?: number;
  getId: (entry: T) => number;
  getName: (entry: T) => string;
  getLabel?: (entry: T) => JSX.Element;
  getTooltip?: (entry: T) => string | undefined;
  getIsSelectable?: (entry: T) => boolean;
  onSelect: (entry: T) => void;
  onUnselect: (entry: T) => void;
};

function EntriesSelection<T>({
  availableItemsLabel,
  availableEntries,
  selectedItemsLabel,
  selectedEntries,
  height = 310,
  getId,
  getName,
  getLabel,
  getTooltip,
  getIsSelectable,
  onSelect,
  onUnselect,
}: Props<T>): JSX.Element {
  const [availableEntriesFilter, setAvailableEntriesFilter] = useState<string | undefined>();
  const [selectedEntriesFilter, setSelectedEntriesFilter] = useState<string | undefined>();

  return (
    <div className="entries-selection">
      <div className="column">
        <div className="column-label">
          {availableItemsLabel} ({availableEntries.length})
        </div>
        <div className="entry">
          <div className="entry-filter">
            <span className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <FontAwesomeIcon
                  icon={faFilter}
                  className={classNames('icon', { 'icon-active': availableEntriesFilter })}
                />
              </span>
              <InputText
                id="search"
                name="search"
                placeholder="Filter"
                value={availableEntriesFilter}
                className={classNames('p-inputtext-sm block w-full', { 'p-invalid': false })}
                onChange={(event) => setAvailableEntriesFilter(event.target.value)}
              />
            </span>
          </div>
          <div className="entry-list" style={{ height: `calc(100vh - ${height}px)` }}>
            {availableEntries
              .filter((entry) =>
                availableEntriesFilter
                  ? getName(entry).toLowerCase().includes(availableEntriesFilter.toLowerCase())
                  : true,
              )
              .map((entry, index) => (
                <SelectableItem
                  key={`available-item-${index}`}
                  direction="select"
                  entry={entry}
                  getId={getId}
                  getLabel={() => (getLabel ? getLabel(entry) : getName(entry))}
                  getTooltip={() => (getTooltip ? getTooltip(entry) : undefined)}
                  getIsSelectable={getIsSelectable}
                  onSelect={onSelect}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="column">
        <div className="column-label">
          {selectedItemsLabel} ({selectedEntries.length})
        </div>
        <div className="entry">
          <div className="entry-filter">
            <span className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <FontAwesomeIcon
                  icon={faFilter}
                  className={classNames('icon', { 'icon-active': selectedEntriesFilter })}
                />
              </span>
              <InputText
                id="search"
                name="search"
                placeholder="Filter"
                value={selectedEntriesFilter}
                className={classNames('p-inputtext-sm block w-full', { 'p-invalid': false })}
                onChange={(event) => setSelectedEntriesFilter(event.target.value)}
              />
            </span>
          </div>
          <div className="entry-list" style={{ height: `calc(100vh - ${height}px)` }}>
            {selectedEntries
              .filter((entry) =>
                selectedEntriesFilter
                  ? getName(entry).toLowerCase().includes(selectedEntriesFilter.toLowerCase())
                  : true,
              )
              .map((entry, index) => (
                <SelectableItem
                  key={`selected-item-${index}`}
                  direction="unselect"
                  entry={entry}
                  getId={getId}
                  getLabel={() => (getLabel ? getLabel(entry) : getName(entry))}
                  getTooltip={() => (getTooltip ? getTooltip(entry) : undefined)}
                  getIsSelectable={getIsSelectable}
                  onSelect={onUnselect}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectableItem<T>({
  entry,
  direction,
  getId,
  getLabel,
  getTooltip,
  getIsSelectable,
  onSelect,
}: {
  entry: T;
  direction: 'select' | 'unselect';
  getId: (entry: T) => number;
  getLabel: (entry: T) => string | JSX.Element;
  getTooltip: (entry: T) => string | undefined;
  getIsSelectable?: (entry: T) => boolean;
  onSelect: (entry: T) => void;
}) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseOver = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHover(false);
  }, []);

  const isSelectable = getIsSelectable ? getIsSelectable(entry) : true;
  return (
    <div
      key={getId(entry)}
      className={classNames('entry-item', { 'not-selectable': !isSelectable })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => isSelectable && onSelect(entry)}
    >
      {!isSelectable && (
        <div className="entry-state">
          <FontAwesomeIcon icon={faLock} />
        </div>
      )}
      <div className="entry-label">
        {getTooltip(entry) && (
          <Tooltip
            target={'.item-tooltip-' + getId(entry)}
            mouseTrack
            mouseTrackLeft={10}
            content={getTooltip(entry)}
          />
        )}
        <span className={'item-tooltip-' + getId(entry)}>{getLabel(entry)}</span>
      </div>
      <div className="entry-action">
        {isSelectable && isHover && (
          <Button className={direction === 'select' ? 'select' : 'unselect'}>
            <FontAwesomeIcon icon={direction === 'select' ? faArrowRight : faTimes} />
          </Button>
        )}
      </div>
    </div>
  );
}

export default EntriesSelection;
