import { useParams } from 'react-router-dom';
import { SubTemplate, useSubTemplateDetailQuery } from '../../api/generated';
import { useMemo } from 'react';

import { AppRoute, IAppPage } from '../../route/AppRoute';
import useAppNavigate from '../../hooks/useAppNavigate';
import FetchInProgress from '../../components/FetchInProgress';
import SubTemplateEditor from '../../components/SubTemplateEditor';

function SubTemplateEditorPage() {
  const { id } = useParams();
  const { data: subTemplate, isLoading } = useSubTemplateDetailQuery({ id: id || '' });

  const navigate = useAppNavigate();

  const subTemplateParameters = useMemo(
    () => subTemplate?.subTemplateById?.subTemplateParameters ?? [],
    [subTemplate?.subTemplateById?.subTemplateParameters],
  );

  if (!id || subTemplate?.subTemplateById === null) {
    navigate(AppRoute.SubTemplates);
  }
  return isLoading ? (
    <FetchInProgress />
  ) : (
    <SubTemplateEditor
      className="page-sub-template-editor"
      path={AppRoute.SubTemplateEditor}
      contentId={subTemplate?.subTemplateById?.subTemplateId || 0}
      currentTemplateId={subTemplate?.subTemplateById?.subTemplateId}
      content={subTemplate?.subTemplateById as SubTemplate}
      contentParameters={subTemplateParameters}
      isLoading={isLoading}
      require={subTemplateEditorPage.require}
    />
  );
}

const subTemplateEditorPage: IAppPage = {
  title: 'Sub-template Editor',
  path: () => AppRoute.SubTemplateEditor,
  page: <SubTemplateEditorPage />,
  require: [],
  isHidden: true,
};

export default subTemplateEditorPage;
