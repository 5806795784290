import { useWorkflowQuery, Workflow } from '../../../../api/generated';
import { MenuItem } from 'primereact/menuitem';
import { useState } from 'react';
import { WorkflowViewTab } from './WorkflowsTypes';
import { TabMenu } from 'primereact/tabmenu';
import Overlay from '../../../../components/Overlay';
import DetailsTab from './DetailsTab';
import FaRouteIcon from '../../../../components/Icons/FaRouteIcon';
import ReferencesTab from './ReferencesTab';

type Props = {
  id: number;
};

function WorkflowView(props: Props) {
  const { data, isLoading } = useWorkflowQuery({ id: props.id.toString() });
  const workflow = data?.workflowById;

  const [activeIndex, setActiveIndex] = useState<number>(WorkflowViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (workflow) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaRouteIcon />
          </div>
          <div className="name">{workflow.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === WorkflowViewTab.Details && <DetailsTab workflow={workflow as Workflow} />}
          {activeIndex === WorkflowViewTab.References && <ReferencesTab id={props.id.toString()} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading workflow" /> : <>Workflow not found!</>;
  }
}

export default WorkflowView;
