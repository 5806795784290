function FaEditIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6562 1.78125C12.5312 0.90625 13.9375 0.90625 14.8125 1.78125L15.2188 2.1875C16.0938 3.0625 16.0938 4.46875 15.2188 5.34375L9.03125 11.5312C8.78125 11.7812 8.4375 12 8.0625 12.0938L4.9375 13C4.6875 13.0625 4.40625 13 4.21875 12.7812C4 12.5938 3.9375 12.3125 4 12.0625L4.90625 8.9375C5 8.5625 5.21875 8.21875 5.46875 7.96875L11.6562 1.78125ZM13.75 2.84375C13.4688 2.5625 13 2.5625 12.7188 2.84375L11.7812 3.75L13.25 5.21875L14.1562 4.28125C14.4375 4 14.4375 3.53125 14.1562 3.25L13.75 2.84375ZM6.34375 9.34375L5.8125 11.1875L7.65625 10.6562C7.78125 10.625 7.875 10.5625 7.96875 10.4688L12.1875 6.25L10.75 4.8125L6.53125 9.03125C6.4375 9.125 6.375 9.21875 6.34375 9.34375ZM6.25 3C6.65625 3 7 3.34375 7 3.75C7 4.1875 6.65625 4.5 6.25 4.5H2.75C2.03125 4.5 1.5 5.0625 1.5 5.75V14.25C1.5 14.9688 2.03125 15.5 2.75 15.5H11.25C11.9375 15.5 12.5 14.9688 12.5 14.25V10.75C12.5 10.3438 12.8125 10 13.25 10C13.6562 10 14 10.3438 14 10.75V14.25C14 15.7812 12.75 17 11.25 17H2.75C1.21875 17 0 15.7812 0 14.25V5.75C0 4.25 1.21875 3 2.75 3H6.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaEditIcon;
