export function convertCountryCode(code: string): string {
  switch (code) {
    case 'cs':
      return 'cz';
    case 'da':
      return 'dk';
    case 'en':
      return 'us';
    case 'et':
      return 'ee';
    case 'sl':
      return 'si';
    case 'sv':
      return 'se';
    case 'uk':
      return 'ua';
    default:
      return code;
  }
}
