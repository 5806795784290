import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';
import React, { useRef, useState } from 'react';

import EntryCard from '../EntryCard';
import DeleteDialog from '../DeleteDialog';
import FaEditIcon from '../Icons/FaEditIcon';
import FaCogIcon from '../Icons/FaCogIcon';
import FaTrashIcon from '../Icons/FaTrashIcon';
import FaEllipsisVIcon from '../Icons/FaEllipsisVIcon';

type Props = {
  name: string;
  description: string | undefined | null;
  tags: React.ReactNode;
  setSelected: () => void;
  isSelected: boolean;
  onEditor?: () => void;
  onProperties: () => void;
  deleteTitle: string;
  deleteMessage: React.ReactNode;
  onDelete: () => void;
  semaphore?: React.ReactNode;
};

function EntryItem(props: Props) {
  const popupMenu = useRef<Menu>(null);
  const [popupMenuOpened, setPopupMenuOpened] = useState<boolean>(false);

  const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

  const menuItems: MenuItem[] = [
    ...(props.onEditor
      ? [
          {
            label: 'Editor',
            icon: <FaEditIcon />,
            command: (e: MenuItemCommandParams) => {
              props.onEditor!();
              e.originalEvent.stopPropagation();
            },
          },
        ]
      : []),
    {
      label: 'Properties',
      icon: <FaCogIcon />,
      command: (e) => {
        props.onProperties();
        e.originalEvent.stopPropagation();
      },
    },
    {
      separator: true,
    },
    {
      label: 'Delete',
      icon: <FaTrashIcon />,
      className: 'danger',
      command: (e) => {
        setDeleteDialogVisible(true);
        e.originalEvent.stopPropagation();
      },
    },
  ];

  return (
    <EntryCard isSelected={props.isSelected} setSelected={props.setSelected} onDoubleClick={props.onEditor}>
      <div className="status"></div>
      <div className="detail">
        <div className="flex align-items-center">
          <div className="name">{props.name}</div>
          {props.semaphore && <div className="ml-5">{props.semaphore}</div>}
        </div>

        <div className="description">{props.description}</div>
        <div className="tags">{props.tags}</div>
      </div>
      <div
        className={classNames('action', { selected: props.isSelected, active: popupMenuOpened })}
        onClick={(e) => popupMenu.current?.toggle(e)}
      >
        <Menu
          model={menuItems}
          className="user-dropdown-menu"
          popup
          ref={popupMenu}
          onShow={() => setPopupMenuOpened(true)}
          onHide={() => setPopupMenuOpened(false)}
        />
        <DeleteDialog
          message={props.deleteMessage}
          header={props.deleteTitle}
          accept={props.onDelete}
          onHide={() => setDeleteDialogVisible(false)}
          visible={deleteDialogVisible}
        />
        <FaEllipsisVIcon />
      </div>
    </EntryCard>
  );
}

export default EntryItem;
