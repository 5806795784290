import {
  CodeBlock,
  useAssignCodeBlockParametersMutation,
  useCodeBlockQuery,
  useUpdateCodeBlockMutation,
} from '../../../api/generated';
import useToast from '../../../hooks/useToast';
import SidePanel from '../../../components/SidePanel';
import SnippetEditForm from './SnippetEditForm';
import { SnippetUpdate } from './SnippetTypes';

type Props = {
  codeBlockId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function SnippetEdit({ codeBlockId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useCodeBlockQuery({ id: codeBlockId.toString() });
  const codeBlock = data?.codeBlockById;

  const toast = useToast();

  const [updateCodeBlock, { isLoading }] = useUpdateCodeBlockMutation();
  const [assignCodeBlockParameters] = useAssignCodeBlockParametersMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <SnippetEditForm
        codeBlock={codeBlock as CodeBlock}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (codeBlock: SnippetUpdate) => {
          await updateCodeBlock({
            id: codeBlockId.toString() || '',
            input: {
              name: codeBlock.name,
              description: codeBlock.description,
            },
          })
            .unwrap()
            .then(async () => {
              await assignCodeBlockParameters({
                id: codeBlock.codeBlockId.toString() || '',
                input: {
                  parameters: codeBlock.parameters.map((param) => ({
                    parameterId: param.id as number,
                  })),
                },
              })
                .unwrap()
                .then(() => {
                  toast.success(`Snippet '${codeBlock.name}' has been updated successfully.`);
                  onConfirm();
                })
                .catch(toast.error);
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default SnippetEdit;
