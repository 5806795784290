import { SysParameterTypeId } from '../../../../api/generated';
import EntriesSelection from '../../../../components/EntriesSelection';
import { EntryPointParameter } from '../EntryPointsTypes';

type Props = {
  parameters: EntryPointParameter[];
  setParameters: (parameters: EntryPointParameter[]) => void;
  activeParameters: EntryPointParameter[];
  setActiveParameters: (activeParameters: EntryPointParameter[]) => void;
};

function ParametersTab(props: Props) {
  return (
    <EntriesSelection
      availableItemsLabel="Available parameters"
      availableEntries={props.parameters}
      selectedItemsLabel="Selected parameters"
      selectedEntries={props.activeParameters}
      getId={(entry) => entry.id as number}
      getName={(entry) => entry.name as string}
      getTooltip={(entry) => entry.description as string}
      getIsSelectable={(entry) => entry.sysParameterTypeId !== SysParameterTypeId.System}
      onSelect={(param) => {
        props.setParameters(props.parameters.filter((parameter) => parameter.id !== param.id));
        props.setActiveParameters([...props.activeParameters, param]);
      }}
      onUnselect={(param) => {
        props.setActiveParameters(props.activeParameters.filter((parameter) => parameter.id !== param.id));
        props.setParameters([...props.parameters, param]);
      }}
    />
  );
}

export default ParametersTab;
