import { ActionType } from '../../../common/actionTypes';
import NoItemSelected from '../../../components/NoItemSelected';
import FaMousePointerIcon from '../../../components/Icons/FaMousePointerIcon';
import ParameterView from './ParameterView';
import ParameterAdd from './ParameterAdd';
import ParameterEdit from './ParameterEdit';

type Props = {
  parameterId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

export const ParameterSidebar = ({
  parameterId,
  sidebarVisible,
  onSidebarClose,
  action,
  setAction,
  refetch,
}: Props) => {
  if (action === ActionType.Add) {
    return (
      <ParameterAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (parameterId && action === ActionType.Edit) {
    return (
      <ParameterEdit
        parameterId={parameterId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (parameterId && action === ActionType.View) {
    return <ParameterView id={parameterId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No parameter selected'}
        note={'Select any to see its details'}
      />
    );
  }
};
