import { Link } from '../../../../../api/generated';
import ValueOrPlaceholder from '../../../../../components/ValueOrPlaceholder';

type Props = {
  link: Link;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <Field label="Name" value={props.link.name} />
        <Field label="Description" value={props.link.description} />
        <Field
          label="Selected parameters"
          value={
            props.link.linkParameters && props.link.linkParameters.length ? (
              props.link.linkParameters.map((eP, index) => {
                return <div key={`parameter-${index}`}>{eP?.parameter.name}</div>;
              })
            ) : (
              <ValueOrPlaceholder value="-" />
            )
          }
        />
      </div>
    </>
  );
}

function Field(props: { label: string; value: any }) {
  return (
    <div className="field">
      <label>{props.label}</label>
      <div>{props.value}</div>
    </div>
  );
}

export default DetailsTab;
