function FaLocationDotIcon() {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 6C8.5 7.40625 7.375 8.5 6 8.5C4.59375 8.5 3.5 7.40625 3.5 6C3.5 4.625 4.59375 3.5 6 3.5C7.375 3.5 8.5 4.625 8.5 6ZM6 5C5.4375 5 5 5.46875 5 6C5 6.5625 5.4375 7 6 7C6.53125 7 7 6.5625 7 6C7 5.46875 6.53125 5 6 5ZM12 6C12 8.75 8.34375 13.5938 6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625C3.625 13.5938 0 8.75 0 6C0 2.6875 2.65625 0 6 0C9.3125 0 12 2.6875 12 6ZM6 1.5C3.5 1.5 1.5 3.53125 1.5 6C1.5 6.40625 1.625 7 1.96875 7.8125C2.28125 8.5625 2.75 9.4375 3.28125 10.3125C4.1875 11.75 5.21875 13.125 6 14.125C6.75 13.125 7.78125 11.75 8.6875 10.3125C9.21875 9.4375 9.6875 8.5625 10 7.8125C10.3438 7 10.5 6.40625 10.5 6C10.5 3.53125 8.46875 1.5 6 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaLocationDotIcon;
