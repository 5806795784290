function FaFileLinesIcon() {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4062 2.9375L9.0625 0.59375C8.6875 0.21875 8.1875 0 7.65625 0H2C0.875 0 0 0.90625 0 2V14C0 15.125 0.875 16 2 16H10C11.0938 16 12 15.125 12 14V4.34375C12 3.8125 11.7812 3.3125 11.4062 2.9375ZM10.5 14C10.5 14.2812 10.25 14.5 10 14.5H2C1.71875 14.5 1.5 14.2812 1.5 14V2.03125C1.5 1.75 1.71875 1.53125 2 1.53125H7V4C7 4.5625 7.4375 5 8 5H10.4688V14H10.5ZM3 8.75C3 9.1875 3.3125 9.5 3.75 9.5H8.25C8.65625 9.5 9 9.1875 9 8.75C9 8.34375 8.65625 8 8.25 8H3.75C3.3125 8 3 8.34375 3 8.75ZM8.25 11H3.75C3.3125 11 3 11.3438 3 11.75C3 12.1875 3.3125 12.5 3.75 12.5H8.25C8.65625 12.5 9 12.1875 9 11.75C9 11.3438 8.65625 11 8.25 11Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaFileLinesIcon;
