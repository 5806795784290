// src/App.tsx
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';

import { api } from './api/graphApi';
import { TokenProvider } from './providers/TokenProvider';
import { AuthProvider } from './providers/AuthProvider';
import DynamicRoutes from './route/DynamicRoutes';
import { ProfilePhotoProvider } from './providers/ProfilePhotoProvider';
import { ToastProvider } from './providers/ToastProvider';
import { store } from './app/store';
import { Provider } from 'react-redux';

const App: React.FC = () => {
  return (
    <TokenProvider>
      <AuthProvider>
        <ProfilePhotoProvider>
          <ApiProvider api={api}>
            <Provider store={store}>
              <ToastProvider>
                <Router>
                  <DynamicRoutes />
                </Router>
              </ToastProvider>
            </Provider>
          </ApiProvider>
        </ProfilePhotoProvider>
      </AuthProvider>
    </TokenProvider>
  );
};

export default App;
