import { createContext, PropsWithChildren, useContext, useEffect } from 'react';

import useIsAuthenticated from '../hooks/useIsAuthenticated';
import useProfilePhoto from '../hooks/useProfilePhoto';

export interface IProfilePhotoContext {
  avatar: string | null;
  fetch: () => void;
}

export const ProfilePhotoContext = createContext<IProfilePhotoContext>({
  avatar: null,
  fetch: () => {},
});

export function ProfilePhotoProvider(props: PropsWithChildren) {
  const api = useProfilePhoto();
  const isAuthenticated = useIsAuthenticated();

  const providerValue: IProfilePhotoContext = {
    avatar: api.image,
    fetch: api.fetchData,
  };

  useEffect(() => {
    isAuthenticated && api.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <ProfilePhotoContext.Provider value={providerValue}>{props.children}</ProfilePhotoContext.Provider>;
}

export const useProfilePhotoContext = () => useContext(ProfilePhotoContext);
