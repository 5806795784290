import Page from '../../components/Page';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ApiRole } from '../../api/ApiRole.types';
import FaRouteIcon from '../../components/Icons/FaRouteIcon';
import { useDeleteWorkflowMutation, useWorkflowsQuery, Workflow } from '../../api/generated';
import { useCallback, useMemo, useState } from 'react';
import useToast from '../../hooks/useToast';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import HeaderPanel from '../../components/HeaderPanel';
import { Button } from 'primereact/button';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import { ActionType } from '../../common/actionTypes';
import WorkflowList from './components/WorkflowList';
import WorkflowSidebar from './components/Sidebar/WorkflowSidebar';

function Workflows() {
  const { data: workflows, isLoading, refetch, error } = useWorkflowsQuery();
  const [selectedWorkflowId, selectWorkflowId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteWorkflow] = useDeleteWorkflowMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const toast = useToast();

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const selectWorkflow = useCallback((workflowId: number) => {
    selectWorkflowId(workflowId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addWorkflow = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editWorkflow = useCallback((workflowId: number) => {
    selectWorkflowId(workflowId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const tryToDeleteWorkflow = useCallback(
    async (workflowId: number) => {
      try {
        await deleteWorkflow({
          id: workflowId.toString(),
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Workflow '${
                workflows?.allWorkflows?.find((item) => item?.workflowId === workflowId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectWorkflowId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteWorkflow, workflows, refetch, toast],
  );

  const filteredWorkflows = useMemo(() => {
    if (searchString) {
      return workflows?.allWorkflows?.filter((workflow) =>
        workflow?.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return workflows?.allWorkflows;
  }, [workflows?.allWorkflows, searchString]);

  return (
    <Page className="page-workflows" windowTitle={workflowsPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <WorkflowSidebar
            workflowId={selectedWorkflowId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectWorkflowId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={workflowsPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addWorkflow} />
        </HeaderPanel>
        <div className="workflows-content">
          <WorkflowList
            workflows={(filteredWorkflows as Workflow[]) || []}
            selectedWorkflowId={selectedWorkflowId}
            onSelect={selectWorkflow}
            onProperties={editWorkflow}
            onDelete={tryToDeleteWorkflow}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const workflowsPage: IAppPage = {
  title: 'Workflows',
  icon: <FaRouteIcon />,
  path: () => AppRoute.Workflows,
  page: <Workflows />,
  require: [ApiRole.Admin],
};

export default workflowsPage;
