function FaMousePointerIcon() {
  return (
    <svg width="58" height="82" viewBox="0 0 58 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.1328 44.8125L7.25781 1.32812C6.53906 0.789062 5.46094 0.25 4.5625 0.25C3.84375 0.25 3.30469 0.429688 2.76562 0.789062C1.14844 1.50781 0.25 2.94531 0.25 4.74219V71.2266C0.25 73.0234 1.32812 74.6406 2.94531 75.1797C3.48438 75.3594 4.02344 75.5391 4.5625 75.5391C5.64062 75.5391 6.89844 75 7.79688 73.9219L22.7109 56.3125L33.3125 78.4141C34.2109 80.5703 36.9062 81.4688 38.8828 80.3906C41.0391 79.3125 42.1172 76.7969 41.0391 74.6406L30.4375 52.3594H53.2578C55.0547 52.3594 56.6719 51.2812 57.3906 49.6641C57.9297 47.8672 57.5703 46.0703 56.1328 44.8125ZM21.9922 43.7344L8.875 59.3672V14.2656L41.9375 43.7344H21.9922Z"
        fill="#CFD2D6"
      />
    </svg>
  );
}

export default FaMousePointerIcon;
