import { FormikField } from '../../../../../components/FormikField';

function BasicInfoTab() {
  return (
    <>
      <FormikField label="Name" type="text" name="name" />
      <FormikField label="Description" type="textarea" name="description" />
    </>
  );
}

export default BasicInfoTab;
