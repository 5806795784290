import { createApi } from '@reduxjs/toolkit/query/react';
import { GraphQLClient } from 'graphql-request';

import { graphqlRequestBaseQuery } from './graphqlRequestBaseQuery';

const url = process.env.REACT_APP_GRAPHQL_API as string;
const uri = (process.env.REACT_APP_MODE as string) === 'Development' ? '/proxy' : url;

export const client = new GraphQLClient(uri);

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    options: {
      client,
    },
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
