import { Link, useAssignLinkParametersMutation, useCreateLinkMutation } from '../../../../api/generated';
import SidePanel from '../../../../components/SidePanel';
import useToast from '../../../../hooks/useToast';
import LinkEditForm from './LinkEditForm';
import { LinkUpdate } from './LinksTypes';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function LinkAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createLink, { isLoading }] = useCreateLinkMutation();
  const [assignLinkParameters] = useAssignLinkParametersMutation();

  const toast = useToast();

  const defaultLink: Partial<Link> = {
    linkId: 0,
    name: '',
    description: '',
    linkParameters: [],
  };

  return (
    <SidePanel title="Create Link" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <LinkEditForm
        isLoading={isLoading}
        link={defaultLink as Link}
        isNew={true}
        onConfirm={async (link: LinkUpdate) => {
          try {
            await createLink({
              input: {
                name: link.name,
                description: link.description,
              },
            })
              .unwrap()
              .then(async (response) => {
                await assignLinkParameters({
                  id: response.createLink?.linkId.toString() || '',
                  input: {
                    parameters: link.parameters.map((param: any) => ({
                      parameterId: param.id as number,
                    })),
                  },
                })
                  .then(() => {
                    toast.success(`Link '${link.name}' has been created successfully.`);
                    onConfirm();
                  })
                  .catch(toast.error);
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default LinkAdd;
