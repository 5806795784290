import { SubTemplate } from '../../../api/generated';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import SubTemplateEntry from './SubTemplateEntry';

type Props = {
  subTemplates: SubTemplate[] | null;
  selectedSubTemplateId: number | undefined;
  onSelect: (entryPointId: number) => void;
  onEditor: (entryPointId: number) => void;
  onProperties: (entryPointId: number) => void;
  onDelete: (entryPointId: number) => void;
};

function SubTemplateList(props: Props) {
  return (
    <>
      {props.subTemplates && props.subTemplates.length > 0 ? (
        props.subTemplates.map((entry) => (
          <SubTemplateEntry
            key={entry.subTemplateId}
            subTemplate={entry as SubTemplate}
            isSelected={entry.subTemplateId === props.selectedSubTemplateId}
            setSelected={() => props.onSelect(entry.subTemplateId)}
            onEditor={() => props.onEditor(entry.subTemplateId)}
            onProperties={() => props.onProperties(entry.subTemplateId)}
            onDelete={() => props.onDelete(entry.subTemplateId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No sub-templates'} note={'Start by adding a sub-template!'} />
      )}
    </>
  );
}

export default SubTemplateList;
