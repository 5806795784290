import { Link } from '../../../api/generated';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import LinkEntry from './LinkEntry';

type Props = {
  links: Link[] | null;
  selectedLinkId: number | undefined;
  onSelect: (linkId: number) => void;
  onEditor: (linkId: number) => void;
  onProperties: (linkId: number) => void;
  onDelete: (linkId: number) => void;
};

function LinkList(props: Props) {
  return (
    <>
      {props.links && props.links.length > 0 ? (
        props.links.map((link) => (
          <LinkEntry
            key={link.linkId}
            link={link as Link}
            isSelected={link.linkId === props.selectedLinkId}
            setSelected={() => props.onSelect(link.linkId)}
            onEditor={() => props.onEditor(link.linkId)}
            onProperties={() => props.onProperties(link.linkId)}
            onDelete={() => props.onDelete(link.linkId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No links'} note={'Start by adding a link!'} />
      )}
    </>
  );
}

export default LinkList;
