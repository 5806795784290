import { Link, useAssignLinkParametersMutation, useLinkQuery, useUpdateLinkMutation } from '../../../../api/generated';
import SidePanel from '../../../../components/SidePanel';
import useToast from '../../../../hooks/useToast';
import LinkEditForm from './LinkEditForm';
import { LinkUpdate } from './LinksTypes';

type Props = {
  linkId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function LinkEdit({ linkId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useLinkQuery({ id: linkId });
  const link = data?.linkById;

  const toast = useToast();

  const [updateLink, { isLoading }] = useUpdateLinkMutation();
  const [assignLinkParameters] = useAssignLinkParametersMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <LinkEditForm
        link={link as Link}
        isLoading={isLoading}
        isNew={false}
        onConfirm={async (link: LinkUpdate) => {
          try {
            await updateLink({
              id: linkId,
              input: {
                name: link.name,
                description: link.description,
              },
            })
              .unwrap()
              .then(async () => {
                await assignLinkParameters({
                  id: linkId.toString() || '',
                  input: {
                    parameters: link.parameters.map((param) => ({
                      parameterId: param.id as number,
                    })),
                  },
                })
                  .then(() => {
                    toast.success(`Link '${link.name}' has been updated successfully.`);
                    onConfirm();
                  })
                  .catch(toast.error);
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default LinkEdit;
