import { useField, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';

import { capitalizeFirstLetter } from '../../common/stringUtils';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

export function FormikField(props: IFormikFieldProps): JSX.Element {
  const formik = useFormikContext();
  const [field, meta] = useField(props.name);
  const fileUploadRef = useRef<FileUpload | null>(null);
  const shouldDisplayError: boolean = meta.error && meta.touched ? true : false;

  let element: JSX.Element | null = null;
  let inlineElement: JSX.Element | null = null;

  const commonProps = {
    id: props.name,
    disabled: formik.isSubmitting,
    name: props.name,
    value: field.value,
    onChange<T = string | React.ChangeEvent<unknown>>(
      field: T,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<unknown>) => void {
      if (props.onChange) {
        props.onChange(field as string | React.ChangeEvent<unknown>);
      }
      return formik.handleChange(field);
    },
  };

  const handleBase64ImageUpload = async (e: { files: File[] }) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imageProps = props as IFormikImageFieldProps;
      const img = new Image();
      img.onload = () => {
        formik.setFieldValue(imageProps.name, reader.result);
        if (imageProps.nameWidth) formik.setFieldValue(imageProps.nameWidth, img.width);
        if (imageProps.nameHeight) formik.setFieldValue(imageProps.nameHeight, img.height);
        if (imageProps.nameMimeType) formik.setFieldValue(imageProps.nameMimeType, file.type);
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue(props.name, '');
    fileUploadRef?.current?.clear();
  };

  switch (props.type) {
    case 'select':
      element = (
        <Dropdown
          {...commonProps}
          placeholder={props.placeholder}
          options={props.options}
          optionValue="id"
          optionLabel="name"
          className={classNames('w-full', { 'p-invalid': shouldDisplayError })}
        />
      );
      break;
    case 'multiselect':
      element = (
        <MultiSelect
          {...commonProps}
          placeholder={props.placeholder}
          options={props.options.map((option) => ({ ...option, name: capitalizeFirstLetter(option.name) }))}
          optionValue="id"
          optionLabel="name"
          selectedItemTemplate={(value) => {
            return !value || value !== field.value[0]
              ? ''
              : (field.value || [])
                  .map((innerValue: string) =>
                    capitalizeFirstLetter(props.options.find((option) => option.id === innerValue)?.name || ''),
                  )
                  .join(', ');
          }}
          className={classNames('w-full', { 'p-invalid': shouldDisplayError })}
        />
      );
      break;
    case 'textarea':
      element = (
        <InputTextarea
          {...commonProps}
          rows={4}
          autoResize
          className={classNames('w-full', { 'p-invalid': shouldDisplayError })}
        />
      );
      break;
    case 'number':
      element = (
        <InputNumber
          {...commonProps}
          className={classNames('w-full', { 'p-invalid': shouldDisplayError })}
          onValueChange={(e) => formik.setFieldValue(props.name, e.value)} // Handle number value change
          // showButtons // Show increment and decrement buttons
          // mode="decimal" // Optional: specify the mode (decimal or currency)
          // min={props.min} // Optional: specify minimum value
          // max={props.max} // Optional: specify maximum value
        />
      );
      break;
    case 'checkbox':
      inlineElement = (
        <InputSwitch
          id={props.name.toLowerCase()}
          name={props.name.toLowerCase()}
          checked={field.value}
          onChange={(e) => {
            formik.setFieldValue(props.name, e.value);
          }}
        />
      );
      break;
    case 'image':
      element = (
        <>
          {!field.value ? (
            <FileUpload
              ref={fileUploadRef}
              name={props.name}
              mode="basic"
              accept="image/*"
              multiple={false}
              auto
              customUpload
              uploadHandler={handleBase64ImageUpload}
              chooseLabel={'Select image'}
            />
          ) : (
            <Button label="Remove image" className="p-button-sm" onClick={handleRemoveImage} />
          )}
          {!!field.value && (
            <div className="p-d-flex p-ai-center" style={{ marginTop: '1rem' }}>
              <img src={field.value} alt="Selected" style={{ maxWidth: '680px', marginRight: '1rem' }} />
            </div>
          )}
        </>
      );
      break;
    case 'text':
    default:
      element = <InputText {...commonProps} className={classNames('w-full', { 'p-invalid': shouldDisplayError })} />;
  }

  const tooltipRef =
    'tooltip-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  return (
    <div className="field w-full">
      {props.tooltip && (
        <Tooltip position="bottom" target={`.${tooltipRef}`}>
          {props.tooltip}
        </Tooltip>
      )}
      <div className="flex align-items-center gap-4">
        <span className={classNames('mb-1', { 'flex-grow-1': !!element })}>
          <label htmlFor={props.name} className={classNames({ 'p-invalid': shouldDisplayError })}>
            {props.label}
          </label>
        </span>
        {inlineElement}
        {props.tooltip && <div className={tooltipRef}>?</div>}
      </div>
      {element}
      {shouldDisplayError && <small className="p-error block">{meta.error}</small>}
    </div>
  );
}
interface IFormikFieldPropsBase {
  name: string;
  label: string;
  type: 'text' | 'textarea' | 'select' | 'multiselect' | 'number' | 'image' | 'checkbox';
  onChange?: (e: string | React.ChangeEvent<unknown>) => void;
  tooltip?: React.ReactNode;
}

interface IFormikTextFieldProps extends IFormikFieldPropsBase {
  type: 'text' | 'textarea';
}

interface IFormikSelectFieldProps extends IFormikFieldPropsBase {
  type: 'select' | 'multiselect';
  placeholder?: string;
  options: { id: string; name: string }[];
}

interface IFormikNumberFieldProps extends IFormikFieldPropsBase {
  type: 'number';
  // min?: number;
  // max?: number;
}

interface IFormikCheckboxFieldProps extends IFormikFieldPropsBase {
  type: 'checkbox';
}

interface IFormikImageFieldProps extends IFormikFieldPropsBase {
  type: 'image';
  nameWidth?: string;
  nameHeight?: string;
  nameMimeType?: string;
}

export type IFormikFieldProps =
  | IFormikTextFieldProps
  | IFormikSelectFieldProps
  | IFormikNumberFieldProps
  | IFormikImageFieldProps
  | IFormikCheckboxFieldProps;
