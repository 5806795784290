import { useMap } from 'usehooks-ts';
import {
  EntryPointContentMatrixRow,
  KeyValuePairOfInt32AndString,
  ParameterInput,
  useLazyEntryPointContentMatrixQuery,
  useLazyTemplatePreviewByContentIdParametersQuery,
} from '../../../../api/generated';
import { SafeContentParameters } from '../../../Editor/editorTypes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getDecisionParams, getSystemParams } from '../../../Editor/editorUtils';
import { BooleanAsString } from '../../../../common/booleanAsString';
import { convertCountryCode } from '../../../../common/countryUtils';
import { ColumnSortOrderType } from 'primereact/column';
import { useAdvanced } from '../../useAdvanced';

export interface IMatrixPreviewData {
  rows: EntryPointContentMatrixRow[];
  parameterColumns: IMatrixColumn[];
  decisionParams: SafeContentParameters[];
  filterValues: FilterValues;
  handleFilterChange: (id: number, value: string | undefined) => void;
  setNestedParameterCustomValue: (params: ParameterInput[]) => void;
  isLoading: boolean;
  setFilterOptions: (options: { field: number | null; order: ColumnSortOrderType }) => void;
  filterOptions: { field: number | null; order: ColumnSortOrderType };
}

export interface IMatrixColumn {
  header: string;
  paramId?: number;
  values?: Map<string, string | JSX.Element>;
  body: (data: EntryPointContentMatrixRow) => string | JSX.Element | undefined;
}

export type FilterValues = Omit<Map<number, string>, 'set' | 'clear' | 'delete'>;

export function useMatrixPreview(
  entryPointId: number,
  params: SafeContentParameters[],
  customValueParameterInputs: ParameterInput[],
  langsUsingDefault: string[],
  isActive: boolean,
): IMatrixPreviewData {
  const { filterValues, filterValueInputs, handleFilterChange } = useFilterValues();
  const [nestedDecisionParameterInputs, setNestedDecisionParameterInputs] = useState<ParameterInput[]>([]);
  const [filterOptions, setFilterOptions] = useState<{ field: number | null; order: ColumnSortOrderType }>({
    field: null,
    order: 0,
  });

  const { paramValuesMap, langsParameterId } = useParamValuesMap(params, langsUsingDefault);
  const paramNameMap = useParamNameMap(params);

  const { matrixData, isFetching } = useEffectFetch(
    isActive,
    entryPointId,
    filterValueInputs,
    customValueParameterInputs,
    nestedDecisionParameterInputs,
  );

  const sortedMatrixData = useMemo(() => {
    if (filterOptions.field) {
      return [...matrixData].sort((a, b) => {
        const aValue = a.parameterValues?.find((pv) => pv?.key === filterOptions.field)?.value || '';
        const bValue = b.parameterValues?.find((pv) => pv?.key === filterOptions.field)?.value || '';
        return filterOptions.order === 1 ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      });
    }
    return matrixData;
  }, [matrixData, filterOptions.field, filterOptions.order]);

  const parameterColumns =
    matrixData?.[0]?.parameterValues?.map((param) => ({
      header: getColumnName(paramNameMap, param),
      paramId: param?.key,
      values: paramValuesMap.get(param?.key) || undefined,
      body: (data: EntryPointContentMatrixRow) => {
        const value = data.parameterValues?.find((pv) => pv?.key === param?.key)?.value || '';
        if (param?.key === langsParameterId) {
          return (
            <div className="d-flex gap-2">
              <span className={`fi fi-${convertCountryCode(value)}`} />
              {value.toUpperCase()}
            </div>
          );
        }
        return paramValuesMap.get(param.key)?.get(value);
      },
    })) || [];

  return {
    rows: sortedMatrixData,
    parameterColumns: parameterColumns,
    decisionParams: getDecisionParams(params),
    filterValues: filterValues,
    handleFilterChange: handleFilterChange,
    setNestedParameterCustomValue: setNestedDecisionParameterInputs,
    isLoading: isFetching,
    setFilterOptions: setFilterOptions,
    filterOptions: filterOptions,
  };
}

function getColumnName(paramNameMap: Map<number, string | undefined>, param: KeyValuePairOfInt32AndString): string {
  const name = paramNameMap.get(param?.key);
  if (name === 'SysMessageType') {
    return 'Notification type';
  }
  if (name === 'SysLanguage') {
    return 'Language';
  }
  return name || '';
}

function useEffectFetch(
  isActive: boolean,
  entryPointId: number,
  filterValueInputs: ParameterInput[],
  customValueParameterInputs: ParameterInput[],
  nestedParameterCustomValues: ParameterInput[],
) {
  const [advanced] = useAdvanced();
  const requestCounterRef = useRef(0);
  const [matrixData, setMatrixData] = useState<EntryPointContentMatrixRow[]>([]);
  const [getSavedPreview] = useLazyTemplatePreviewByContentIdParametersQuery({});
  const [getMatrix, { isFetching }] = useLazyEntryPointContentMatrixQuery({});

  const lazyLoadPreview = useCallback(
    async (row: EntryPointContentMatrixRow) => {
      if (!row.contentId) return;
      const customValues = advanced ? [...customValueParameterInputs, ...nestedParameterCustomValues] : [];
      try {
        const response = await getSavedPreview({
          input: {
            contentId: row.contentId,
            parameters: [...convertToParameterInput(row.parameterValues), ...customValues],
          },
        });
        const params = response.data?.templatePreviewByContentIdParameters;
        updateMatrixData(setMatrixData, row.contentId, params?.body, params?.subject);
      } catch (error) {
        console.error(error);
      }
    },
    [advanced, customValueParameterInputs, getSavedPreview, nestedParameterCustomValues],
  );

  useEffect(() => {
    if (isActive) {
      const currentRequest = ++requestCounterRef.current;

      async function fetchData() {
        try {
          const response = await getMatrix({
            id: entryPointId,
            input: { render: false, parameters: filterValueInputs },
          });
          if (currentRequest === requestCounterRef.current) {
            response.data?.entryPointContentMatrix?.forEach((row) => row?.contentId && lazyLoadPreview(row));
            setMatrixData(
              (response.data?.entryPointContentMatrix?.filter((row) => !!row) || []) as EntryPointContentMatrixRow[],
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }
  }, [
    filterValueInputs,
    entryPointId,
    getMatrix,
    isActive,
    customValueParameterInputs,
    nestedParameterCustomValues,
    advanced,
    lazyLoadPreview,
  ]);

  return { matrixData, isFetching };
}

function updateMatrixData(
  setMatrixData: React.Dispatch<React.SetStateAction<EntryPointContentMatrixRow[]>>,
  contentId: number,
  newBody?: string | null,
  newSubject?: string | null,
) {
  setMatrixData((prevData) =>
    prevData.map((item) =>
      item.contentId === contentId
        ? {
            ...item,
            body: newBody,
            subject: newSubject,
          }
        : item,
    ),
  );
}

function useParamNameMap(params: SafeContentParameters[]) {
  return useMemo(
    () => new Map(params.filter((param) => !!param).map((param) => [param!.parameterId, param!.parameter?.name])),
    [params],
  );
}

function useParamValuesMap(params: SafeContentParameters[], langsUsingDefault: string[]) {
  return useMemo(() => {
    const decisionParams = getDecisionParams(params);
    const systemParams = getSystemParams(params);
    const messageType = systemParams.find((p) => p?.parameter?.name === 'SysMessageType');
    const langs = systemParams.find((p) => p?.parameter?.name === 'SysLanguage');
    const langsParameterId = langs?.parameterId;

    const valuesMap = new Map<number, Map<string, string | JSX.Element>>();
    decisionParams.forEach((param) => {
      if (param) {
        if (param.parameter?.enumItems) {
          valuesMap.set(param.parameterId, new Map(param.parameter.enumItems.map((item) => [item.key, item.value])));
        } else {
          valuesMap.set(
            param.parameterId,
            new Map([
              [BooleanAsString.True, 'True'],
              [BooleanAsString.False, 'False'],
            ]),
          );
        }
      }
      return valuesMap;
    });
    if (messageType?.parameter?.enumItems) {
      valuesMap.set(
        messageType.parameterId,
        new Map(messageType.parameter.enumItems.filter((item) => item.enabled).map((item) => [item.key, item.value])),
      );
    }
    if (langs?.parameter?.enumItems) {
      valuesMap.set(
        langs.parameterId,
        new Map(
          langs.parameter.enumItems
            .filter((lang) => !langsUsingDefault.includes(lang.key))
            .map((item) => [
              item.key,
              <div className="d-flex gap-2">
                <span className={`fi fi-${convertCountryCode(item.key)}`} />
                {item.key.toUpperCase()}
              </div>,
            ]),
        ),
      );
    }
    return { paramValuesMap: valuesMap, langsParameterId };
  }, [langsUsingDefault, params]);
}

function useFilterValues() {
  const [filterValues, { set: setFilterValue, remove: removeFilterValue }] = useMap<number, string>();

  const filterValueInputs: ParameterInput[] = useMemo(
    () =>
      Array.from(filterValues.entries()).map(([key, value]) => ({
        parameterId: key,
        value: value,
      })),
    [filterValues],
  );

  const handleFilterChange = (id: number, value: string | undefined) => {
    if (value) {
      setFilterValue(id, value);
    } else {
      removeFilterValue(id);
    }
  };

  return { filterValues, filterValueInputs, handleFilterChange };
}

function convertToParameterInput(params: KeyValuePairOfInt32AndString[]): ParameterInput[] {
  return params.map((param) => ({ parameterId: param.key, value: param.value || '' }));
}
