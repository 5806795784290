import { classNames } from 'primereact/utils';

type Props = {
  label: string;
  icon: React.ReactNode;
  size?: 'normal' | 'large';
  debug?: string;
};

function HeadingWithIcon(props: Props) {
  return (
    <div className="heading-with-icon">
      <div className="icon">{props.icon}</div>
      <div className={classNames('label', { large: props.size === 'large' })}>{props.label}</div>
      {props.debug && <div className="debug">{props.debug}</div>}
    </div>
  );
}

export default HeadingWithIcon;
