import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { useState } from 'react';

import FaFileLinesIcon from '../../../components/Icons/FaFileLinesIcon';
import { SubTemplate, useSubTemplateQuery } from '../../../api/generated';
import { SubTemplateViewTab } from './SubTemplateTypes';
import Overlay from '../../../components/Overlay';
import ReferencesTab from './ReferencesTab';
import DetailsTab from './DetailsTab';

type Props = {
  id: number;
};

function SubTemplateView(props: Props) {
  const { data, isLoading } = useSubTemplateQuery({ id: props.id.toString() });
  const subTemplate = data?.subTemplateById;

  const [activeIndex, setActiveIndex] = useState<number>(SubTemplateViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (subTemplate) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaFileLinesIcon />
          </div>
          <div className="name">{subTemplate.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === SubTemplateViewTab.Details && <DetailsTab subTemplate={subTemplate as SubTemplate} />}
          {activeIndex === SubTemplateViewTab.References && <ReferencesTab id={props.id.toString()} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading sub-template" /> : <>Sub-template not found!</>;
  }
}

export default SubTemplateView;
