import * as Yup from 'yup';
import { addCustomYupMethods, ValidationMessage } from '../../../common/validationUtils';

addCustomYupMethods();

const basicSchema = (parameterNames: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(256)
      //@ts-ignore
      .nonSystemParameter()
      //@ts-ignore
      .uniqueIn(parameterNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
    queryStringName: Yup.string()
      .max(256)
      //@ts-ignore
      .validQueryStringName()
      .required(ValidationMessage.REQUIRED),
    sysCategory: Yup.string().required(ValidationMessage.REQUIRED),
  });

export function useValidationSchema(parameterNames: string[]) {
  return basicSchema(parameterNames);
}
