import { faCheckCircle, faSave, faSpinner, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from 'primereact/button';

type Props = {
  unsavedChanges: boolean;
  saveSuccessful: boolean;
  onSave: () => void;
  onClear?: () => void;
  isLoading: boolean;
  isSaving: boolean;
  isDisabled?: boolean;
};

export default function SavePanel(props: Props) {
  return (
    <div className="save-panel d-flex gap-2">
      <SaveButton {...props} />
      <StatusMessage {...props} />
    </div>
  );
}

function SaveButton(props: Props) {
  return props.onClear ? (
    <SplitButton
      disabled={props.isDisabled}
      label="Save changes"
      className="p-button-sm"
      icon={<FontAwesomeIcon icon={faSave} />}
      onClick={props.onSave}
      model={[
        {
          label: 'Clear content',
          command: props.onClear,
        },
      ]}
    >
      Save changes
    </SplitButton>
  ) : (
    <Button
      type="button"
      className="p-button-sm"
      icon={<FontAwesomeIcon icon={faSave} />}
      onClick={props.onSave}
      disabled={props.isDisabled}
    >
      Save changes
    </Button>
  );
}

function StatusMessage(props: Props) {
  return props.isSaving ? (
    <SavingChanges />
  ) : (
    <>
      {props.isLoading && <Loading />}
      {props.unsavedChanges && <UnsavedChanges />}
      {props.saveSuccessful && <Saved />}
    </>
  );
}

function Loading() {
  return (
    <div className="d-flex gap-2 saving">
      <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
      Loading...
    </div>
  );
}
function SavingChanges() {
  return (
    <div className="d-flex gap-2 saving">
      <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
      Saving...
    </div>
  );
}
function Saved() {
  return (
    <div className="d-flex gap-2 saved">
      <FontAwesomeIcon icon={faCheckCircle} />
      All changes saved
    </div>
  );
}
function UnsavedChanges() {
  return (
    <div className="d-flex gap-2 unsaved">
      <FontAwesomeIcon icon={faWarning} />
      Unsaved changes
    </div>
  );
}
