import { MasterTemplate } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaCrownIcon from '../../../components/Icons/FaCrownIcon';

type Props = {
  masterTemplate: MasterTemplate;
  setSelected: () => void;
  isSelected: boolean;
  onEditor: () => void;
  onProperties: () => void;
  onDelete: () => void;
};

export default function MasterTemplateEntry(props: Props) {
  return (
    <EntryItem
      name={props.masterTemplate.name}
      description={props.masterTemplate.description}
      deleteTitle="Delete Master Template"
      deleteMessage={
        <>
          Are you sure you want to delete master template <span className="font-bold">{props.masterTemplate.name}</span>
          ?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaCrownIcon />
            Master Template
          </div>
        </>
      }
      {...props}
    />
  );
}
