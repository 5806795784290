import { Toast, ToastMessage } from 'primereact/toast';
import { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react';

export interface IToastContext {
  show(message: ToastMessage): void;
  clear(): void;
}

export const ToastContext = createContext<IToastContext>({
  show: () => {},
  clear: () => {},
});

export function ToastProvider(props: PropsWithChildren) {
  const toast = useRef<Toast>(null);

  const show = useCallback((message: ToastMessage) => toast.current?.show(message), [toast]);
  const clear = useCallback(() => toast.current?.clear(), [toast]);

  const providerValue: IToastContext = { show, clear };

  return (
    <ToastContext.Provider value={providerValue}>
      <Toast position="top-right" ref={toast} />
      <ToastContext.Provider value={providerValue}>{props.children}</ToastContext.Provider>
    </ToastContext.Provider>
  );
}

export const useToastContext = () => useContext(ToastContext);
