/* eslint-disable no-template-curly-in-string */
import { useRef } from 'react';

import Editor from '@monaco-editor/react';

type Props = {
  value: string;
  setValue: (value: string) => void;
  language?: 'javascript' | 'html';
};
function MonacoEditor(props: Props) {
  const editorRef = useRef<any>(null);

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
    editor.focus();

    //Add custom snippets
    monaco.languages.registerCompletionItemProvider('javascript', {
      provideCompletionItems: () => {
        return {
          suggestions: [
            {
              label: 'Async Block',
              kind: monaco.languages.CompletionItemKind.Snippet,
              documentation: 'Add an async block',
              insertText: ['(async () => {', '\t', '})()'].join('\n'),
            },
            {
              label: 'for: Array',
              kind: monaco.languages.CompletionItemKind.Snippet,
              documentation: 'Iterate over an Array',
              insertText: ['for(let i=0; i < arr.length; i++){', '\tlet elem = arr[i];', '', '}'].join('\n'),
            },
            {
              label: 'simpleText',
              kind: monaco.languages.CompletionItemKind.Text,
              insertText: 'simpleText',
            },
            {
              label: 'testing',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'testing(${1:condition})',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            {
              label: 'ifelse',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: ['if (${1:condition}) {', '\t$0', '} else {', '\t', '}'].join('\n'),
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'If-Else Statement',
            },

            {
              label: 'for-',
              documentation: 'for (...) {...} snippet',
              kind: 1, // monaco.languages.CompletionItemKind.Function,
              insertText: `for (var \${1:i} = \${2:Things}.length; \${1:i}--; ) {\n\t\${0:\${2:Things}[\${1:i}];}\n}`,
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },

            {
              label: 'sdf',
              documentation: 'self-defining function snippet',
              kind: 1, // monaco.languages.CompletionItemKind.Function,
              insertText: `var \${1:function_name} = function(\${2:argument}) {\n\t\${3:// initial code ...}\n\n\t$1 = function($2) {\n\t\t\${4:// main code}\n\t};\n}`,
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            {
              label: 'class',
              kind: 1, // monaco.languages.CompletionItemKind.Function,
              insertText: `var $\{1:class} = function(\${20}) {\n\t$40$0\n};\n\n(function() {\n\t\${60:this.prop = ""}\n}).call(\${1:class}.prototype);\n\nexports.\${1:class} = \${1:class};`,
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'class snippet',
            },
          ],
        };
      },
    });

    //Add custom action
    editor.addAction({
      id: 'insert-text-at-cursor-command',
      label: 'Command Snippet',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10],
      contextMenuGroupId: 'snippets',
      contextMenuOrder: 1.5,
      run: function () {
        editor.focus();
        editor.trigger('keyboard', 'type', { text: 'for' });
      },
    });
  }

  return (
    <>
      <Editor
        className="monaco-editor-wrapper"
        height="calc(100vh - 215px)"
        defaultLanguage={props.language || 'javascript'}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
        }}
        onMount={handleEditorDidMount}
        value={props.value}
        onChange={(value, ev) => value && props.setValue(value)}
      />
    </>
  );
}

export default MonacoEditor;
