import { Image } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaImageIcon from '../../../components/Icons/FaImageIcon';

type Props = {
  image: Image;
  setSelected: () => void;
  isSelected: boolean;
  onProperties: () => void;
  onDelete: () => void;
};

function ImageEntry(props: Props) {
  return (
    <EntryItem
      name={props.image.name}
      description={props.image.description}
      deleteTitle="Delete Image"
      deleteMessage={
        <>
          Are you sure you want to delete image <span className="font-bold">{props.image.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaImageIcon />
            Image
          </div>
        </>
      }
      {...props}
    />
  );
}

export default ImageEntry;
