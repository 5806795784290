import { ActionType } from '../../../../common/actionTypes';
import React, { useCallback } from 'react';
import { ParameterInput, useClearContentMutation } from '../../../../api/generated';
import ContentWithHeader from '../../../ContentWithHeader';
import HeadingWithIcon from '../../../HeadingWithIcon';
import FaPaletteIcon from '../../../Icons/FaPaletteIcon';
import SavePanel from '../SavePanel';
import SendPreviewButton from '../sendPreview/SendPreviewButton';
import { MessageType } from '../../editorTypes';

type Props = {
  action: ActionType;
  messageType: MessageType | undefined;
  contentId: number;
  params: ParameterInput[] | undefined;
  unsavedChanges: boolean;
  saveSuccessful: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isDisabled: boolean;
  minimalHeight: number;
  onSave: () => void;
  onClear: () => void;
  edit: React.ReactElement;
  preview: React.ReactElement;
  isSendPreviewDisabled?: boolean;
};

export default function ContentEditorSection(props: Props) {
  const [clearContent] = useClearContentMutation();

  const onClear = useCallback(async () => {
    if (window.confirm('Do you want to clear the content? The action cannot be undone!')) {
      await clearContent({ id: props.contentId.toString() })
        .unwrap()
        .then((result) => {
          props.onClear();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearContent, props.contentId]);

  return (
    <ContentWithHeader
      header={<HeadingWithIcon label="Content editor" icon={<FaPaletteIcon />} />}
      contentHeader={<HeaderPanel {...props} onClear={onClear} />}
      minimalHeight={props.minimalHeight}
    >
      {props.action === ActionType.Edit && props.edit}
      {props.action === ActionType.View && props.preview}
    </ContentWithHeader>
  );
}

function HeaderPanel(props: {
  action: ActionType;
  messageType?: MessageType;
  contentId: number;
  params?: ParameterInput[];
  onSave: () => void;
  onClear: () => void;
  isLoading: boolean;
  isSaving: boolean;
  isDisabled: boolean;
  unsavedChanges: boolean;
  saveSuccessful: boolean;
  isSendPreviewDisabled?: boolean;
}) {
  return (
    <div className="d-flex gap-2">
      <div className="flex-grow-1"></div>
      {props.action === ActionType.Edit || props.isSendPreviewDisabled ? (
        <SavePanel
          onSave={props.onSave}
          onClear={props.onClear}
          isLoading={props.isLoading}
          isSaving={props.isSaving}
          isDisabled={props.isDisabled}
          unsavedChanges={props.unsavedChanges}
          saveSuccessful={props.saveSuccessful}
        />
      ) : (
        <SendPreviewButton
          isDisabled={props.isDisabled}
          messageType={props.messageType}
          contentId={props.contentId}
          params={props.params}
        />
      )}
    </div>
  );
}
