import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';

import { useMsalInstance } from '../hooks/useMsalInstance';
import { msalConfig } from '../auth/authConfig';

interface AuthContextType {
  lastAttemptedRoute: string;
  setLastAttemptedRoute: (route: string) => void;
}

const AuthContext = createContext<AuthContextType>({
  lastAttemptedRoute: '/',
  setLastAttemptedRoute: () => {},
});

export function AuthProvider(props: PropsWithChildren) {
  const msalInstance = useMsalInstance(msalConfig);

  const [lastAttemptedRoute, setLastAttemptedRoute] = useState<string>('/');

  return (
    <AuthContext.Provider value={{ lastAttemptedRoute, setLastAttemptedRoute }}>
      <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
