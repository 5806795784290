import { Workflow } from '../../../../../api/generated';
import ValueOrPlaceholder from '../../../../../components/ValueOrPlaceholder';

type Props = {
  workflow: Workflow;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.workflow.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.workflow.description} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="type">Type</label>
          <div>
            {props.workflow.email ? <div key="email">Email</div> : null}
            {props.workflow.sms ? <div key="sms">SMS</div> : null}
            {props.workflow.notification ? <div key="notification">Notification</div> : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsTab;
