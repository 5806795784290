import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { classNames } from 'primereact/utils';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  size?: SizeProp;
  label?: string;
};
function Loader(props: Props) {
  return (
    <>
      <FontAwesomeIcon
        size={props.size}
        icon={faSpinner}
        className={classNames('loader blue100 fa-duotone fa-pulse')}
      />
      <span>{props.label}</span>
    </>
  );
}

export default Loader;
