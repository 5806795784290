import { SafeContentParameters, SafeParameter } from '../../editorTypes';
import { BooleanAsString } from '../../../../common/booleanAsString';
import { useAppSelector } from '../../../../app/store';
import { selectParameterStatistics } from '../../../../pages/EntryPointEditor/Slices/EntryPointParametersSlice';
import SelectButton from '../../../SelectButton';
import StatisticsSemaphore from '../../../StatisticsSemaphore';

function DecisionsSection({
  decisionParams,
  decisions,
  onChange,
}: {
  decisionParams: SafeContentParameters[];
  decisions: Map<number, string>;
  onChange: (id: number, value: string) => void;
}) {
  return (
    <>
      {decisionParams.map((p) => {
        if (p && p.parameter) {
          return p.parameter.enumItems ? (
            <EnumDecision
              key={`param-${p.parameterId}`}
              parameterId={p.parameterId}
              param={p.parameter as SafeParameter}
              value={decisions?.get(p.parameterId) as any}
              onChange={(value) => {
                onChange(p.parameterId, value);
              }}
            />
          ) : (
            <BooleanDecision
              key={`param-${p.parameterId}`}
              parameterId={p.parameterId}
              param={p.parameter as SafeParameter}
              value={decisions?.get(p.parameterId) || ''}
              onChange={(value) => {
                onChange(p.parameterId, value);
              }}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

function BooleanDecision({
  parameterId,
  param,
  value,
  onChange,
}: {
  parameterId: number;
  param: SafeParameter;
  value: string;
  onChange: (value: string) => void;
}) {
  const statistics = useAppSelector(selectParameterStatistics(parameterId));
  const trueStatistics = statistics?.values.find((v) => v.value === BooleanAsString.True);
  const falseStatistics = statistics?.values.find((v) => v.value === BooleanAsString.False);

  return (
    <div className="field">
      <span className="title">{param.name}</span>
      <div className="options">
        <SelectButton
          value={BooleanAsString.True}
          onClick={(value: string) => onChange(value)}
          selected={value === BooleanAsString.True}
          unselectable={trueStatistics !== undefined}
        >
          Yes
          {statistics && !trueStatistics && <Statistics maxContentCount={0} missingContentCount={0} />}
          {trueStatistics && <Statistics {...trueStatistics} />}
        </SelectButton>
        <SelectButton
          value={BooleanAsString.False}
          onClick={(value: string) => onChange(value)}
          selected={value === BooleanAsString.False}
          unselectable={falseStatistics !== undefined}
        >
          No
          {statistics && !falseStatistics && <Statistics maxContentCount={0} missingContentCount={0} />}
          {falseStatistics && <Statistics {...falseStatistics} />}
        </SelectButton>
      </div>
    </div>
  );
}

function EnumDecision({
  parameterId,
  param,
  value,
  onChange,
}: {
  parameterId: number;
  param: SafeParameter;
  value: string;
  onChange: (value: string) => void;
}) {
  const statistics = useAppSelector(selectParameterStatistics(parameterId));

  return (
    <div className="field">
      <span className="title">{param.name}</span>
      <div className="options">
        {param?.enumItems?.map((p) => {
          const valueStatistics = statistics?.values.find((v) => v.value === p.key);

          return (
            <SelectButton
              key={p.key}
              value={p.key}
              onClick={(value: string) => onChange(value)}
              selected={p.key === value}
              unselectable={valueStatistics !== undefined}
            >
              {p.value}
              {statistics && !valueStatistics && <Statistics maxContentCount={0} missingContentCount={0} />}
              {valueStatistics && <Statistics {...valueStatistics} />}
            </SelectButton>
          );
        })}
      </div>
    </div>
  );
}

function Statistics({
  maxContentCount,
  missingContentCount,
}: {
  maxContentCount: number;
  missingContentCount: number;
}) {
  return (
    <div className="semaphore">
      <StatisticsSemaphore maxContentCount={maxContentCount} missingContentCount={missingContentCount} />
    </div>
  );
}

export default DecisionsSection;
