// src/auth/authUtils.ts
import { jwtDecode } from 'jwt-decode';
import { ApiRole } from '../api/ApiRole.types';

export const checkAuth = (roles: ApiRole[], requirements?: ApiRole[], token?: string): boolean => {
  // If there's no token, the user is not authenticated
  if (!token) {
    return false;
  }

  if (isTokenExpired(token)) {
    return false;
  }

  if (roles.some((role) => role === ApiRole.Admin)) {
    return true;
  }

  // If specific role requirements are provided, check if the user meets them
  if (requirements && requirements.length > 0) {
    // This example assumes that you decode the token and extract roles from it
    // You might use a library like `jwt-decode` to decode JWT tokens

    // Check if user has at least one of the required roles
    return requirements.some((role) => roles.includes(role));
  }

  return false;
};

export const isTokenExpired = (token: string): boolean => {
  const decoded: { exp: number } = jwtDecode(token);
  const currentTime = Date.now() / 1000; // current time in seconds
  return decoded.exp < currentTime;
};
