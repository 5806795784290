import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { CodeBlock, useCodeBlocksQuery, useDeleteCodeBlockMutation } from '../../api/generated';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import HeaderPanel from '../../components/HeaderPanel';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import SnippetList from './components/SnippetList';
import SnippetSidebar from './components/SnippetSidebar';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import FaCodeIcon from '../../components/Icons/FaCodeIcon';

function Snippets() {
  const { data: codeBlocks, isLoading, refetch, error } = useCodeBlocksQuery({});
  const [selectedCodeBlockId, selectCodeBlockId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteCodeBlock] = useDeleteCodeBlockMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const navigate = useNavigate();
  const toast = useToast();

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const selectCodeBlock = useCallback((codeBlockId: number) => {
    selectCodeBlockId(codeBlockId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addCodeBlock = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editCodeBlock = useCallback((codeBlockId: number) => {
    selectCodeBlockId(codeBlockId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const openEditor = useCallback(
    (codeBlockId: number) => {
      navigate(generatePath(AppRoute.SnippetEditor, { id: codeBlockId.toString() }));
    },
    [navigate],
  );

  const tryToDeleteCodeBlock = useCallback(
    async (codeBlockId: number) => {
      try {
        await deleteCodeBlock({
          id: codeBlockId.toString(),
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Snippet '${
                codeBlocks?.allCodeBlocks?.find((item) => item?.codeBlockId === codeBlockId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectCodeBlockId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteCodeBlock, codeBlocks, refetch, toast],
  );

  const filteredCodeBlocks = useMemo(() => {
    if (searchString) {
      return codeBlocks?.allCodeBlocks?.filter((codeBlock) =>
        codeBlock?.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return codeBlocks?.allCodeBlocks;
  }, [codeBlocks?.allCodeBlocks, searchString]);

  return (
    <Page className="page-snippets" windowTitle={snippetsPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <SnippetSidebar
            codeBlockId={selectedCodeBlockId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectCodeBlockId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={snippetsPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addCodeBlock} />
        </HeaderPanel>
        <div className="snippets-content">
          <SnippetList
            codeBlocks={(filteredCodeBlocks as CodeBlock[]) || []}
            selectedCodeBlockId={selectedCodeBlockId}
            onSelect={selectCodeBlock}
            onEditor={openEditor}
            onProperties={editCodeBlock}
            onDelete={tryToDeleteCodeBlock}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const snippetsPage: IAppPage = {
  title: 'Snippets',
  icon: <FaCodeIcon />,
  path: () => AppRoute.Snippets,
  page: <Snippets />,
  require: [],
};

export default snippetsPage;
