import { Formik } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';

import { CodeBlock, useCodeBlocksQuery, useParametersQuery } from '../../../api/generated';
import { CodeBlockParameter, SnippetEditFormTab } from './SnippetTypes';
import { useValidationSchema } from './useValidationSchema';
import BasicInfoTab from './BasicInfoTab';
import ParametersTab from './ParametersTab';

type Props = {
  codeBlock: CodeBlock;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (codeBlock: any) => void;
  onClose: () => void;
};

function SnippetEditForm({ codeBlock, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element | null {
  const { data: allParameters } = useParametersQuery();
  const [parameters, setParameters] = useState<CodeBlockParameter[]>([]);
  const [activeParameters, setActiveParameters] = useState<CodeBlockParameter[]>([]);

  const { data: codeBlocks } = useCodeBlocksQuery();
  const validationSchema = useValidationSchema(
    codeBlocks?.allCodeBlocks?.filter((item) => item?.name !== codeBlock.name).map((item) => item?.name || '') || [],
  );

  const [tab, setTab] = useState<SnippetEditFormTab>(SnippetEditFormTab.BasicInfo);
  const tabItems: MenuItem[] = [
    {
      label: 'Basic info',
      command: () => setTab(SnippetEditFormTab.BasicInfo),
    },
    {
      label: 'Parameters',
      command: () => setTab(SnippetEditFormTab.Parameters),
    },
  ];

  useEffect(() => {
    if (allParameters) {
      const activeParametersEntries =
        codeBlock?.codeBlockParameters?.map((parameter) => ({
          id: parameter?.parameterId,
          name: parameter?.parameter?.name,
          description: parameter?.parameter?.description,
          type: parameter?.parameter?.sysDataTypeId,
          sysParameterTypeId: parameter?.parameter.sysParameterTypeId,
        })) || [];
      const activeParametersIds = activeParametersEntries.map((parameter) => parameter.id) || [];
      const parametersEntries =
        allParameters.allParameters
          ?.filter((param) => !activeParametersIds.includes(param?.parameterId))
          .map((param) => ({
            id: param?.parameterId,
            name: param?.name,
            description: param?.description,
            type: param?.sysDataTypeId,
            sysParameterTypeId: param?.sysParameterTypeId,
          })) || [];

      setParameters(parametersEntries);
      setActiveParameters(activeParametersEntries as CodeBlockParameter[]);
    }
  }, [allParameters, codeBlock]);

  return (
    <Formik
      initialValues={codeBlock}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        onConfirm({
          ...codeBlock,
          name: formik.name,
          description: formik.description,
          parameters: activeParameters,
        });
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu model={tabItems} />
          </div>
          <div className="side-panel-content side-panel-content-section">
            {tab === SnippetEditFormTab.BasicInfo && <BasicInfoTab />}
            {tab === SnippetEditFormTab.Parameters && (
              <ParametersTab
                parameters={parameters}
                setParameters={setParameters}
                activeParameters={activeParameters}
                setActiveParameters={setActiveParameters}
              />
            )}
          </div>
          <div className="side-panel-controls">
            <Button
              label={isNew ? 'Create' : 'Save'}
              type="submit"
              onClick={submitForm}
              disabled={isLoading}
              className="p-button-sm"
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}

export default SnippetEditForm;
