import FaThumbsUpIcon from '../../../../components/Icons/FaThumbsUpIcon';
import NoItemSelected from '../../../../components/NoItemSelected';

function IssuesTab() {
  return (
    <NoItemSelected
      icon={<FaThumbsUpIcon />}
      title={'No issues detected'}
      note={'Everything is running properly. Keep it up!'}
    />
  );
}

export default IssuesTab;
