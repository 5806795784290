import { IReplacePlugin } from './replacePluginBase';

export class VariablePlugin implements IReplacePlugin {
  htmlFromValue(value: string): string {
    return value.replace(this.getConvertVariableRegex(), (match, parsedType, _parsedSystemName, parsedName) => {
      return this.createVariableHTML(parsedType, parsedName, match);
    });
  }

  valueFromHtml(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const variables = tempDiv.querySelectorAll('.jodit-variable');
    variables.forEach((variable) => {
      const dataValue = variable.getAttribute('data-value');

      if (dataValue) {
        const textNode = document.createTextNode(dataValue);
        variable.parentNode?.replaceChild(textNode, variable);
      } else {
        variable.remove();
      }
    });

    return tempDiv.innerHTML;
  }

  createVariableHTMLFromValue(value: string): string | undefined {
    const matches = this.getConvertVariableRegex().exec(value);

    return matches ? this.createVariableHTML(matches[1], matches[3], matches[0]) : undefined;
  }

  private getConvertVariableRegex() {
    return /(?<!data-value="[^"]*)\[\[([^#]+)#([^#]+)#([^\]\]]+)\]\]/gm;
  }

  private createVariableHTML(parsedType: string, parsedName: string, match: string) {
    return `<span class="jodit-variable ${parsedType}" contenteditable="false" data-value="${match}">
        ${parsedName}
        <span class="remove-variable" onclick="this.parentElement.remove()">
          <span class="fa fa-times">&nbsp;</span>
        </span>
      </span>`;
  }
}
