import { IReplacePlugin } from './replacePluginBase';

export class ContentPlugin implements IReplacePlugin {
  htmlFromValue(value: string): string {
    return value.replace(this.getConvertContentRegex(), (match) => {
      return this.createContentHTML(match);
    });
  }

  valueFromHtml(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const contentElements = tempDiv.querySelectorAll('.jodit-content');
    contentElements.forEach((contentElement) => {
      const contentDiv = document.createElement('div');
      contentDiv.id = 'content';
      contentElement.parentNode?.replaceChild(contentDiv, contentElement);
    });

    return tempDiv.innerHTML;
  }

  private getConvertContentRegex() {
    return /<div\s+id=["']content["']\s*><\/div>/gm;
  }

  private createContentHTML(match: string) {
    return `
      <span class="jodit-content" contenteditable="false">
        Content
      </span>
    `;
  }
}
