import * as Yup from 'yup';
import { addCustomYupMethods, ValidationMessage } from '../../../common/validationUtils';

addCustomYupMethods();

const basicSchema = (entryPointNames: string[], masterTemplateIds: number[], workflowIds: number[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(256)
      //@ts-ignore
      .uniqueIn(entryPointNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
    queryStringName: Yup.string()
      .max(256)
      //@ts-ignore
      .validQueryStringName()
      .required(ValidationMessage.REQUIRED),
    masterTemplateId: Yup.number()
      .oneOf(masterTemplateIds, ValidationMessage.REQUIRED)
      .required(ValidationMessage.REQUIRED),
    workflowId: Yup.number().oneOf(workflowIds, ValidationMessage.REQUIRED).required(ValidationMessage.REQUIRED),
  });

export function useValidationSchema(entryPointNames: string[], masterTemplateIds: number[], workflowIds: number[]) {
  return basicSchema(entryPointNames, masterTemplateIds, workflowIds);
}
