import FaRouteIcon from '../../../components/Icons/FaRouteIcon';
import FaCrownIcon from '../../../components/Icons/FaCrownIcon';
import EntryItem from '../../../components/EntryItem';
import { CombinedEntryPoint } from '../useEntryPointsWithStatistics';
import StatisticsSemaphore from '../../../components/StatisticsSemaphore';

type Props = {
  entryPoint: CombinedEntryPoint;
  setSelected: () => void;
  isSelected: boolean;
  onEditor: () => void;
  onProperties: () => void;
  onDelete: () => void;
};

function EntryPointEntry(props: Props) {
  return (
    <EntryItem
      name={props.entryPoint.name}
      description={props.entryPoint.description}
      deleteTitle="Delete Entry Point"
      deleteMessage={
        <>
          Are you sure you want to delete entry point <span className="font-bold">{props.entryPoint.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaCrownIcon />
            {`Master Template: ${props.entryPoint.masterTemplate.name}`}
          </div>
          <div className="tag">
            <FaRouteIcon />
            {`Workflow: ${props.entryPoint.workflow.name}`}
          </div>
        </>
      }
      semaphore={
        props.entryPoint.statistics && (
          <StatisticsSemaphore
            maxContentCount={props.entryPoint.statistics.maxContentCount}
            missingContentCount={props.entryPoint.statistics.missingContentCount}
          />
        )
      }
      {...props}
    />
  );
}

export default EntryPointEntry;
