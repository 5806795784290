import {
  EntryPoint,
  useAssignEntryPointParametersMutation,
  useEntryPointQuery,
  useUpdateEntryPointMutation,
} from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import EntryPointEditForm from './EntryPointEditForm';
import { EntryPointUpdate } from './EntryPointsTypes';

type Props = {
  entryPointId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function EntryPointEdit({ entryPointId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useEntryPointQuery({ id: entryPointId.toString() });
  const entryPoint = data?.entryPointById;

  const toast = useToast();

  const [updateEntryPoint, { isLoading }] = useUpdateEntryPointMutation();
  const [assignEntryPointParameters] = useAssignEntryPointParametersMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <EntryPointEditForm
        entryPoint={entryPoint as EntryPoint}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (entryPoint: EntryPointUpdate) => {
          await updateEntryPoint({
            id: entryPointId.toString() || '',
            input: {
              masterTemplateId: entryPoint.masterTemplateId,
              workflowId: entryPoint.workflowId,
              name: entryPoint.name,
              description: entryPoint.description,
              queryStringName: entryPoint.queryStringName,
            },
          })
            .unwrap()
            .then(async () => {
              await assignEntryPointParameters({
                id: entryPointId.toString() || '',
                input: {
                  parameters: entryPoint.parameters.map((param) => ({
                    parameterId: param.id as number,
                    isDecision: param.isDecision as boolean,
                  })),
                },
              })
                .then(() => {
                  toast.success(`Entry point '${entryPoint.name}' has been updated successfully.`);
                  onConfirm();
                })
                .catch(toast.error);
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default EntryPointEdit;
