import { InputSwitch } from 'primereact/inputswitch';

type props = {
  isAdvanced: boolean;
  onAdvancedChange: (value: boolean) => void;
};

export function ShowAdvanced(props: props) {
  return (
    <div className="flex gap-2 align-items-center">
      <InputSwitch
        id="useAdvanced"
        name="useAdvanced"
        checked={props.isAdvanced}
        onChange={(e) => props.onAdvancedChange(e.value)}
      />
      <span>Advanced</span>
    </div>
  );
}
