import React from 'react';
import JoditEditorReact from 'jodit-react';
import { Props, getConfig } from './JoditEditorUtils';

const JoditEditor = React.memo(
  (props: Props) => {
    const config = getConfig(props);

    return (
      <JoditEditorReact
        value={props.editorConnector?.getEditorValue() || ''}
        config={config}
        onChange={(newContent) => props.editorConnector?.setEditorValue(newContent)}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.editorConnector?.dataRevisionIndex === nextProps.editorConnector?.dataRevisionIndex &&
    prevProps.disabled === nextProps.disabled,
);

export default JoditEditor;
