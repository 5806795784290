import { Formik } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';

import {
  EntryPoint,
  MasterTemplate,
  useEntryPointsQuery,
  useMasterTemplatesQuery,
  useParametersQuery,
  useWorkflowsQuery,
  Workflow,
} from '../../../api/generated';
import { EntryPointEditFormTab, EntryPointParameter } from './EntryPointsTypes';
import BasicInfoTab from './BasicInfoTab';
import ParametersTab from './ParametersTab';
import DecisionParametersTab from './DecisionParametersTab';
import { useValidationSchema } from './useValidationSchema';

type Props = {
  entryPoint: EntryPoint;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (entryPoint: any) => void;
  onClose: () => void;
};

function EntryPointEditForm({ entryPoint, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element | null {
  const { data: masterTemplates } = useMasterTemplatesQuery();
  const { data: workflows } = useWorkflowsQuery();
  const [masterTemplate, setMasterTemplate] = useState<number | undefined>(entryPoint.masterTemplateId);
  const [workflow, setWorkflow] = useState<number | undefined>(entryPoint.workflowId);
  const { data: allParameters } = useParametersQuery();
  const [parameters, setParameters] = useState<EntryPointParameter[]>([]);
  const [activeParameters, setActiveParameters] = useState<EntryPointParameter[]>([]);

  const { data: entryPoints } = useEntryPointsQuery();
  const validationSchema = useValidationSchema(
    entryPoints?.allEntryPoints?.filter((item) => item?.name !== entryPoint.name).map((item) => item?.name || '') || [],
    masterTemplates?.allMasterTemplates?.map((template) => template?.masterTemplateId || 0) || [],
    workflows?.allWorkflows?.map((workflow) => workflow?.workflowId || 0) || [],
  );

  const [tab, setTab] = useState<EntryPointEditFormTab>(EntryPointEditFormTab.BasicInfo);
  const tabItems: MenuItem[] = [
    {
      label: 'Basic info',
      command: () => setTab(EntryPointEditFormTab.BasicInfo),
    },
    {
      label: 'Parameters',
      command: () => setTab(EntryPointEditFormTab.Parameters),
    },
    {
      label: 'Decisions',
      command: () => setTab(EntryPointEditFormTab.DecisionParameters),
    },
  ];

  useEffect(() => {
    if (allParameters) {
      const activeParametersEntries =
        entryPoint?.entryPointParameters?.map((parameter) => ({
          id: parameter?.parameterId,
          name: parameter?.parameter?.name,
          description: parameter?.parameter?.description,
          isDecision: parameter?.isDecision,
          type: parameter?.parameter?.sysDataTypeId,
          sysParameterTypeId: parameter?.parameter.sysParameterTypeId,
        })) || [];
      const activeParametersIds = activeParametersEntries.map((parameter) => parameter.id) || [];
      const parametersEntries =
        allParameters.allParameters
          ?.filter((param) => !activeParametersIds.includes(param?.parameterId))
          .map((param) => ({
            id: param?.parameterId,
            name: param?.name,
            description: param?.description,
            isDecision: false,
            type: param?.sysDataTypeId,
            sysParameterTypeId: param?.sysParameterTypeId,
          })) || [];

      setParameters(parametersEntries);
      setActiveParameters(activeParametersEntries as EntryPointParameter[]);
    }
  }, [allParameters, entryPoint]);

  return (
    <Formik
      initialValues={entryPoint}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        onConfirm({
          ...entryPoint,
          name: formik.name,
          description: formik.description,
          masterTemplateId: formik.masterTemplateId,
          workflowId: formik.workflowId,
          queryStringName: formik.queryStringName,
          parameters: activeParameters,
        });
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu model={tabItems} />
          </div>
          <div className="side-panel-content side-panel-content-section">
            {tab === EntryPointEditFormTab.BasicInfo && (
              <BasicInfoTab
                masterTemplate={masterTemplate}
                setMasterTemplate={setMasterTemplate}
                workflow={workflow}
                setWorkflow={setWorkflow}
                allWorkflows={workflows?.allWorkflows as Workflow[]}
                allMasterTemplates={masterTemplates?.allMasterTemplates as MasterTemplate[]}
              />
            )}
            {tab === EntryPointEditFormTab.Parameters && (
              <ParametersTab
                parameters={parameters}
                setParameters={setParameters}
                activeParameters={activeParameters}
                setActiveParameters={setActiveParameters}
              />
            )}
            {tab === EntryPointEditFormTab.DecisionParameters && (
              <DecisionParametersTab
                parameters={parameters}
                setParameters={setParameters}
                activeParameters={activeParameters}
                setActiveParameters={setActiveParameters}
              />
            )}
          </div>
          <div className="side-panel-controls">
            <Button
              label={isNew ? 'Create' : 'Save'}
              type="submit"
              disabled={isLoading}
              className="p-button-sm"
              onClick={submitForm}
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}

export default EntryPointEditForm;
