import { BrowserAuthOptions, Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

const msalAuthConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '', // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`, // https://login.microsoftonline.com/Enter_the_Tenant_Info_Here, Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  } as BrowserAuthOptions,
};
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: msalAuthConfig.auth,
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
  prompt: 'select_account',
  redirectStartPage: '/',
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
  scopes: ['User.Read', `api://${process.env.REACT_APP_AZURE_CLIENT_ID}/access_as_user`],
};
