import { Button } from 'primereact/button';
import { useCallback } from 'react';

import FaExclamationTriangle from '../../components/Icons/FaExclamationTriangle';
import Page from '../../components/Page';
import useAppNavigate from '../../hooks/useAppNavigate';
import { AppRoute } from '../../route/AppRoute';

function NotAllowed() {
  const navigate = useAppNavigate();
  const gotoHome = useCallback(() => {
    navigate(AppRoute.Home);
  }, [navigate]);

  return (
    <Page className="page-error">
      <FaExclamationTriangle />
      <h1>Access to this page is not available!</h1>
      <div className="mb-1">This page is only accessible with granted role which is not available to you.</div>
      <div className="mb-5">Please proceed by clicking on button bellow.</div>
      <Button type="button" onClick={gotoHome}>
        Goto home
      </Button>
    </Page>
  );
}

export default NotAllowed;
