function FaPaletteIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 8C5 8.5625 4.53125 9 4 9C3.4375 9 3 8.5625 3 8C3 7.46875 3.4375 7 4 7C4.53125 7 5 7.46875 5 8ZM4 5C4 4.46875 4.4375 4 5 4C5.53125 4 6 4.46875 6 5C6 5.5625 5.53125 6 5 6C4.4375 6 4 5.5625 4 5ZM9 4C9 4.5625 8.53125 5 8 5C7.4375 5 7 4.5625 7 4C7 3.46875 7.4375 3 8 3C8.53125 3 9 3.46875 9 4ZM10 5C10 4.46875 10.4375 4 11 4C11.5312 4 12 4.46875 12 5C12 5.5625 11.5312 6 11 6C10.4375 6 10 5.5625 10 5ZM13.7812 10H10.75C9.90625 10 9.25 10.6875 9.25 11.5C9.25 11.625 9.25 11.7188 9.28125 11.8125C9.34375 12.1562 9.46875 12.4375 9.59375 12.75C9.78125 13.1875 10 13.625 10 14.0625C10 15.0625 9.3125 15.9688 8.3125 16C8.21875 16 8.09375 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 8.03125 15.9688 8.0625 15.9688 8.09375C15.9688 9.25 14.9375 10 13.7812 10ZM14.4688 8.09375C14.4688 8.0625 14.5 8.03125 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C8.0625 14.5 8.15625 14.5 8.25 14.5C8.28125 14.5 8.28125 14.5 8.3125 14.5C8.3125 14.5 8.34375 14.4688 8.375 14.4375C8.4375 14.375 8.5 14.25 8.5 14.0625C8.5 14.0312 8.46875 13.9062 8.3125 13.5312C8.28125 13.5 8.28125 13.4688 8.25 13.4062C8.125 13.0938 7.90625 12.625 7.8125 12.125C7.75 11.9375 7.75 11.7188 7.75 11.5C7.75 9.84375 9.0625 8.5 10.75 8.5H13.7812C14.0312 8.5 14.2188 8.4375 14.3438 8.34375C14.4688 8.25 14.4688 8.15625 14.4688 8.09375Z"
        fill="#686E75"
      />
    </svg>
  );
}

export default FaPaletteIcon;
