import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector } from 'react-redux';
import { api } from '../api/graphApi';
import entryPointParametersStatisticsSlice from '../pages/EntryPointEditor/Slices/EntryPointParametersSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    entryPointParametersStatistics: entryPointParametersStatisticsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch = () => useReduxDispatch<AppDispatch>();
