import { useWorkflowReferencesQuery } from '../../../../../api/generated';
import { DependencyDetail } from '../../../../../components/DependencyDetail';
import { NoReferences } from '../../../../../components/NoReferences';

type Props = {
  id: string;
};

function ReferencesTab(props: Props) {
  const { data: references } = useWorkflowReferencesQuery({ id: props.id });

  if (!references?.workflowReferences?.any) {
    return <NoReferences />;
  }

  return (
    <div className="entry-detail">
      <div className="field">
        <label htmlFor="entryPoints">Entry points</label>
        <div id="entryPoints">
          <DependencyDetail dependency={references.workflowReferences.entryPoints || []} type="entryPoints" />
        </div>
      </div>
    </div>
  );
}

export default ReferencesTab;
