function FaBracketsCurlyIcon() {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25 0H3.75C2.78125 0 2 0.8125 2 1.75V4.1875C2 4.53125 1.84375 4.84375 1.625 5.0625L0.21875 6.46875C0.0625 6.625 0 6.8125 0 7C0 7.21875 0.0625 7.40625 0.21875 7.53125L1.625 8.96875C1.84375 9.1875 2 9.5 2 9.84375V12.25C2 13.2188 2.78125 14 3.75 14H6.25C6.65625 14 7 13.6875 7 13.25C7 12.8438 6.65625 12.5 6.25 12.5H3.75C3.59375 12.5 3.5 12.4062 3.5 12.25V9.84375C3.5 9.125 3.1875 8.40625 2.6875 7.90625L1.78125 7L2.6875 6.125C3.1875 5.625 3.5 4.90625 3.5 4.1875V1.75C3.5 1.625 3.59375 1.5 3.75 1.5H6.25C6.65625 1.5 7 1.1875 7 0.75C7 0.34375 6.65625 0 6.25 0ZM17.7812 6.46875L16.3438 5.0625C16.125 4.84375 16 4.53125 16 4.1875V1.75C16 0.8125 15.1875 0 14.25 0H11.75C11.3125 0 11 0.34375 11 0.75C11 1.125 11.3125 1.5 11.7188 1.5H14.25C14.375 1.5 14.5 1.625 14.5 1.75V4.1875C14.5 4.90625 14.7812 5.625 15.2812 6.125L16.1562 7L15.25 7.90625C14.7812 8.40625 14.5 9.125 14.5 9.84375V12.25C14.5 12.4062 14.375 12.5 14.25 12.5H11.75C11.3125 12.5 11 12.8438 11 13.25C11 13.625 11.3125 14 11.7188 14H14.25C15.1875 14 16 13.2188 16 12.25V9.84375C16 9.5 16.125 9.1875 16.3438 8.96875L17.7812 7.53125C17.9062 7.40625 18 7.21875 18 7C18 6.8125 17.9062 6.625 17.7812 6.46875Z"
        fill="#686E75"
      />
    </svg>
  );
}

export default FaBracketsCurlyIcon;
