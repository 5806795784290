import { ParameterEnum, SysDataTypeId } from '../../api/generated';
import { BooleanAsString } from '../../common/booleanAsString';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { InputText } from 'primereact/inputtext';

type Props = {
  type: SysDataTypeId;
  value: string;
  setValue: (value: string) => void;
  enumItems: ParameterEnum[];
  isSimple: boolean;
  ref?: React.RefObject<HTMLInputElement>;
};

export default function SampleData(props: Props) {
  switch (props.type) {
    case SysDataTypeId.Bool:
      if (!props.value) {
        props.setValue(BooleanAsString.False);
        props.value = BooleanAsString.False;
      }
      return (
        <div className="boolean">
          <InputSwitch
            id="sampleValue"
            name="sampleValue"
            checked={props.value === BooleanAsString.True}
            onChange={(event) => props.setValue(event.value ? BooleanAsString.True : BooleanAsString.False)}
          />
          {props.isSimple || <span>{props.value}</span>}
        </div>
      );
    case SysDataTypeId.Enum:
      return (
        <Dropdown
          id="sampleValue"
          name="sampleValue"
          value={props.value}
          defaultValue={props.value}
          placeholder="Select value"
          className={classNames('w-full', { 'p-invalid': false })}
          onChange={(event) => props.setValue(event.target.value)}
          options={
            props.enumItems[0]?.key
              ? props.enumItems.map((item) => {
                  return { label: `${item.key} (${item.value})`, value: item.key };
                })
              : []
          }
        />
      );
    case SysDataTypeId.DateTime:
      return (
        <div>
          <Calendar
            id="sampleValue"
            name="sampleValue"
            value={new Date(props.value.split('.').reverse().join('-'))} // Convert "DD.MM.YYYY" to "YYYY-MM-DD" for new Date()
            dateFormat="dd.mm.yy"
            placeholder="Select value"
            className=""
            onChange={(e) => {
              let newDate: Date | null = null;

              if (Array.isArray(e.value) && e.value.length > 0) {
                newDate = e.value[0];
              } else if (e.value instanceof Date) {
                newDate = e.value;
              }

              if (newDate instanceof Date) {
                const formattedDate = `${newDate.getDate()}.${newDate.getMonth() + 1}.${newDate.getFullYear()}`;
                props.setValue(formattedDate);
              }
            }}
          />
        </div>
      );
    default:
      return props.isSimple ? (
        <InputText
          type="text"
          value={props.value}
          className="w-full"
          onClick={(e) => e.stopPropagation()}
          onChange={(event) => props.setValue(event.target.value)}
        />
      ) : (
        <InputTextarea
          id="sampleValue"
          name="sampleValue"
          value={props.value}
          className="w-full"
          onChange={(event) => props.setValue(event.target.value)}
          rows={3}
          autoResize
        />
      );
  }
}
