// src/components/DynamicRoutes.tsx
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { AppRoute, appRoutes } from './AppRoute';
import Home from '../pages/Home';
import Logout from '../pages/Logout';
import NotFound from '../pages/NotFound';

const DynamicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={AppRoute.Home} element={<Home />} />
      <Route path={AppRoute.Logout} element={<Logout />} />

      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />

      {appRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path()}
          element={<ProtectedRoute require={route.require}>{route.page}</ProtectedRoute>}
        />
      ))}
    </Routes>
  );
};

export default DynamicRoutes;
