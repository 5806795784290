import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

type HeaderPanelProps = {
  titleText: string;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
  showSearch?: Boolean;
  searchPlaceHolder?: string;
  onSearch?: (search: string) => void;
};

function HeaderPanel(props: HeaderPanelProps) {
  const [search, setSearch] = useState('');
  const left = (
    <>
      <span className="title">{props.titleText}</span>
      {props.children}
    </>
  );

  const searchInput = (
    <span className="p-inputgroup">
      <span className="p-inputgroup-addon">
        <FontAwesomeIcon icon={faSearch} className={classNames('icon', { 'icon-active': search })} />
      </span>
      <InputText
        className="p-inputtext-sm block"
        placeholder={props.searchPlaceHolder || 'Type to search...'}
        value={search}
        id="headerpanelsearch"
        name="search"
        onChange={(e) => {
          if (props.onSearch) {
            setSearch(e.target.value);
            props.onSearch(e.target.value);
          }
        }}
      />
    </span>
  );

  const right = (
    <>
      {props.showSearch && searchInput}
      {props.rightContent}
    </>
  );

  return (
    <div className="header-panel">
      <Toolbar left={left} right={right} />
    </div>
  );
}

export default HeaderPanel;
