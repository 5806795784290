import React, { useState, ChangeEvent } from 'react';

interface DecimalInputProps {
  value?: string;
  onChange?: (value: number) => void;
  placeholder?: string;
}

const DecimalInput: React.FC<DecimalInputProps> = ({ value, onChange, placeholder }) => {
  const [localValue, setLocalValue] = useState(value || '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Allow only numbers, a single comma and up to two decimal places
    if (/^\d*(,\d{0,2})?$/.test(inputValue)) {
      setLocalValue(inputValue);

      if (onChange) {
        const floatValue = parseFloat(inputValue.replace(',', '.'));
        if (!isNaN(floatValue)) {
          onChange(floatValue);
        }
      }
    }
  };

  return (
    <input type="text" value={localValue} onChange={handleChange} placeholder={placeholder} className="p-inputtext" />
  );
};

export default DecimalInput;
