import {
  SubTemplate,
  useAssignSubTemplateParametersMutation,
  useCreateSubTemplateMutation,
  useUpdateSubTemplateConditionalUseMutation,
} from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import SubTemplateEditForm from './SubTemplateEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function SubTemplateAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createSubTemplate, { isLoading }] = useCreateSubTemplateMutation();
  const [assignSubTemplateParameters] = useAssignSubTemplateParametersMutation();
  const [updateSubTemplateConditionalUse] = useUpdateSubTemplateConditionalUseMutation();

  const toast = useToast();

  const defaultSubTemplate: Partial<SubTemplate> = {
    subTemplateId: 0,
    name: '',
    description: '',
    conditionalUse: {
      conditions: [],
    },
  };

  return (
    <SidePanel title="Create Sub-template" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <SubTemplateEditForm
        isLoading={isLoading}
        subTemplate={defaultSubTemplate as SubTemplate}
        isNew={true}
        onConfirm={async (subTemplate: any) => {
          await createSubTemplate({
            input: {
              name: subTemplate.name,
              description: subTemplate.description,
            },
          })
            .unwrap()
            .then(async (response) => {
              await assignSubTemplateParameters({
                id: response.createSubTemplate?.subTemplateId.toString() || '',
                input: {
                  parameters: subTemplate.parameters.map((param: any) => ({
                    parameterId: param.id as number,
                    isDecision: param.isDecision as boolean,
                  })),
                },
              })
                .then(async () => {
                  await updateSubTemplateConditionalUse({
                    id: response.createSubTemplate?.subTemplateId.toString() || '',
                    input: {
                      conditions: subTemplate.conditions,
                    },
                  }).catch(toast.error);
                })
                .catch(toast.error);
            })
            .then(() => {
              toast.success(`Sub-template '${subTemplate.name}' has been created successfully.`);
              onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default SubTemplateAdd;
