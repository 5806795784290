import { useEffect, useMemo } from 'react';
import { Image, useImagesQuery, EntryPointParameter, Link } from '../../../api/generated';
import { TreeItemData } from '../../../components/Editor/components/tree-items/TreeItemData';
import { AppRoute } from '../../../route/AppRoute';
import { AvailableObjectType } from '../../../components/Editor/components/tree-items/AvailableObjectType';
import { getDataHolderParams } from '../../../components/Editor/editorUtils';
import EditorTreeItems from '../../../components/Editor/components/tree-items/EditorTreeItems';

type Props = {
  link: Link;
  usedObjects: string[];
  onObjectSelect: (object: TreeItemData) => void;
  onLoadingComplete: (isLoading: boolean) => void;
  enabledTypes?: AvailableObjectType[];
};

export default function AvailableLinkObjects(props: Props) {
  const { data: images, isLoading: isLoadingImages } = useImagesQuery({});

  const params = useMemo(() => {
    const params = props.link.linkParameters
      .filter((linkParam) => !!linkParam)
      .map((linkParam) => ({
        parameterId: linkParam!.parameterId,
        parameter: linkParam!.parameter,
      })) as EntryPointParameter[];
    return getDataHolderParams(params);
  }, [props.link.linkParameters]);

  useEffect(() => {
    const isLoading = isLoadingImages;
    props.onLoadingComplete(isLoading);
  }, [isLoadingImages, props]);

  return (
    <EditorTreeItems
      parameters={params as EntryPointParameter[]}
      codeBlocks={[]}
      subTemplates={[]}
      images={images?.allImages as Image[]}
      links={[]} // not used in this page
      usedObjects={props.usedObjects}
      onObjectSelect={props.onObjectSelect}
      path={AppRoute.LinkEditor}
      enabledTypes={props.enabledTypes}
    />
  );
}
