import { ApiRole } from '../../api/ApiRole.types';
import {
  CodeBlock,
  CodeBlockQuery,
  EntryPoint,
  EntryPointDetailQuery,
  ParametersQuery,
  Parameter,
  SubTemplate,
  SubTemplateDetailQuery,
} from '../../api/generated';
import { AppRoute } from '../../route/AppRoute';

export type EntryPointEditorStore = {
  decisions: Map<number, string> | null;
  messageType: string;
  defaultLanguage: string;
  langsUsingDefault: string[];
  specificLangs: string[];
};

export type SafeEntryPointParameters = NonNullable<
  NonNullable<EntryPointDetailQuery['entryPointById']>['entryPointParameters']
>[0];

export type SafeCodeBlockParameters = NonNullable<
  NonNullable<CodeBlockQuery['codeBlockById']>['codeBlockParameters']
>[0];

export type SafeSubTemplateParameters = NonNullable<
  NonNullable<SubTemplateDetailQuery['subTemplateById']>['subTemplateParameters']
>[0];

export type SafeAllParameters = {
  parameterId: number;
  parameter: NonNullable<ParametersQuery['allParameters']>[0];
};

export type SafeContentParameters =
  | SafeEntryPointParameters
  | SafeCodeBlockParameters
  | SafeSubTemplateParameters
  | SafeAllParameters;

export type SafeParameter = NonNullable<Parameter>;

export type ContentEditorProps = {
  contentId: number;
  currentTemplateId: number | undefined;
  path: AppRoute.EntryPointEditor | AppRoute.SnippetEditor | AppRoute.SubTemplateEditor;
  content: EntryPoint | CodeBlock | SubTemplate;
  contentParameters: SafeContentParameters[];
  className?: string;
  isLoading: boolean;
  require?: ApiRole[];
};

export enum MessageType {
  Notification = 'ntf',
  Sms = 'sms',
  Email = 'email',
}
