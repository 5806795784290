import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useWindowTitle } from '../hooks/useWindowTitle';
import TopBar from './TopBar';
import { RequestError } from '../api/graphqlBaseQueryTypes';
import { SerializedError } from '@reduxjs/toolkit';
import FetchError from './FetchError';
import { useAppDispatch } from '../app/store';
import { clearParameterStatistics } from '../pages/EntryPointEditor/Slices/EntryPointParametersSlice';

type Props = {
  windowTitle?: string;
  className?: string;
  children?: React.ReactNode;
  topBar?: React.ReactNode;
  error?: RequestError | SerializedError | undefined;
};

function Page({ windowTitle, className, children, topBar, error }: Props) {
  const mainRef = useRef<any>();
  const dispatch = useAppDispatch();

  const [showScrollShadow, setShowScrollShadow] = useState(false);

  const onScroll = useCallback(() => {
    if (mainRef.current) {
      const { scrollTop } = mainRef.current;
      if (scrollTop > 1) {
        setShowScrollShadow(true);
      } else {
        setShowScrollShadow(false);
      }
    }
  }, []);

  useWindowTitle(windowTitle);

  // TODO refactor - make general for all pages that use redux `slice`
  useEffect(() => {
    return () => {
      dispatch(clearParameterStatistics());
    };
  }, [dispatch]);

  return (
    <div className={classNames('page', className)}>
      {topBar || <TopBar />}
      {error ? (
        <FetchError />
      ) : (
        <main ref={mainRef} onScroll={onScroll} className={classNames({ 'scrollbar-active': showScrollShadow })}>
          {children}
        </main>
      )}
    </div>
  );
}

export default Page;
