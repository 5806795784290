import { MasterTemplate, useMasterTemplateQuery, useUpdateMasterTemplateMutation } from '../../../../api/generated';
import useToast from '../../../../hooks/useToast';
import SidePanel from '../../../../components/SidePanel';
import MasterTemplateEditForm from './MasterTemplateEditForm';
import { MasterTemplateUpdate } from './MasterTemplateTypes';

type Props = {
  masterTemplateId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

export default function MasterTemplateEdit(props: Props) {
  const { data } = useMasterTemplateQuery({ id: props.masterTemplateId.toString() });
  const masterTemplate = data?.masterTemplateById;

  const toast = useToast();

  const [updateMasterTemplate, { isLoading }] = useUpdateMasterTemplateMutation();

  return (
    <SidePanel title="Properties" onClose={props.onSidebarClose} visible={props.sidebarVisible} size="wide">
      <MasterTemplateEditForm
        masterTemplate={masterTemplate as MasterTemplate}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (masterTemplate: MasterTemplateUpdate) => {
          await updateMasterTemplate({
            id: props.masterTemplateId.toString() || '',
            input: {
              name: masterTemplate.name,
              description: masterTemplate.description,
              htmlBody: masterTemplate.htmlBody,
              mjmlBody: masterTemplate.mjmlBody,
            },
          })
            .unwrap()
            .then(async () => {
              toast.success(`Master Template '${masterTemplate.name}' has been updated successfully.`);
              props.onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={props.onSidebarClose}
      />
    </SidePanel>
  );
}
