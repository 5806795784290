import React from 'react';
import MobileIcon from '../../../../Icons/MobileIcon';

type Props = {
  body?: string;
};

export default function NotificationPreview(props: Props) {
  return (
    <>
      <MobileIcon />
      <div className="notification-preview" dangerouslySetInnerHTML={{ __html: props.body || '' }} />
    </>
  );
}
