import Loader from '../Loader';
import NoItemSelected from '../NoItemSelected';

type Props = {
  label: string;
  isFullscreen?: boolean;
};

const Overlay = (props: Props) => {
  return (
    <div className={props.isFullscreen ? 'overlay-full' : 'overlay'}>
      <NoItemSelected icon={<Loader size="3x" />} title={props.label} note={'Please wait...'} />
    </div>
  );
};

export default Overlay;
