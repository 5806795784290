import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { useState } from 'react';

import FaCodeIcon from '../../../components/Icons/FaCodeIcon';
import { CodeBlock, useCodeBlockQuery } from '../../../api/generated';
import { SnippetViewTab } from './SnippetTypes';
import Overlay from '../../../components/Overlay';
import ReferencesTab from './ReferencesTab';
import DetailsTab from './DetailsTab';

type Props = {
  id: number;
};

function SnippetView(props: Props) {
  const { data, isLoading } = useCodeBlockQuery({ id: props.id.toString() });
  const codeBlock = data?.codeBlockById;

  const [activeIndex, setActiveIndex] = useState<number>(SnippetViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (codeBlock) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaCodeIcon />
          </div>
          <div className="name">{codeBlock.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === SnippetViewTab.Details && <DetailsTab codeBlock={codeBlock as CodeBlock} />}
          {activeIndex === SnippetViewTab.References && <ReferencesTab id={props.id.toString()} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading snippet" /> : <>Snippet not found!</>;
  }
}

export default SnippetView;
