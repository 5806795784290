import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';

import Page from '../../components/Page';
import MainLayout from '../../components/MainLayout';
import HeaderPanel from '../../components/HeaderPanel';
import EntryCard from '../../components/EntryCard';
import { convertCountryCode } from '../../common/countryUtils';
import { ApiRole } from '../../api/ApiRole.types';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import FaCogIcon from '../../components/Icons/FaCogIcon';

function Configuration() {
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const langs = ['en'];

  const resetDefaultLanguage = useCallback(() => {
    setDefaultLanguage('');
  }, []);

  return (
    <Page className="page-configuration" windowTitle={configurationPage.title}>
      <MainLayout>
        <HeaderPanel titleText={configurationPage.title} showSearch></HeaderPanel>
        <div className="configuration-content">
          <EntryCard>
            <div className="detail">
              <div className="name">Default language</div>
              <div className="description">
                The selected default language is assigned to the objects (entry point, snippets,..) if content in a
                specific language is not defined for the given object.
              </div>
              <div className="description">Be careful, changing the settings can cause disaster!</div>
              <div className="actions">
                <Dropdown
                  id="defaultLanguage"
                  name="defaultLanguage"
                  value={defaultLanguage}
                  placeholder="Select value"
                  itemTemplate={itemTemplate}
                  valueTemplate={itemTemplate}
                  className={classNames('default-language', { 'p-invalid': false })}
                  onChange={(event) => setDefaultLanguage(event.target.value)}
                  options={langs?.map((lang) => {
                    return { value: lang, label: lang };
                  })}
                />
                <Button type="button" onClick={resetDefaultLanguage}>
                  Reset
                </Button>
              </div>
            </div>
          </EntryCard>

          <EntryCard>
            <div className="detail">
              <div className="name">Environment</div>
              <div className="text-bold mt-2">Mode</div>
              <div className="description">{process.env.REACT_APP_MODE}</div>
              <div className="text-bold mt-2">Backend</div>
              <div className="description">{process.env.REACT_APP_GRAPHQL_API}</div>
            </div>
          </EntryCard>
        </div>
      </MainLayout>
    </Page>
  );
}

const itemTemplate = (item: any) => {
  return item ? (
    <div className="flex gap-2 align-items-center">
      <span className={`fi fi-${convertCountryCode(item.value)}`} />
      <span>{item.label}</span>
    </div>
  ) : null;
};

const configurationPage: IAppPage = {
  title: 'Configuration',
  icon: <FaCogIcon />,
  path: () => AppRoute.Configuration,
  page: <Configuration />,
  require: [ApiRole.Admin],
};

export default configurationPage;
