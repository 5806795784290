import { ConfirmDialog } from 'primereact/confirmdialog';

type DeleteDialogProps = {
  message: React.ReactNode;
  header: string;
  accept?: () => void;
  onHide?: () => void;
  visible: boolean;
};

function DeleteDialog(props: DeleteDialogProps) {
  const accept = () => {
    props.accept && props.accept();
    props.onHide && props.onHide();
  };

  const reject = () => {
    props.onHide && props.onHide();
  };

  return (
    <ConfirmDialog
      message={props.message}
      header={props.header}
      accept={accept}
      reject={reject}
      onHide={props.onHide}
      visible={props.visible}
      acceptLabel="Delete"
      rejectLabel="Cancel"
    />
  );
}

export default DeleteDialog;
