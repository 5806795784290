import { Button } from 'primereact/button';
import { convertCountryCode } from '../../../../common/countryUtils';

import HeadingWithIcon from '../../../HeadingWithIcon';
import FaCogIcon from '../../../Icons/FaCogIcon';
import FaLanguageIcon from '../../../Icons/FaLanguageIcon';

type Props = {
  defaultLanguage: string;
  setLanguageSettingsVisible: () => void;
};
function LanguagesSectionHeader(props: Props) {
  return (
    <div className="languages-header">
      <HeadingWithIcon label="Languages" icon={<FaLanguageIcon />} />
      <div className="options">
        {props.defaultLanguage && (
          <Button disabled className="default-language">
            Default:
            <span className={`fi fi-${convertCountryCode(props.defaultLanguage)}`} />
            {props.defaultLanguage.toUpperCase()}
          </Button>
        )}
        <Button className="settings" onClick={props.setLanguageSettingsVisible}>
          <FaCogIcon />
        </Button>
      </div>
    </div>
  );
}

export default LanguagesSectionHeader;
