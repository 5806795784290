import PreviewContent from '../../Editor/components/preview/PreviewContent';
import NotificationWithSubjectPreview from '../../Editor/components/preview/preview-types/NotificationWithSubjectPreview';
import SmsPreview from '../../Editor/components/preview/preview-types/SmsPreview';
import EmailWithSubjectPreview from '../../Editor/components/preview/preview-types/EmailWithSubjectPreview';
import { Divider } from 'primereact/divider';

type Props = {
  messageType: string;
  savedSubjectPreview: string | undefined;
  savedBodyPreview: string | undefined;
  draftSubjectPreview: string;
  draftBodyPreview: string;
  isBodyEmpty: boolean | undefined;
  unsavedChanges: boolean;
  isLoadingSavedPreview: boolean;
  isLoadingDraftPreview: boolean;
  savedPreviewError: string | undefined;
  draftPreviewError: string | undefined;
  hasSavedPreviewError: boolean;
  hasDraftPreviewError: boolean;
};

export default function ContentEditorPreview(props: Props) {
  return (
    <>
      <div className="split-panel">
        {props.unsavedChanges && (
          <>
            <PreviewContent
              title={'Draft'}
              messageType={props.messageType}
              isBodyEmpty={!props.draftBodyPreview}
              isLoading={props.isLoadingDraftPreview}
              hasError={props.hasDraftPreviewError}
              error={props.draftPreviewError}
              notificationPreview={
                <NotificationWithSubjectPreview subject={props.draftSubjectPreview} body={props.draftBodyPreview} />
              }
              smsPreview={<SmsPreview body={props.draftBodyPreview} />}
              emailPreview={
                <EmailWithSubjectPreview subject={props.draftSubjectPreview} body={props.draftBodyPreview} />
              }
            />
            <Divider layout="vertical" />
          </>
        )}
        <PreviewContent
          title={props.unsavedChanges ? 'Saved version' : undefined}
          messageType={props.messageType}
          isBodyEmpty={props.isBodyEmpty}
          isLoading={props.isLoadingSavedPreview}
          hasError={props.hasSavedPreviewError}
          notificationPreview={
            <NotificationWithSubjectPreview subject={props.savedSubjectPreview} body={props.savedBodyPreview} />
          }
          smsPreview={<SmsPreview body={props.savedBodyPreview} />}
          emailPreview={<EmailWithSubjectPreview subject={props.savedSubjectPreview} body={props.savedBodyPreview} />}
        />
      </div>
    </>
  );
}
