import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import SendPreviewDialog from './SendPreviewDialog';
import { useState } from 'react';
import { MessageType } from '../../editorTypes';
import { ParameterInput } from '../../../../api/generated';

type Props = {
  messageType: MessageType | undefined;
  contentId: number | undefined;
  params: ParameterInput[] | undefined;
  isDisabled?: boolean;
};

export default function SendPreviewButton(props: Props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        type="button"
        className="p-button-sm"
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        onClick={() => setVisible(true)}
        disabled={
          props.isDisabled ||
          props.contentId === undefined ||
          props.params === undefined ||
          (props.messageType !== MessageType.Sms &&
            props.messageType !== MessageType.Email &&
            props.messageType !== MessageType.Notification)
        }
      >
        Send preview
      </Button>
      {props.contentId && props.params && props.messageType && (
        <SendPreviewDialog
          messageType={props.messageType}
          contentId={props.contentId}
          params={props.params}
          visible={visible}
          onHide={() => setVisible(false)}
        />
      )}
    </>
  );
}
