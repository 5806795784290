import NoItemSelected from '../../../NoItemSelected';
import FaEmptySetIcon from '../../../Icons/FaEmptySetIcon';
import Overlay from '../../../Overlay';
import React from 'react';

type Props = {
  title?: string;
  messageType: string;
  isBodyEmpty?: boolean;
  isLoading: boolean;
  hasError: boolean;
  error?: string;
  notificationPreview: React.ReactElement;
  smsPreview: React.ReactElement;
  emailPreview: React.ReactElement;
};

export default function PreviewContent(props: Props) {
  let preview: React.ReactNode;

  if (props.isLoading) {
    preview = <Overlay label="Generating preview" />;
  } else if (props.isBodyEmpty || props.isBodyEmpty === undefined) {
    preview = (
      <NoItemSelected icon={<FaEmptySetIcon />} title="No content" note="This content is empty and has no value." />
    );
  } else if (props.hasError) {
    preview = (
      <NoItemSelected
        icon={<FaEmptySetIcon />}
        title={props.error ? 'No preview available' : 'No preview'}
        note={props.error || 'The preview cannot be generated.'}
      />
    );
  } else {
    preview = <Preview {...props} />;
  }

  return (
    <div className="editor-preview">
      {props.title && <div className="preview-title">{props.title}</div>}
      <div className="preview-content">{preview}</div>
    </div>
  );
}

function Preview(props: {
  messageType: string;
  notificationPreview: React.ReactElement;
  smsPreview: React.ReactElement;
  emailPreview: React.ReactElement;
}) {
  switch (props.messageType) {
    case 'ntf':
      return props.notificationPreview;
    case 'sms':
      return props.smsPreview;
    case 'email':
      return props.emailPreview;
    default:
      return null;
  }
}
