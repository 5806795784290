import { SubTemplate } from '../../../../api/generated';
import ValueOrPlaceholder from '../../../../components/ValueOrPlaceholder';

type Props = {
  subTemplate: SubTemplate;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.subTemplate.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.subTemplate.description} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="parameters">Selected parameters</label>
          <div>
            {props.subTemplate.subTemplateParameters ? (
              props.subTemplate.subTemplateParameters.map((eP, index) => {
                return <div key={`parameter-${index}`}>{eP?.parameter?.name}</div>;
              })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
        <div className="field">
          <label htmlFor="decisions">Decisions</label>
          <div>
            {props.subTemplate.subTemplateParameters ? (
              props.subTemplate.subTemplateParameters
                .filter((eP) => eP?.isDecision || eP?.parameter?.name.startsWith('Sys'))
                .map((eP, index) => {
                  return <div key={`decision-${index}`}>{eP?.parameter?.name}</div>;
                })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
        <div className="field">
          <label htmlFor="conditionalUse">Conditional Use</label>
          {props.subTemplate.conditionalUse?.conditions && props.subTemplate.conditionalUse?.conditions.length > 0 ? (
            props.subTemplate.conditionalUse?.conditions.map((item) => (
              <div key={item?.parameterId}>{JSON.stringify(removeNullProperties(item?.value))}</div>
            ))
          ) : (
            <div>
              <ValueOrPlaceholder value={null} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function removeNullProperties(obj: any): any {
  if (obj === null || obj === undefined) return obj;
  if (typeof obj !== 'object') return obj;

  return Object.entries(obj)
    .filter(([_, value]) => value !== null)
    .reduce((newObj, [key, value]) => {
      return {
        ...newObj,
        [key]: removeNullProperties(value),
      };
    }, {});
}

export default DetailsTab;
