function FaEllipsisVIcon() {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 10.5C2.8125 10.5 3.5 11.1875 3.5 12C3.5 12.8438 2.8125 13.5 2 13.5C1.15625 13.5 0.5 12.8438 0.5 12C0.5 11.1875 1.15625 10.5 2 10.5ZM2 5.5C2.8125 5.5 3.5 6.1875 3.5 7C3.5 7.84375 2.8125 8.5 2 8.5C1.15625 8.5 0.5 7.84375 0.5 7C0.5 6.1875 1.15625 5.5 2 5.5ZM2 3.5C1.15625 3.5 0.5 2.84375 0.5 2C0.5 1.1875 1.15625 0.5 2 0.5C2.8125 0.5 3.5 1.1875 3.5 2C3.5 2.84375 2.8125 3.5 2 3.5Z"
        fill="#25292E"
      />
    </svg>
  );
}

export default FaEllipsisVIcon;
