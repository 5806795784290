import { useUpdateWorkflowMutation, useWorkflowQuery, Workflow } from '../../../../api/generated';
import useToast from '../../../../hooks/useToast';
import SidePanel from '../../../../components/SidePanel';
import WorkflowEditForm from './WorkflowEditForm';
import { WorkflowUpdate } from './WorkflowsTypes';

type Props = {
  workflowId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function WorkflowEdit({ workflowId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data } = useWorkflowQuery({ id: workflowId.toString() });
  const workflow = data?.workflowById;

  const toast = useToast();

  const [updateWorkflow, { isLoading }] = useUpdateWorkflowMutation();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <WorkflowEditForm
        workflow={workflow as Workflow}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (workflow: WorkflowUpdate) => {
          await updateWorkflow({
            id: workflowId.toString() || '',
            input: {
              name: workflow.name,
              description: workflow.description,
              email: workflow.email,
              sms: workflow.sms,
              notification: workflow.notification,
            },
          })
            .unwrap()
            .then(async () => {
              toast.success(`Workflow '${workflow.name}' has been updated successfully.`);
              onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default WorkflowEdit;
