import { Divider } from 'primereact/divider';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import Overlay from '../../../components/Overlay';

type LinkPreviewProps = {
  reference: string;
  content: string;
  draftReference: string;
  draftContent: string;
  hasUnsavedChanges: boolean;
  isLoadingDraftPreview: boolean;
  hasDraftPreviewError: boolean;
  isLoadingSavedPreview: boolean;
  hasSavedPreviewError: boolean;
};

export function LinkPreview(props: LinkPreviewProps) {
  return (
    <>
      <div className="split-panel">
        {props.hasUnsavedChanges && (
          <>
            <PreviewContent
              title={'Draft'}
              isBodyEmpty={!props.draftContent}
              isLoading={props.isLoadingDraftPreview}
              hasError={props.hasDraftPreviewError}
              reference={props.draftReference}
              content={props.draftContent}
            />
            <Divider layout="vertical" />
          </>
        )}
        <PreviewContent
          title={props.hasUnsavedChanges ? 'Saved version' : undefined}
          isBodyEmpty={!props.content}
          isLoading={props.isLoadingSavedPreview}
          hasError={props.hasSavedPreviewError}
          reference={props.reference}
          content={props.content}
        />
      </div>
    </>
  );
}

function PreviewContent(props: {
  title?: string;
  isLoading: boolean;
  isBodyEmpty: boolean;
  hasError: boolean;
  reference: string;
  content: string;
}) {
  let preview: React.ReactNode;

  if (props.isLoading) {
    preview = <Overlay label="Generating preview" />;
  } else if (props.isBodyEmpty || props.isBodyEmpty === undefined) {
    preview = (
      <NoItemSelected icon={<FaEmptySetIcon />} title="No content" note="This content is empty and has no value." />
    );
  } else if (props.hasError) {
    preview = (
      <NoItemSelected
        icon={<FaEmptySetIcon />}
        title={'No preview available'}
        note={'The preview cannot be generated.'}
      />
    );
  } else {
    preview = <LinkPreviewContent reference={props.reference} content={props.content} />;
  }

  return (
    <div className="editor-preview">
      {props.title && <div className="preview-title">{props.title}</div>}
      <div className="preview-content">{preview}</div>
    </div>
  );
}

function LinkPreviewContent(props: { reference: string; content: string }) {
  return (
    <div className="link-preview">
      <div className="field">
        <div className="title">Reference</div>
        <div className="value mt-2">{props.reference}</div>
      </div>
      <div className="field">
        <div className="title">Link</div>
        <div className="value mt-2">
          <a href={props.reference} target="_blank" rel="noreferrer">
            <span dangerouslySetInnerHTML={{ __html: props.content }} />
          </a>
        </div>
      </div>
    </div>
  );
}
