function FaFilterIcon() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4688 0H2.5C1.15625 0 0.46875 1.625 1.4375 2.5625L6.5 7.625V13C6.5 13.5 6.71875 13.9375 7.09375 14.25L9.09375 15.75C10.0625 16.4062 11.5 15.7812 11.5 14.5312V7.625L16.5312 2.5625C17.5 1.625 16.8125 0 15.4688 0ZM10 7V14.5L8 13V7L2.5 1.5H15.5L10 7Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaFilterIcon;
