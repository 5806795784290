import { SafeContentParameters } from './editorTypes';
import { SysDataTypeId, SysParameterTypeId } from '../../api/generated';

export function getSystemParams(parameters: SafeContentParameters[]) {
  return filterParams(parameters, SysParameterTypeId.System);
}

export function getDecisionParams(parameters: SafeContentParameters[]) {
  const dataHolderDecisions = getDataHolderParams(parameters).filter((p: any) => p?.isDecision);
  const decisionParams = filterParams(
    parameters.filter((p: any) => p?.isDecision),
    SysParameterTypeId.Decision,
  );
  return [...dataHolderDecisions, ...decisionParams];
}

export function getDataHolderParams(parameters: SafeContentParameters[]) {
  return filterParams(parameters, SysParameterTypeId.DataHolder);
}

export function getDataHolderNonDecisionParams(parameters: SafeContentParameters[]) {
  const value = getDataHolderParams(parameters).filter(
    (p: any) =>
      !p?.isDecision &&
      p?.parameter.sysDataTypeId !== SysDataTypeId.Bool &&
      p?.parameter.sysDataTypeId !== SysDataTypeId.Enum,
  );
  return value;
}

function filterParams(parameters: SafeContentParameters[], type: SysParameterTypeId) {
  return parameters.filter((param: any) => param?.parameter?.sysParameterTypeId === type);
}
