import { Parameter } from '../../../../api/generated';
import { Tooltip } from 'primereact/tooltip';
import SampleData from '../../../SampleData';
import { classNames } from 'primereact/utils';

type Props = {
  parameter: Parameter;
  customSampleValue?: string;
  onValueChange: (value: string) => void;
  onValueReset: () => void;
  isHighlighted?: boolean;
};

export default function ParameterSampleValue(props: Props) {
  const p = props.parameter;
  const hasChangedValue = props.customSampleValue !== undefined;
  return (
    <>
      {p.description && (
        <Tooltip target={'.param-' + p.parameterId} mouseTrack mouseTrackLeft={10} content={p.description} />
      )}
      <div className={classNames('field mb-0 py-2 px-3', { highlighted: props.isHighlighted })}>
        <label className={'param-' + p.parameterId}>{p.name}</label>
        <div className="flex align-items-center w-full">
          <div className="flex-grow-1">
            <SampleData
              type={p.sysDataTypeId}
              value={(hasChangedValue ? props.customSampleValue : p.sampleValue) || ''}
              setValue={props.onValueChange}
              enumItems={p.enumItems || []}
              isSimple={true}
            />
          </div>
          {hasChangedValue && (
            <span className="fa-regular fa-rotate cursor-pointer ml-2" onClick={props.onValueReset}></span>
          )}
        </div>
      </div>
    </>
  );
}
