import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Field, useField } from 'formik';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  options: { value: number; label: string }[];
};

export default function DropdownField(props: Props) {
  return <Field {...props} as={DropdownWrapper} />;
}

function DropdownWrapper(props: any) {
  // eslint-disable-next-line
  const [_, meta] = useField(props.name);
  const shouldDisplayError: boolean = !!(meta.error && meta.touched);

  return (
    <>
      <div className="field w-full">
        <div className="flex align-items-center">
          <span className="flex-grow-1 mb-1">
            <label htmlFor={props.name} className={classNames({ 'p-invalid': shouldDisplayError })}>
              {props.label}
            </label>
          </span>
        </div>
        <Dropdown id={props.name} {...props} className={classNames('w-full', { 'p-invalid': shouldDisplayError })} />
        {shouldDisplayError && <small className="p-error block">{meta.error}</small>}
      </div>
    </>
  );
}
