import { CodeBlock } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaCodeIcon from '../../../components/Icons/FaCodeIcon';

type Props = {
  codeBlock: CodeBlock;
  setSelected: () => void;
  isSelected: boolean;
  onEditor: () => void;
  onProperties: () => void;
  onDelete: () => void;
};

function SnippetEntry(props: Props) {
  return (
    <EntryItem
      name={props.codeBlock.name}
      description={props.codeBlock.description}
      deleteTitle="Delete Snippet"
      deleteMessage={
        <>
          Are you sure you want to delete snippet <span className="font-bold">{props.codeBlock.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaCodeIcon />
            Snippet
          </div>
        </>
      }
      {...props}
    />
  );
}

export default SnippetEntry;
