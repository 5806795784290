import { useMsal } from '@azure/msal-react';

import { ApiRole } from '../api/ApiRole.types';
import { useProfilePhotoContext } from '../providers/ProfilePhotoProvider';
import { useRoles } from './useRoles';

export interface IUserInfo {
  name: string;
  role: string;
  roles: ApiRole[];
  avatar: string | null;
}

export const useUserInfo = (): IUserInfo => {
  const { accounts } = useMsal();
  const { avatar } = useProfilePhotoContext();
  const roles = useRoles();

  const name = (accounts[0] && accounts[0].name) || (accounts[0] && accounts[0].username) || '';

  return {
    name: name,
    role: roles.role,
    roles: roles.getRoles(),
    avatar: avatar,
  };
};
