import { MasterTemplate, useMasterTemplatesQuery } from '../../../../api/generated';
import { useState } from 'react';
import { MasterTemplateEditFormTab } from './MasterTemplateTypes';
import { MenuItem } from 'primereact/menuitem';
import { Formik } from 'formik';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useValidationSchema } from './useValidationSchema';
import BasicInfoTab from './BasicInfoTab';

type Props = {
  masterTemplate: MasterTemplate;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (masterTemplate: any) => void;
  onClose: () => void;
};

export default function MasterTemplateEditForm(props: Props) {
  const { data: masterTemplates } = useMasterTemplatesQuery();
  const validationSchema = useValidationSchema(
    masterTemplates?.allMasterTemplates
      ?.filter((item) => item?.name !== props.masterTemplate.name)
      .map((item) => item?.name || '') || [],
  );

  const [tab, setTab] = useState<MasterTemplateEditFormTab>(MasterTemplateEditFormTab.BasicInfo);
  const tabItems: MenuItem[] = [
    {
      label: 'Basic info',
      command: () => setTab(MasterTemplateEditFormTab.BasicInfo),
    },
  ];

  return (
    <Formik
      initialValues={props.masterTemplate}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        props.onConfirm({
          ...props.masterTemplate,
          name: formik.name,
          description: formik.description,
        });
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu model={tabItems} />
          </div>
          <div className="side-panel-content side-panel-content-section">
            {tab === MasterTemplateEditFormTab.BasicInfo && <BasicInfoTab />}
          </div>
          <div className="side-panel-controls">
            <Button
              label={props.isNew ? 'Create' : 'Save'}
              type="submit"
              onClick={submitForm}
              disabled={props.isLoading}
              className="p-button-sm"
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={props.onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}
