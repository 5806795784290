import { useParams } from 'react-router-dom';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import {
  Link,
  useLazyLinkPreviewByIdQuery,
  useLinkQuery,
  useUpdateLinkMutation,
  useLazyLinkPreviewByContentParametersQuery,
} from '../../api/generated';
import useAppNavigate from '../../hooks/useAppNavigate';
import FetchInProgress from '../../components/FetchInProgress';
import Page from '../../components/Page';
import { classNames } from 'primereact/utils';
import { ActionType } from '../../common/actionTypes';
import { useCallback, useEffect, useState } from 'react';
import FaLinkIcon from '../../components/Icons/FaLinkIcon';
import TopBar from '../../components/Editor/components/TopBar';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import ContentEditorSection from '../../components/Editor/components/sections/ContentEditorSection';
import {
  JoditEditorConnector,
  useJoditEditorConnector,
} from '../../components/Editor/components/jodit-editor/JoditEditorConnector';
import LinkEditor from './components/LinkEditor';
import AvailableLinkObjects from './components/AvailableLinkObjects';
import { AvailableObjectType } from '../../components/Editor/components/tree-items/AvailableObjectType';
import { LinkPreview } from './components/LinkPreview';

function LinkEditorPage() {
  const { id } = useParams();
  const { data, isFetching: isLoadingLink } = useLinkQuery({ id: parseInt(id || '') });
  const [updateLink, { isLoading: isSaving }] = useUpdateLinkMutation();

  const [linkPreview, setLinkPreview] = useState({ reference: '', content: '' });
  const [linkContentPreview, setLinkContentPreview] = useState({ reference: '', content: '' });
  const [getLinkPreview, { isFetching: isLoadingPreview, isError: hasPreviewError }] = useLazyLinkPreviewByIdQuery();
  const [getLinkPreviewByContent, { isFetching: isLoadingPreviewByContent, isError: hasPreviewByContentError }] =
    useLazyLinkPreviewByContentParametersQuery();

  const link = data?.linkById;

  const navigate = useAppNavigate();

  const [action, setAction] = useState<ActionType>(ActionType.Edit);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);
  const [usedObjects, setUsedObjects] = useState<string[]>([]);
  const [isLoadingObjects, setIsLoadingObjects] = useState(true);

  const [lastFocusedEditorConnector, setLastFocusedEditorConnector] = useState<JoditEditorConnector | null>(null);
  const referenceEditorConnector = useJoditEditorConnector({
    onChange: () => handleReferenceChange,
    enabledVariableTypes: [AvailableObjectType.Parameter],
  });
  const contentEditorConnector = useJoditEditorConnector({ onChange: () => handleContentChange() });

  const handlePreview = useCallback(async () => {
    if (link) {
      getLinkPreview({ id: link.linkId })
        .unwrap()
        .then((data) => {
          setLinkPreview({
            reference: data.linkPreviewById?.reference || '',
            content: data.linkPreviewById?.content || '',
          });
        })
        .catch(console.error);
      if (unsavedChanges) {
        getLinkPreviewByContent({
          input: {
            linkId: link.linkId,
            content: {
              reference: referenceEditorConnector.getValue(),
              content: contentEditorConnector.getValue(),
            },
          },
        })
          .unwrap()
          .then((data) => {
            setLinkContentPreview({
              reference: data.linkPreviewByContentParameters?.reference || '',
              content: data.linkPreviewByContentParameters?.content || '',
            });
          })
          .catch(console.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLinkPreview, getLinkPreviewByContent, link, unsavedChanges]);

  const handleSave = useCallback(async () => {
    if (link) {
      await updateLink({
        id: link.linkId,
        input: {
          reference: referenceEditorConnector.getValue(),
          content: contentEditorConnector.getValue(),
        },
      })
        .unwrap()
        .then(() => {
          setTimeout(() => {
            setShowSaveSuccessful(false);
          }, 2000);
          setUnsavedChanges(false);
          setShowSaveSuccessful(true);
          handlePreview();
        });
    }
  }, [contentEditorConnector, handlePreview, link, referenceEditorConnector, updateLink]);

  const detectUsedObjects = useCallback(() => {
    setUsedObjects(
      Array.from(new Set([...referenceEditorConnector.getVariables(), ...contentEditorConnector.getVariables()])),
    );
  }, [referenceEditorConnector, contentEditorConnector]);

  const handleReferenceChange = useCallback(() => {
    setUnsavedChanges(true);
    detectUsedObjects();
  }, [detectUsedObjects]);

  const handleContentChange = useCallback(() => {
    setUnsavedChanges(true);
    detectUsedObjects();
  }, [detectUsedObjects]);

  const getActiveEditorConnector = useCallback(() => {
    return lastFocusedEditorConnector || contentEditorConnector;
  }, [lastFocusedEditorConnector, contentEditorConnector]);

  useEffect(() => {
    if (link) {
      referenceEditorConnector.setValue(link.reference || '');
      contentEditorConnector.setValue(link.content || '');
      handlePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => {
    if (action === ActionType.View) {
      handlePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, handlePreview]);

  if (!id || link === null) {
    navigate(AppRoute.EntryPoints);
  }

  const isLoadingOtherContent = isLoadingObjects;

  return isLoadingLink ? (
    <FetchInProgress />
  ) : (
    <Page
      className={classNames('page-link-editor', 'content-editor', {
        'link-preview': action === ActionType.View && unsavedChanges,
      })}
      windowTitle={`Edit ${link!.name}`}
      topBar={
        <TopBar
          previousPath={AppRoute.Links}
          title={'Link'}
          icon={<FaLinkIcon />}
          contentName={link!.name}
          previewVisible={true}
          isLoading={isLoadingOtherContent}
          setAction={setAction}
        />
      }
    >
      <MainLayoutWithSidebar
        sidebarPosition="left"
        isLoading={isLoadingOtherContent}
        sidebar={
          <>
            {link && (
              <AvailableLinkObjects
                link={link as Link}
                usedObjects={usedObjects}
                onObjectSelect={(object) => getActiveEditorConnector().insertVariable(object.value)}
                onLoadingComplete={(isLoading) => setIsLoadingObjects(isLoading)}
                enabledTypes={getActiveEditorConnector().getEnabledVariableTypes?.()}
              />
            )}
          </>
        }
      >
        <div className="editor">
          <div className="content-editor-section">
            <ContentEditorSection
              action={action}
              messageType={undefined}
              contentId={link!.linkId}
              params={undefined}
              unsavedChanges={unsavedChanges}
              saveSuccessful={showSaveSuccessful}
              isLoading={isLoadingOtherContent}
              isSaving={isSaving}
              isDisabled={false}
              isSendPreviewDisabled={true}
              minimalHeight={490}
              onSave={handleSave}
              onClear={() => {
                referenceEditorConnector.setValue(null);
                contentEditorConnector.setValue(null);
                setUnsavedChanges(true);
              }}
              edit={
                <LinkEditor
                  referenceEditorConnector={referenceEditorConnector}
                  contentEditorConnector={contentEditorConnector}
                  onEditorFocus={setLastFocusedEditorConnector}
                />
              }
              preview={
                <LinkPreview
                  reference={linkPreview.reference}
                  content={linkPreview.content}
                  draftContent={linkContentPreview.content}
                  draftReference={linkContentPreview.reference}
                  hasDraftPreviewError={hasPreviewByContentError}
                  isLoadingDraftPreview={isLoadingPreviewByContent}
                  isLoadingSavedPreview={isLoadingPreview}
                  hasSavedPreviewError={hasPreviewError}
                  hasUnsavedChanges={unsavedChanges}
                />
              }
            />
          </div>
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const linkEditorPage: IAppPage = {
  title: 'Link Editor',
  path: () => AppRoute.LinkEditor,
  page: <LinkEditorPage />,
  require: [],
  isHidden: true,
};

export default linkEditorPage;
