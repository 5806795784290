import { MasterTemplate } from '../../../api/generated';
import NoItemSelected from '../../../components/NoItemSelected';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import MasterTemplateEntry from './MasterTemplateEntry';

type Props = {
  masterTemplates: MasterTemplate[] | null;
  selectedMasterTemplateId: number | undefined;
  onSelect: (masterTemplateId: number) => void;
  onEditor: (masterTemplateId: number) => void;
  onProperties: (masterTemplateId: number) => void;
  onDelete: (masterTemplateId: number) => void;
};

export default function MasterTemplateList(props: Props) {
  return (
    <>
      {props.masterTemplates && props.masterTemplates.length > 0 ? (
        props.masterTemplates.map((masterTemplate) => (
          <MasterTemplateEntry
            key={masterTemplate.masterTemplateId}
            masterTemplate={masterTemplate as MasterTemplate}
            isSelected={masterTemplate.masterTemplateId === props.selectedMasterTemplateId}
            setSelected={() => props.onSelect(masterTemplate.masterTemplateId)}
            onEditor={() => props.onEditor(masterTemplate.masterTemplateId)}
            onProperties={() => props.onProperties(masterTemplate.masterTemplateId)}
            onDelete={() => props.onDelete(masterTemplate.masterTemplateId)}
          />
        ))
      ) : (
        <NoItemSelected
          icon={<FaEmptySetIcon />}
          title={'No master templates'}
          note={'Start by adding a master template!'}
        />
      )}
    </>
  );
}
