import { useLocalStorage } from 'usehooks-ts';

import { ApiRole } from '../api/ApiRole.types';

const STORAGE_KEY = 'roles';

export type TokenClaims = {
  roles: string[];
};

export const useRoles = () => {
  const [value, setValue] = useLocalStorage<ApiRole[]>(STORAGE_KEY, []);

  const getRoles = () => {
    return value;
  };
  const getMasterRole = () => {
    if (hasRole(ApiRole.Admin)) {
      return 'Super Administrator';
    } else if (hasRole(ApiRole.IT)) {
      return 'Administrator';
    } else if (hasRole(ApiRole.Editor)) {
      return 'Editor';
    } else {
      return 'User';
    }
  };
  const setRoles = (roles: ApiRole[]) => {
    setValue(roles);
  };
  const hasRole = (role: ApiRole) => {
    try {
      return value.some((apiRole: ApiRole) => apiRole === role);
    } catch (e) {
      clearRoles();
    }
  };
  const isAllowed = (require?: ApiRole[]) =>
    require && require.length > 0 ? require.some((role) => hasRole(role)) : true;
  const clearRoles = () => {
    setValue([]);
  };

  return {
    getRoles,
    setRoles,
    hasRole,
    isAllowed,
    isAdmin: hasRole(ApiRole.Admin),
    role: getMasterRole(),
    clearRoles,
  };
};
