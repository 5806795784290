import { JoditEditorConnector } from '../../Editor/components/jodit-editor/JoditEditorConnector';
import JoditEditor from '../../Editor/components/jodit-editor/JoditEditor';
import { MessageType } from '../../Editor/editorTypes';

type Props = {
  messageType: MessageType;
  subjectEditorConnector: JoditEditorConnector;
  bodyEditorConnector: JoditEditorConnector;
  onEditorFocus: (connector: JoditEditorConnector) => void;
  isDisabled?: boolean;
};

export default function ContentEditorEdit(props: Props) {
  return (
    <>
      <div className="mb-3">
        <div className="field">
          <div className="title">Subject</div>
          <div className="mt-2"></div>
          <JoditEditor
            disabled={props.isDisabled || props.messageType === MessageType.Sms}
            isOneLine
            isWithoutStyles
            withoutToolbar
            placeholder="This subject is empty and has no value."
            editorConnector={props.subjectEditorConnector}
            onFocus={() => props.onEditorFocus(props.subjectEditorConnector)}
          />
        </div>
      </div>
      <div className="field">
        <div className="title">Body</div>
        <div className="mt-2"></div>
        <JoditEditor
          disabled={props.isDisabled}
          placeholder="This content is empty and has no value."
          editorConnector={props.bodyEditorConnector}
          onFocus={() => props.onEditorFocus(props.bodyEditorConnector)}
          isWithoutStyles={props.messageType !== MessageType.Email}
          withoutToolbar={props.messageType !== MessageType.Email}
        />
      </div>
    </>
  );
}
