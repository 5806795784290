import { SysDataTypeId, SysParameterTypeId } from '../../../api/generated';

export enum SnippetEditFormTab {
  BasicInfo,
  Parameters,
}
export enum SnippetViewTab {
  Details,
  References,
}

export type SnippetUpdate = {
  codeBlockId: number;
  name: string;
  description: string;
  parameters: CodeBlockParameter[];
};

export type CodeBlockParameter = {
  id?: number;
  name?: string;
  description?: string | null;
  type?: SysDataTypeId;
  sysParameterTypeId?: SysParameterTypeId;
};
