import { Formik } from 'formik';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useState } from 'react';

import { Parameter, SubTemplate, useParametersQuery, useSubTemplatesQuery } from '../../../api/generated';
import BasicInfoTab from './BasicInfoTab';
import ParametersTab from './ParametersTab';
import DecisionParametersTab from './DecisionParametersTab';
import ConditionalUseTab from './ConditionalUseTab';
import { SubTemplateConditionalUse, SubTemplateEditFormTab, SubTemplateParameter } from './SubTemplateTypes';
import { useValidationSchema } from './useValidationSchema';

type Props = {
  subTemplate: SubTemplate;
  isNew: boolean;
  isLoading: boolean;
  onConfirm: (subTemplate: any) => void;
  onClose: () => void;
};

function SubTemplateEditForm({ subTemplate, isNew, isLoading, onConfirm, onClose }: Props): JSX.Element | null {
  const { data: subTemplates } = useSubTemplatesQuery();
  const validationSchema = useValidationSchema(
    subTemplates?.allSubTemplates?.filter((item) => item?.name !== subTemplate.name).map((item) => item?.name || '') ||
      [],
  );

  const { data: allParameters } = useParametersQuery();

  const initialConditions =
    (subTemplate.conditionalUse?.conditions?.filter(Boolean) as SubTemplateConditionalUse[]) || [];
  const [conditions, setConditions] = useState<SubTemplateConditionalUse[]>(initialConditions);

  const [parameters, setParameters] = useState<SubTemplateParameter[]>([]);
  const [activeParameters, setActiveParameters] = useState<SubTemplateParameter[]>([]);
  const [tab, setTab] = useState<SubTemplateEditFormTab>(SubTemplateEditFormTab.BasicInfo);

  const updateConditions = useCallback((newConditions: SubTemplateConditionalUse[]) => {
    setConditions(newConditions);
  }, []);

  useEffect(() => {
    if (allParameters) {
      const activeParametersEntries =
        subTemplate?.subTemplateParameters?.map((parameter) => ({
          id: parameter?.parameterId,
          name: parameter?.parameter?.name,
          description: parameter?.parameter?.description,
          isDecision: parameter?.isDecision,
          type: parameter?.parameter?.sysDataTypeId,
          sysParameterTypeId: parameter?.parameter?.sysParameterTypeId,
          enumItems: parameter?.parameter?.enumItems,
        })) || [];
      const activeParametersIds = activeParametersEntries.map((parameter) => parameter.id);
      const parametersEntries =
        allParameters.allParameters
          ?.filter((param) => !activeParametersIds.includes(param?.parameterId))
          .map((param) => ({
            id: param?.parameterId,
            name: param?.name,
            description: param?.description,
            isDecision: false,
            type: param?.sysDataTypeId,
            sysParameterTypeId: param?.sysParameterTypeId,
            enumItems: param?.enumItems,
          })) || [];

      setParameters(parametersEntries);
      setActiveParameters(activeParametersEntries);
    }
  }, [allParameters, subTemplate?.subTemplateParameters]);

  const renderTabContent = () => {
    switch (tab) {
      case SubTemplateEditFormTab.BasicInfo:
        return <BasicInfoTab />;
      case SubTemplateEditFormTab.Parameters:
        return (
          <ParametersTab
            parameters={parameters}
            setParameters={setParameters}
            activeParameters={activeParameters}
            setActiveParameters={setActiveParameters}
          />
        );
      case SubTemplateEditFormTab.DecisionParameters:
        return (
          <DecisionParametersTab
            parameters={parameters}
            setParameters={setParameters}
            activeParameters={activeParameters}
            setActiveParameters={setActiveParameters}
          />
        );
      case SubTemplateEditFormTab.ConditionalUse:
        return (
          <ConditionalUseTab
            conditions={conditions}
            setConditions={updateConditions}
            parameters={(activeParameters as Parameter[]) || []}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={subTemplate}
      validationSchema={validationSchema}
      onSubmit={(formik) => {
        onConfirm({
          ...subTemplate,
          name: formik.name,
          description: formik.description,
          parameters: activeParameters,
          conditions: conditions,
        });
      }}
    >
      {({ submitForm }) => (
        <>
          <div className="content-tabs">
            <TabMenu
              model={[
                { label: 'Basic info', command: () => setTab(SubTemplateEditFormTab.BasicInfo) },
                { label: 'Parameters', command: () => setTab(SubTemplateEditFormTab.Parameters) },
                { label: 'Decisions', command: () => setTab(SubTemplateEditFormTab.DecisionParameters) },
                { label: 'Conditions', command: () => setTab(SubTemplateEditFormTab.ConditionalUse) },
              ]}
            />
          </div>
          <div className="side-panel-content side-panel-content-section">{renderTabContent()}</div>
          <div className="side-panel-controls">
            <Button
              label={isNew ? 'Create' : 'Save'}
              type="submit"
              disabled={isLoading}
              className="p-button-sm"
              onClick={submitForm}
            />
            <Button label="Cancel" type="button" className="p-button-sm p-button-secondary" onClick={onClose} />
          </div>
        </>
      )}
    </Formik>
  );
}

export default SubTemplateEditForm;
