function FaThumbsUpIcon() {
  return (
    <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.25 29H5.75C2.51562 29 0 31.5156 0 34.5703V74.6406C0 77.6953 2.51562 80.2109 5.75 80.2109H17.25C20.3047 80.2109 23 77.6953 23 74.6406V34.75C23 31.6953 20.3047 29 17.25 29ZM92 35.2891C92 28.8203 86.4297 23.4297 79.9609 23.4297H61.9922C63.6094 18.9375 64.6875 14.9844 64.6875 12.6484C64.6875 6.53906 59.8359 0.429688 51.9297 0.429688C43.6641 0.429688 41.3281 6.17969 39.5312 10.8516C33.7812 25.4062 28.75 22.7109 28.75 27.5625C28.75 29.8984 30.5469 31.875 33.0625 31.875C33.9609 31.875 34.8594 31.6953 35.5781 30.9766C49.4141 20.0156 46 9.05469 51.9297 9.05469C54.9844 9.05469 56.0625 11.2109 56.0625 12.6484C56.0625 14.0859 54.625 19.8359 51.3906 25.5859C51.0312 26.3047 50.8516 27.0234 50.8516 27.7422C50.8516 30.2578 52.8281 31.875 55.1641 31.875H79.7812C81.7578 32.0547 83.375 33.4922 83.375 35.2891C83.375 37.0859 81.9375 38.7031 80.1406 38.7031C77.8047 38.8828 76.0078 40.8594 76.0078 43.0156C76.0078 45.8906 78.1641 45.8906 78.1641 48.2266C78.1641 52.7188 71.875 50.3828 71.875 55.7734C71.875 57.75 72.9531 58.1094 72.9531 59.7266C72.9531 63.8594 67.5625 62.2422 67.5625 67.0938C67.5625 67.9922 67.9219 68.1719 67.9219 68.8906C67.9219 70.6875 66.3047 72.3047 64.5078 72.3047H54.9844C50.3125 72.3047 45.8203 70.6875 42.2266 67.9922L35.5781 62.9609C34.8594 62.4219 33.9609 62.0625 33.0625 62.0625C30.5469 62.0625 28.5703 64.2188 28.5703 66.375C28.5703 67.8125 29.2891 69.0703 30.3672 69.9688L37.0156 74.8203C42.2266 78.7734 48.5156 80.75 54.9844 80.75H64.5078C70.7969 80.75 76.0078 75.8984 76.3672 69.6094C79.6016 67.4531 81.5781 63.8594 81.5781 59.7266C81.5781 59.1875 81.5781 58.6484 81.5781 58.1094C84.6328 55.9531 86.7891 52.3594 86.7891 48.2266C86.7891 47.3281 86.6094 46.4297 86.4297 45.3516C89.6641 43.1953 92 39.6016 92 35.2891Z"
        fill="#CFD2D6"
      />
    </svg>
  );
}

export default FaThumbsUpIcon;
