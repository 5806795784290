export enum ImageEditFormTab {
  BasicInfo,
}

export enum ImageViewTab {
  Details,
  References,
}

export type ImageUpdate = {
  imageId: number;
  name: string;
  description: string;
  alternateText: string;
  width: number;
  height: number;
  source: string;
};
