import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useState } from 'react';

import { useUpdateTemplateMutation, useUpdateSubTemplateMutation } from '../../../../api/generated';
import { convertCountryCode } from '../../../../common/countryUtils';
import EntriesSelection from '../../../EntriesSelection';
import SidePanel from '../../../SidePanel';
import { AppRoute } from '../../../../route/AppRoute';

type LanguagesSettings = {
  defaultLanguage: string;
  langsUsingDefault: string[];
};
type Props = {
  defaultLanguage: string;
  langs: string[];
  langsUsingDefault: string[];
  sidebarVisible: boolean;
  onSidebarClose: (data: LanguagesSettings | null) => void;
  templateId: string;
  path: AppRoute;
};

function DefaultLanguageSettings(props: Props) {
  const [defaultLanguage, setDefaultLanguage] = useState(props.defaultLanguage);
  const [langsUsingDefault, setLangsUsingDefaults] = useState([...props.langsUsingDefault]);

  const [updateTemplate, { isLoading: isSavingTemplate }] = useUpdateTemplateMutation();
  const [updateSubTemplate, { isLoading: isSavingSubtemplate }] = useUpdateSubTemplateMutation();

  useEffect(() => {
    setDefaultLanguage(props.defaultLanguage);
    setLangsUsingDefaults([...props.langsUsingDefault]);
  }, [props.defaultLanguage, props.langsUsingDefault, props.sidebarVisible]);

  /** remove lang in selected if set as default */
  useEffect(() => {
    const index = langsUsingDefault.indexOf(defaultLanguage);
    if (index > -1) {
      const copy = [...langsUsingDefault];
      copy.splice(index, 1);
      setLangsUsingDefaults(copy);
    }
  }, [defaultLanguage, langsUsingDefault]);

  const handleUpdate = useCallback(async () => {
    if (props.path === AppRoute.SubTemplateEditor) {
      await updateSubTemplate({
        id: props.templateId,
        input: {
          defaultLanguage: defaultLanguage,
          languagesUsingDefaultLanguage: langsUsingDefault,
        },
      })
        .unwrap()
        .then((result) =>
          props.onSidebarClose({
            defaultLanguage: result.updateSubTemplate?.defaultLanguage || '',
            langsUsingDefault: result.updateSubTemplate?.languagesUsingDefaultLanguage || ([] as any),
          }),
        )
        .catch((e) => {
          console.error(e);
        });
    } else {
      await updateTemplate({
        id: props.templateId,
        input: {
          defaultLanguage: defaultLanguage,
          languagesUsingDefaultLanguage: langsUsingDefault,
        },
      })
        .unwrap()
        .then((result) =>
          props.onSidebarClose({
            defaultLanguage: result.updateTemplate?.defaultLanguage || '',
            langsUsingDefault: result.updateTemplate?.languagesUsingDefaultLanguage || ([] as any),
          }),
        )
        .catch((e) => {
          console.error(e);
        });
    }
  }, [defaultLanguage, langsUsingDefault, props, updateTemplate, updateSubTemplate]);

  const itemTemplate = (item: any) => {
    return item ? (
      <div className="flex gap-2 align-items-center">
        <span className={`fi fi-${convertCountryCode(item.value)}`} />
        <span>{item.label}</span>
      </div>
    ) : null;
  };

  return (
    <SidePanel
      title="Default Language Settings"
      visible={props.sidebarVisible}
      onClose={() => props.onSidebarClose(null)}
      size="wide"
    >
      <div className="side-panel-content side-panel-content-section">
        <div className="field">
          <label htmlFor="defaultLanguage">Default language</label>
          <Dropdown
            id="defaultLanguage"
            name="defaultLanguage"
            value={defaultLanguage}
            placeholder="Select value"
            itemTemplate={itemTemplate}
            valueTemplate={itemTemplate}
            className={classNames('w-full', { 'p-invalid': false })}
            onChange={(event) => setDefaultLanguage(event.target.value)}
            options={props.langs?.map((lang) => {
              return { value: lang, label: lang };
            })}
          />
        </div>

        <div className="field">
          <div className="d-flex langs-using-default">
            <div className="icon fa-regular fa-info-circle"></div>
            <div className="content">Select languages that should adopt default language content.</div>
          </div>
        </div>

        <div className="field">
          <EntriesSelection
            height={437}
            availableItemsLabel="Available languages"
            availableEntries={props.langs
              .filter((lang) => lang !== defaultLanguage)
              .filter((lang) => !langsUsingDefault.includes(lang))
              .sort()}
            selectedItemsLabel="Selected languages"
            selectedEntries={langsUsingDefault.sort()}
            getId={(entry) => props.langs.indexOf(entry)}
            getName={(entry) => props.langs[props.langs.indexOf(entry)]}
            getLabel={(entry) => (
              <div className="d-flex gap-2">
                <span className={`fi fi-${convertCountryCode(entry)}`}></span>
                {props.langs[props.langs.indexOf(entry)]}
              </div>
            )}
            onSelect={(param) => {
              setLangsUsingDefaults((prev) => [...prev, param]);
            }}
            onUnselect={(param) => {
              const index = langsUsingDefault.indexOf(param);
              if (index > -1) {
                const copy = [...langsUsingDefault];
                copy.splice(index, 1);
                setLangsUsingDefaults(copy);
              }
            }}
          />
        </div>
      </div>
      <div className="side-panel-controls">
        <Button
          label="Update"
          disabled={isSavingTemplate || isSavingSubtemplate}
          className="p-button-sm"
          onClick={handleUpdate}
        />
        <Button label="Cancel" className="p-button-sm p-button-secondary" onClick={() => props.onSidebarClose(null)} />
      </div>
    </SidePanel>
  );
}

export default DefaultLanguageSettings;
