import { addCustomYupMethods, ValidationMessage } from '../../../../common/validationUtils';
import * as Yup from 'yup';

addCustomYupMethods();

const basicSchema = (linkNames: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(50)
      //@ts-ignore
      .uniqueIn(linkNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
  });

export function useValidationSchema(linkNames: string[]) {
  return basicSchema(linkNames);
}
