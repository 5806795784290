function FaExclamationTriangle() {
  return (
    <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.3438 42.6094L34.0469 2.79688C33.0625 1.26562 31.5312 0.5 30 0.5C28.3594 0.5 26.8281 1.26562 25.8438 2.79688L2.4375 42.6094C0.796875 45.6719 2.98438 49.5 6.59375 49.5H53.2969C56.9062 49.5 59.0938 45.6719 57.3438 42.6094ZM7.6875 44.25L29.8906 6.29688L52.2031 44.25H7.6875ZM30 33.9688C28.0312 33.9688 26.5 35.5 26.5 37.3594C26.5 39.2188 28.0312 40.75 30 40.75C31.8594 40.75 33.3906 39.2188 33.3906 37.3594C33.3906 35.5 31.8594 33.9688 30 33.9688ZM27.375 17.125V27.625C27.375 29.1562 28.4688 30.25 30 30.25C31.4219 30.25 32.625 29.1562 32.625 27.625V17.125C32.625 15.7031 31.4219 14.5 30 14.5C28.4688 14.5 27.375 15.7031 27.375 17.125Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaExclamationTriangle;
