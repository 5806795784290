import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../../app/store';
import { api } from '../../../api/generated';

export interface EntryPointParametersStatistics {
  parameterId: number;
  values: Array<{
    value: string;
    maxContentCount: number;
    missingContentCount: number;
  }>;
}

interface EntryPointParametersStatisticsState {
  statistics: EntryPointParametersStatistics[];
}

const initialState: EntryPointParametersStatisticsState = {
  statistics: [],
};

const entryPointParametersStatisticsSlice = createSlice({
  name: 'entryPointParametersStatistics',
  initialState,
  reducers: {
    clearParameterStatistics: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.EntryPointStatisticsDetail.matchFulfilled, (state, action) => {
      state.statistics =
        action.payload.entryPointStatisticsDetail?.map((s) => ({
          parameterId: s!.parameterId,
          values: s!.values.map((v) => ({
            value: v.value,
            maxContentCount: v.maxContentCount,
            missingContentCount: v.missingContentCount,
          })),
        })) || [];
    });
  },
});

export const selectParameterStatistics = (parameterId: number | undefined) => (state: AppState) =>
  state.entryPointParametersStatistics.statistics.find((s) => s.parameterId === parameterId);
export const { clearParameterStatistics } = entryPointParametersStatisticsSlice.actions;

export default entryPointParametersStatisticsSlice.reducer;
