import { useImageReferencesQuery } from '../../../../../api/generated';
import { DependencyDetail } from '../../../../../components/DependencyDetail';
import { NoReferences } from '../../../../../components/NoReferences';

type Props = {
  id: number;
};

function ReferencesTab(props: Props) {
  const { data: references } = useImageReferencesQuery({ id: props.id });

  if (!references?.imageReferences?.any) {
    return <NoReferences />;
  }

  return (
    <div className="entry-detail">
      <div className="field">
        <label htmlFor="entryPoints">Entry points</label>
        <div id="entryPoints">
          <DependencyDetail dependency={references.imageReferences.entryPoints || []} type="entryPoints" />
        </div>
      </div>

      <div className="field">
        <label htmlFor="subTemplates">Sub-templates</label>
        <div id="subTemplates">
          <DependencyDetail dependency={references.imageReferences.subTemplates || []} type="subTemplates" />
        </div>
      </div>

      <div className="field">
        <label htmlFor="links">Links</label>
        <div id="links">
          <DependencyDetail dependency={references.imageReferences.links || []} type="links" />
        </div>
      </div>
    </div>
  );
}

export default ReferencesTab;
