import { ActionType } from '../../../common/actionTypes';
import FaMousePointerIcon from '../../../components/Icons/FaMousePointerIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import SubTemplateView from './SubTemplateView';
import SubTemplateAdd from './SubTemplateAdd';
import SubTemplateEdit from './SubTemplateEdit';

type Props = {
  subTemplateId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

function SubTemplateSidebar({ subTemplateId, sidebarVisible, onSidebarClose, action, setAction, refetch }: Props) {
  if (action === ActionType.Add) {
    return (
      <SubTemplateAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (subTemplateId && action === ActionType.Edit) {
    return (
      <SubTemplateEdit
        subTemplateId={subTemplateId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (subTemplateId && action === ActionType.View) {
    return <SubTemplateView id={subTemplateId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No sub-template selected'}
        note={'Select any to see its details'}
      />
    );
  }
}

export default SubTemplateSidebar;
