import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { AppRoute } from '../../../../route/AppRoute';
import FaBinaryIcon from '../../../Icons/FaBinaryIcon';
import FaCodeIcon from '../../../Icons/FaCodeIcon';
import FaFileLinesIcon from '../../../Icons/FaFileLinesIcon';
import FaFilterIcon from '../../../Icons/FaFilterIcon';
import { AvailableObjectType } from './AvailableObjectType';
import FaImageIcon from '../../../Icons/FaImageIcon';
import FaLinkIcon from '../../../Icons/FaLinkIcon';

type Props = {
  selected: string;
  onChange: (value: AvailableObjectType) => void;
  filter: string;
  onFilterChange: (value: string) => void;
  parametersCount: number;
  codeBlocksCount: number;
  subTemplatesCount: number;
  imageCount: number;
  linksCount: number;
  path: AppRoute;
};

export default function TreeItemsHeader(props: Props) {
  const itemTemplate = (option: any) => {
    return (
      <div className="flex gap-2 align-items-center">
        <div>{option.icon}</div>
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <div className="heading">Available objects</div>
      <Dropdown
        id="objects"
        name="objects"
        value={props.selected}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder="Select value"
        className={classNames('objects-dropdown', 'w-full', { 'p-invalid': false })}
        valueTemplate={itemTemplate}
        options={[
          {
            label: `Parameters (${props.parametersCount})`,
            value: AvailableObjectType.Parameter,
            icon: getIconByObjectType(AvailableObjectType.Parameter),
          },
          {
            label: `Snippets (${props.codeBlocksCount})`,
            value: AvailableObjectType.CodeBlock,
            icon: getIconByObjectType(AvailableObjectType.CodeBlock),
            disabled: props.path === AppRoute.LinkEditor,
          },
          {
            label: `Sub-templates (${props.subTemplatesCount})`,
            value: AvailableObjectType.SubTemplate,
            icon: getIconByObjectType(AvailableObjectType.SubTemplate),
            disabled: props.path === AppRoute.SubTemplateEditor || props.path === AppRoute.LinkEditor,
          },
          {
            label: `Images (${props.imageCount})`,
            value: AvailableObjectType.Image,
            icon: getIconByObjectType(AvailableObjectType.Image),
          },
          {
            label: `Links (${props.linksCount})`,
            value: AvailableObjectType.Link,
            icon: getIconByObjectType(AvailableObjectType.Link),
            disabled: props.path === AppRoute.LinkEditor,
          },
        ]}
      />
      <div className="objects-filter">
        <span className="p-input-icon-left w-full">
          <FaFilterIcon />
          <InputText
            id="filter"
            name="filter"
            placeholder="Filter"
            value={props.filter}
            className={classNames('w-full')}
            onChange={(event) => props.onFilterChange(event.target.value)}
          />
        </span>
      </div>
    </>
  );
}

export function getIconByObjectType(objectType: AvailableObjectType) {
  switch (objectType) {
    case AvailableObjectType.Parameter:
      return <FaBinaryIcon />;
    case AvailableObjectType.CodeBlock:
      return <FaCodeIcon />;
    case AvailableObjectType.SubTemplate:
      return <FaFileLinesIcon />;
    case AvailableObjectType.Image:
      return <FaImageIcon />;
    case AvailableObjectType.Link:
      return <FaLinkIcon />;
    default:
      return <></>;
  }
}
