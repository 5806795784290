export type Props = {
  icon: React.ReactNode;
  title: string;
  note?: string;
  action?: React.ReactNode;
};

function NoItemSelected(props: Props) {
  return (
    <div className="no-item-selected">
      {props.icon}
      <div className="title">{props.title}</div>
      {props.note && <div className="note">{props.note}</div>}
      {props.action && <div className="action">{props.action}</div>}
    </div>
  );
}

export default NoItemSelected;
