import { Button } from 'primereact/button';
import { useCallback } from 'react';

import FaExclamationTriangle from '../../components/Icons/FaExclamationTriangle';
import Page from '../../components/Page';
import useAppNavigate from '../../hooks/useAppNavigate';
import { AppRoute } from '../../route/AppRoute';

function NotFound() {
  const navigate = useAppNavigate();
  const gotoHome = useCallback(() => {
    navigate(AppRoute.Home);
  }, [navigate]);

  return (
    <Page className="page-error">
      <FaExclamationTriangle />
      <h1>Page not found...</h1>
      <div className="mb-1">Looks like that you are trying to access something what is not here anymore...</div>
      <div className="mb-5">Please proceed by clicking on button bellow.</div>
      <Button type="button" onClick={gotoHome}>
        Goto home
      </Button>
    </Page>
  );
}

export default NotFound;
