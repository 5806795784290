import { Button } from 'primereact/button';

type Props = {
  title: string;
  onClose?: () => void;
};

function SidePanelHeader(props: Props) {
  return (
    <div className="side-panel-header">
      <div className="title">{props.title}</div>
      <div className="action" onClick={props.onClose}>
        <Button className="p-button-secondary" icon="fa-regular fa-times fa-lg"></Button>
      </div>
    </div>
  );
}

export default SidePanelHeader;
