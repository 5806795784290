import { addCustomYupMethods, ValidationMessage } from '../../../../common/validationUtils';
import * as Yup from 'yup';

addCustomYupMethods();

const basicSchema = (masterTemplateNames: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(256)
      //@ts-ignore
      .uniqueIn(masterTemplateNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
  });

export function useValidationSchema(masterTemplateNames: string[]) {
  return basicSchema(masterTemplateNames);
}
