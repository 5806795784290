import { useEffect } from 'react';
import { AppRoute } from '../route/AppRoute';
import useAppNavigate from './useAppNavigate';
import useIsAuthenticated from './useIsAuthenticated';

function useRedirectToDefaultPage() {
  const navigate = useAppNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(AppRoute.EntryPoints, { replace: true });
    }
  }, [navigate, isAuthenticated]);
}

export default useRedirectToDefaultPage;
