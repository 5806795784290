import PreviewContent from '../../Editor/components/preview/PreviewContent';
import NotificationPreview from '../../Editor/components/preview/preview-types/NotificationPreview';
import SmsPreview from '../../Editor/components/preview/preview-types/SmsPreview';
import EmailPreview from '../../Editor/components/preview/preview-types/EmailPreview';
import React from 'react';

type Props = {
  messageType: string;
  contentTemplatePreview: string | undefined;
  isContentEmpty: boolean | undefined;
  isLoadingPreview: boolean;
  hasTemplatePreviewError: boolean;
};

export default function ContentEditorPreview(props: Props) {
  return (
    <PreviewContent
      messageType={props.messageType}
      isBodyEmpty={props.isContentEmpty}
      isLoading={props.isLoadingPreview}
      hasError={props.hasTemplatePreviewError}
      notificationPreview={<NotificationPreview body={props.contentTemplatePreview} />}
      smsPreview={<SmsPreview body={props.contentTemplatePreview} />}
      emailPreview={<EmailPreview body={props.contentTemplatePreview} />}
    />
  );
}
