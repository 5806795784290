import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';

type Props = {
  value: string;
  onClick: (value: string) => void;
  selected: boolean;
  disabled?: boolean;
  unselectable?: boolean;
  icon?: React.ReactElement;
  children?: React.ReactNode;
};

function SelectButton(props: Props) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <Button
      disabled={props.disabled}
      className={classNames({ active: props.selected })}
      icon={props.icon}
      onClick={() => {
        props.onClick(!selected || !props.unselectable ? props.value : 'defaultValue');
        setSelected(!selected || !props.unselectable);
      }}
    >
      {props.children ? props.children : props.value}
    </Button>
  );
}

export default SelectButton;
