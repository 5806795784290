import { AppRoute, IAppPage } from '../../route/AppRoute';
import Page from '../../components/Page';
import { useParams } from 'react-router-dom';
import { useMasterTemplateQuery, useUpdateMasterTemplateMutation } from '../../api/generated';
import useAppNavigate from '../../hooks/useAppNavigate';
import FetchInProgress from '../../components/FetchInProgress';
import TopBar from '../../components/Editor/components/TopBar';
import FaCrownIcon from '../../components/Icons/FaCrownIcon';
import { useCallback, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import ContentWithHeader from '../../components/ContentWithHeader';
import FaPaletteIcon from '../../components/Icons/FaPaletteIcon';
import HeadingWithIcon from '../../components/HeadingWithIcon';
import SavePanel from '../../components/Editor/components/SavePanel';
import useToast from '../../hooks/useToast';
import MonacoEditor from '../../components/MonacoEditor';
import { ActionType } from '../../common/actionTypes';
import { HtmlPreview } from '../../components/htmlPreview';

function MasterTemplateEditor() {
  const { id } = useParams();
  const { data: masterTemplate, isLoading, isFetching, refetch } = useMasterTemplateQuery({ id: id || '' });
  const [updateMasterTemplate, { isLoading: isSaving }] = useUpdateMasterTemplateMutation();

  const [action, setAction] = useState<ActionType>(ActionType.Edit);
  const navigate = useAppNavigate();
  const toast = useToast();

  if (!id || masterTemplate?.masterTemplateById === null) {
    navigate(AppRoute.MasterTemplates);
  }

  const [htmlBody, setHtmlBody] = useState<string>('');

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);

  const getMasterTemplateIdOrThrow = (id: number | undefined) => {
    if (id) {
      return id;
    }

    throw new Error('Missing masterTemplateId');
  };

  useEffect(() => {
    setHtmlBody(masterTemplate?.masterTemplateById?.htmlBody || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterTemplate?.masterTemplateById?.htmlBody]);

  const handleSave = useCallback(async () => {
    await updateMasterTemplate({
      id: getMasterTemplateIdOrThrow(masterTemplate?.masterTemplateById?.masterTemplateId).toString(),
      input: {
        name: masterTemplate?.masterTemplateById?.name,
        description: masterTemplate?.masterTemplateById?.description,
        htmlBody: htmlBody,
        mjmlBody: masterTemplate?.masterTemplateById?.mjmlBody,
      },
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          setShowSaveSuccessful(false);
        }, 2000);
        setUnsavedChanges(false);
        setShowSaveSuccessful(true);
        refetch();
      })
      .catch(toast.error);
  }, [
    updateMasterTemplate,
    masterTemplate?.masterTemplateById?.masterTemplateId,
    masterTemplate?.masterTemplateById?.name,
    masterTemplate?.masterTemplateById?.description,
    masterTemplate?.masterTemplateById?.mjmlBody,
    htmlBody,
    toast.error,
    refetch,
  ]);

  return isLoading ? (
    <FetchInProgress />
  ) : (
    <Page
      className={classNames('page-master-template-editor content-editor', {
        'html-preview': action === ActionType.View,
      })}
      windowTitle={`Edit ${masterTemplate?.masterTemplateById?.name || '...'}`}
      topBar={
        <TopBar
          previousPath={AppRoute.MasterTemplates}
          title={'Master Template'}
          icon={<FaCrownIcon />}
          contentName={masterTemplate?.masterTemplateById?.name || '...'}
          previewVisible
          isLoading={isLoading}
          setAction={setAction}
        />
      }
    >
      <ContentWithHeader
        header={<HeadingWithIcon label="Content editor" icon={<FaPaletteIcon />} />}
        contentHeader={
          <div className="d-flex">
            <div className="flex-grow-1"></div>
            <SavePanel
              onSave={handleSave}
              isLoading={isFetching}
              isSaving={isSaving}
              unsavedChanges={unsavedChanges}
              saveSuccessful={showSaveSuccessful}
            />
          </div>
        }
      >
        {action === ActionType.View && (
          <div className="split-panel">
            {unsavedChanges && <HtmlPreview title={'Draft'} html={htmlBody} />}
            <HtmlPreview
              title={unsavedChanges ? 'Saved version' : undefined}
              html={masterTemplate?.masterTemplateById?.htmlBody || ''}
            />
          </div>
        )}
        {action === ActionType.Edit && (
          <MonacoEditor
            language="html"
            value={htmlBody}
            setValue={(value) => {
              setHtmlBody(value);
              setUnsavedChanges(true);
            }}
          />
        )}
      </ContentWithHeader>
    </Page>
  );
}

const masterTemplateEditorPage: IAppPage = {
  title: 'Master Template Editor',
  path: () => AppRoute.MasterTemplateEditor,
  page: <MasterTemplateEditor />,
  require: [],
  isHidden: true,
};

export default masterTemplateEditorPage;
