import { Image } from '../../../api/generated';
import FaEmptySetIcon from '../../../components/Icons/FaEmptySetIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import ImageEntry from './ImageEntry';

type Props = {
  images: Image[] | null;
  selectedImageId: number | undefined;
  onSelect: (imageId: number) => void;
  onProperties: (imageId: number) => void;
  onDelete: (imageId: number) => void;
};

function ImageList(props: Props) {
  return (
    <>
      {props.images && props.images.length > 0 ? (
        props.images.map((image) => (
          <ImageEntry
            key={image.imageId}
            image={image as Image}
            isSelected={image.imageId === props.selectedImageId}
            setSelected={() => props.onSelect(image.imageId)}
            onProperties={() => props.onProperties(image.imageId)}
            onDelete={() => props.onDelete(image.imageId)}
          />
        ))
      ) : (
        <NoItemSelected icon={<FaEmptySetIcon />} title={'No images'} note={'Start by adding an image!'} />
      )}
    </>
  );
}

export default ImageList;
