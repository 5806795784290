import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { EntryPoint, useDeleteEntryPointMutation } from '../../api/generated';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import HeaderPanel from '../../components/HeaderPanel';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import EntryPointList from './components/EntryPointList';
import EntryPointSidebar from './components/EntryPointSidebar';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import FaLocationDotIcon from '../../components/Icons/FaLocationDotIcon';
import { useEntryPointsWithStatistics } from './useEntryPointsWithStatistics';
import { InputSwitch } from 'primereact/inputswitch';
import { useShowStatistics } from './useShowStatistics';

function EntryPoints() {
  const [showStatistics, setShowStatistics] = useShowStatistics();
  const { data: entryPoints, isLoading, refetch, error } = useEntryPointsWithStatistics(showStatistics);

  const [selectedEntryPointId, selectEntryPointId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteEntryPoint] = useDeleteEntryPointMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const navigate = useNavigate();
  const toast = useToast();

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const selectEntryPoint = useCallback((entryPointId: number) => {
    selectEntryPointId(entryPointId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addEntryPoint = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editEntryPoint = useCallback((entryPointId: number) => {
    selectEntryPointId(entryPointId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const openEntryPointEditor = useCallback(
    (entryPointId: number) => {
      navigate(generatePath(AppRoute.EntryPointEditor, { id: entryPointId.toString() }));
    },
    [navigate],
  );

  const tryToDeleteEntryPoint = useCallback(
    async (entryPointId: number) => {
      try {
        await deleteEntryPoint({
          id: entryPointId.toString(),
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Entry point '${
                entryPoints.find((entry) => entry?.entryPointId === entryPointId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectEntryPointId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteEntryPoint, entryPoints, refetch, toast],
  );

  const filteredEntryPoints = useMemo(() => {
    if (searchString) {
      return entryPoints.filter((entryPoint) => entryPoint?.name.toLowerCase().includes(searchString.toLowerCase()));
    }
    return entryPoints;
  }, [entryPoints, searchString]);

  return (
    <Page className="page-entry-points" windowTitle={entryPointsPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <EntryPointSidebar
            entrypointId={selectedEntryPointId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectEntryPointId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={entryPointsPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addEntryPoint} />
          <div className="flex gap-2 align-items-center ml-4">
            <InputSwitch
              id="showStatistics"
              name="showStatistics"
              checked={showStatistics}
              onChange={(e) => setShowStatistics(e.value)}
            />
            <span>Show statistics</span>
          </div>
        </HeaderPanel>
        <div className="entry-points-content">
          <EntryPointList
            entryPoints={(filteredEntryPoints as EntryPoint[]) || []}
            selectedEntryPointId={selectedEntryPointId}
            onSelect={selectEntryPoint}
            onEditor={openEntryPointEditor}
            onProperties={editEntryPoint}
            onDelete={tryToDeleteEntryPoint}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const entryPointsPage: IAppPage = {
  title: 'Entry Points',
  icon: <FaLocationDotIcon />,
  path: () => AppRoute.EntryPoints,
  page: <EntryPoints />,
  require: [],
};

export default entryPointsPage;
