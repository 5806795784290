import { SubTemplate } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaFileLinesIcon from '../../../components/Icons/FaFileLinesIcon';

type Props = {
  subTemplate: SubTemplate;
  setSelected: () => void;
  isSelected: boolean;
  onEditor: () => void;
  onProperties: () => void;
  onDelete: () => void;
};

function SubTemplateEntry(props: Props) {
  return (
    <EntryItem
      name={props.subTemplate.name}
      description={props.subTemplate.description}
      deleteTitle="Delete Sub-template"
      deleteMessage={
        <>
          Are you sure you want to delete sub-template <span className="font-bold">{props.subTemplate.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaFileLinesIcon />
            Sub-template
          </div>
        </>
      }
      {...props}
    />
  );
}

export default SubTemplateEntry;
