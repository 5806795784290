import { EntryPoint, useAssignEntryPointParametersMutation, useCreateEntryPointMutation } from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import EntryPointEditForm from './EntryPointEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function EntryPointAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createEntryPoint, { isLoading }] = useCreateEntryPointMutation();
  const [assignEntryPointParameters] = useAssignEntryPointParametersMutation();

  const toast = useToast();

  const defaultEntryPoint: Partial<EntryPoint> = {
    entryPointId: 0,
    name: '',
    description: '',
    queryStringName: '',
    masterTemplateId: 0,
    workflowId: 0,
    entryPointParameters: [],
  };

  return (
    <SidePanel title="Create Entry Point" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <EntryPointEditForm
        isLoading={isLoading}
        entryPoint={defaultEntryPoint as EntryPoint}
        isNew={true}
        onConfirm={async (entryPoint: any) => {
          try {
            await createEntryPoint({
              input: {
                masterTemplateId: entryPoint.masterTemplateId,
                workflowId: entryPoint.workflowId,
                name: entryPoint.name,
                description: entryPoint.description,
                queryStringName: entryPoint.queryStringName,
              },
            })
              .unwrap()
              .then(async (response) => {
                await assignEntryPointParameters({
                  id: response.createEntryPoint?.entryPointId.toString() || '',
                  input: {
                    parameters: entryPoint.parameters.map((param: any) => ({
                      parameterId: param.id as number,
                      isDecision: param.isDecision as boolean,
                    })),
                  },
                })
                  .then(() => {
                    toast.success(`Entry point '${entryPoint.name}' has been created successfully.`);
                    onConfirm();
                  })
                  .catch(toast.error);
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default EntryPointAdd;
