import { useState } from 'react';
import { Link, useLinkQuery } from '../../../../api/generated';
import { LinkViewTab } from './LinksTypes';
import { MenuItem } from 'primereact/menuitem';
import FaLinkIcon from '../../../../components/Icons/FaLinkIcon';
import { TabMenu } from 'primereact/tabmenu';
import Overlay from '../../../../components/Overlay';
import DetailsTab from './DetailsTab';
import ReferencesTab from './ReferencesTab';

type Props = {
  id: number;
};

function LinkView(props: Props) {
  const { data, isFetching } = useLinkQuery({ id: props.id });
  const link = data?.linkById;

  const [activeIndex, setActiveIndex] = useState<number>(LinkViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (!isFetching && link) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaLinkIcon />
          </div>
          <div className="name">{link.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === LinkViewTab.Details && <DetailsTab link={link as Link} />}
          {activeIndex === LinkViewTab.References && <ReferencesTab id={props.id} />}
        </div>
      </div>
    );
  } else {
    return isFetching ? <Overlay label="Loading link" /> : <>Link not found!</>;
  }
}

export default LinkView;
