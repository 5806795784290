function FetchError() {
  return (
    <div className="no-item-selected">
      <div className="title">Unable to fetch data</div>
      <div className="note">Please, refresh the page or try it again later</div>
    </div>
  );
}

export default FetchError;
