import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { classNames } from 'primereact/utils';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useAppNavigate from '../../hooks/useAppNavigate';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { useRoles } from '../../hooks/useRoles';
import { AppRoute } from '../../route/AppRoute';
import AccountMenu from '../AccountMenu';
import { getNavBarItems } from './NavBarItems';
import useLogin from '../../hooks/useLogin';
import { PacketaLogo } from '../PacketaLogo';

type Props = {
  size?: 'small' | 'medium' | 'large' | 'full';
  children?: React.ReactNode;
  hideUserMenu?: boolean;
  navigationItems?: MenuItem[];
  tabItems?: MenuItem[];
};

const defaultProps: Props = {
  size: 'small',
};

function TopBar(props: Props) {
  const { size, children, tabItems } = {
    ...defaultProps,
    ...props,
  };

  const location = useLocation();
  const navigate = useAppNavigate();

  const isAuthenticated = useIsAuthenticated();
  const roles = useRoles();

  const { login } = useLogin();
  const onLogout = useCallback(() => navigate(AppRoute.Logout), [navigate]);

  const navItems: MenuItem[] = useMemo(
    () => props.navigationItems || [...getNavBarItems(isAuthenticated, location.pathname, roles.getRoles(), navigate)],
    [isAuthenticated, location.pathname, navigate, props.navigationItems, roles],
  );

  const authItem: MenuItem[] = [
    {
      label: 'Sign In',
      command: login,
    },
  ];

  return (
    <div
      className={classNames('top-bar', {
        'top-bar-medium': size === 'medium',
        'top-bar-large': size === 'large',
        'top-bar-full': size === 'full',
      })}
    >
      <div className="background-container">
        <div className="mask-left" />
        <div className="mask-right" />
      </div>

      <div className="top-bar-navigation">
        <div className="top-bar-left">
          <PacketaLogo />
          {!isAuthenticated && <span className="app-name">Packeta Channel</span>}
          <Menubar model={navItems} />
        </div>
        <div className="top-bar-right">
          {isAuthenticated ? <AccountMenu onLogout={onLogout} /> : <Menubar model={authItem} />}
        </div>
      </div>

      <div className="top-bar-content">{children}</div>
      {tabItems && <TabMenu model={tabItems} />}
    </div>
  );
}

export default TopBar;
