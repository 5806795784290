import JoditEditor from '../../../components/Editor/components/jodit-editor/JoditEditor';
import { JoditEditorConnector } from '../../../components/Editor/components/jodit-editor/JoditEditorConnector';

type Props = {
  referenceEditorConnector: JoditEditorConnector;
  contentEditorConnector: JoditEditorConnector;
  onEditorFocus: (connector: JoditEditorConnector) => void;
};

export default function LinkEditor(props: Props) {
  return (
    <>
      <div className="mb-3">
        <div className="field">
          <div className="title">Reference</div>
          <div className="mt-2"></div>
          <JoditEditor
            isOneLine
            isWithoutStyles
            withoutToolbar
            placeholder="This link reference is empty and has no value."
            editorConnector={props.referenceEditorConnector}
            onFocus={() => props.onEditorFocus(props.referenceEditorConnector)}
          />
        </div>
      </div>
      <div className="field">
        <div className="title">Content</div>
        <div className="mt-2"></div>
        <JoditEditor
          placeholder="This link content is empty and has no value."
          editorConnector={props.contentEditorConnector}
          onFocus={() => props.onEditorFocus(props.contentEditorConnector)}
          isWithoutStyles
          customActions={[]}
        />
      </div>
    </>
  );
}
