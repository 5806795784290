import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';

type Props = {
  header: React.ReactNode;
  contentHeader?: React.ReactNode;
  children: React.ReactNode;
  hasShowButton?: boolean;
  isInitiallyHidden?: boolean;
  minimalHeight?: number;
};

function ContentWithHeader(props: Props) {
  const [showBody, setShowBody] = useState<boolean>(true);

  useEffect(() => {
    const defaultValue = !!!props.isInitiallyHidden;
    setShowBody(defaultValue);
  }, [props.isInitiallyHidden]);

  return (
    <div className="content-with-header">
      <div className="header">
        <div className="content">{props.header}</div>
        {props.contentHeader}
        {props.hasShowButton && (
          <div className="show-button">
            <Button
              icon={<FontAwesomeIcon icon={showBody ? faEyeSlash : faEye} />}
              onClick={() => setShowBody(!showBody)}
            >
              {showBody ? 'Hide' : 'Show'}
            </Button>
          </div>
        )}
      </div>
      {showBody && (
        <div
          className="body"
          style={{
            minHeight: props.minimalHeight,
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}

export default ContentWithHeader;
