import { NavigateOptions, useNavigate } from 'react-router-dom';

const useAppNavigate = () => {
  const defNavigate = useNavigate();
  return (destination: string, options?: NavigateOptions | undefined) => {
    defNavigate(destination, options);
  };
};

export default useAppNavigate;
