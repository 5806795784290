import { getDataHolderNonDecisionParams } from '../../Editor/editorUtils';
import { CodeBlock, EntryPointParameter, SubTemplate, Image, Link } from '../../../api/generated';
import EditorTreeItems from '../../Editor/components/tree-items/EditorTreeItems';
import React from 'react';
import { SafeContentParameters } from '../../Editor/editorTypes';
import { TreeItemData } from '../../Editor/components/tree-items/TreeItemData';
import { AppRoute } from '../../../route/AppRoute';
import { AvailableObjectType } from '../../Editor/components/tree-items/AvailableObjectType';

type Props = {
  contentParameters: SafeContentParameters[];
  codeBlocks: CodeBlock[];
  subTemplates: SubTemplate[];
  images: Image[];
  links: Link[];
  usedObjects: string[];
  onObjectSelect: (object: TreeItemData) => void;
  path: AppRoute.EntryPointEditor | AppRoute.SnippetEditor | AppRoute.SubTemplateEditor;
  enabledTypes?: AvailableObjectType[];
};

export default function EditorSidebar(props: Props) {
  return (
    <>
      <EditorTreeItems
        parameters={getDataHolderNonDecisionParams(props.contentParameters) as EntryPointParameter[]}
        codeBlocks={props.codeBlocks}
        subTemplates={props.subTemplates}
        usedObjects={props.usedObjects}
        onObjectSelect={props.onObjectSelect}
        path={props.path}
        images={props.images}
        links={props.links}
        enabledTypes={props.enabledTypes}
      />
    </>
  );
}
