import { useCallback, useMemo, useState } from 'react';
import { ApiRole } from '../../api/ApiRole.types';
import HeaderPanel from '../../components/HeaderPanel';
import FaImageIcon from '../../components/Icons/FaImageIcon';
import { Image, useImagesQuery, useDeleteImageMutation } from '../../api/generated';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import { Button } from 'primereact/button';
import ImageList from './components/ImageList';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import ImageSidebar from './components/Sidebar/ImageSidebar';

function Images() {
  const { data: images, isLoading, refetch, error } = useImagesQuery();
  const [selectedImageId, selectImageId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteImage] = useDeleteImageMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const toast = useToast();

  const selectImage = useCallback((imageId: number) => {
    selectImageId(imageId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addImage = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editImage = useCallback((imageId: number) => {
    selectImageId(imageId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const tryToDeleteImage = useCallback(
    async (imageId: number) => {
      try {
        await deleteImage({
          id: imageId,
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Image '${
                images?.allImages?.find((item) => item?.imageId === imageId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectImageId(undefined);
          });
      } catch (error) {
        toast.error('Failed to delete image. Please try again.');
      }
    },
    [deleteImage, images, refetch, selectImageId, toast],
  );

  const filteredImages = useMemo(() => {
    if (searchString) {
      return images?.allImages?.filter((image) => image?.name.toLowerCase().includes(searchString.toLowerCase()));
    }
    return images?.allImages;
  }, [images?.allImages, searchString]);

  return (
    <Page className="page-images" windowTitle={ImagesPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <ImageSidebar
            imageId={selectedImageId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectImageId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={ImagesPage.title} showSearch onSearch={setSearchString}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addImage} />
        </HeaderPanel>
        <div className="images-content">
          <ImageList
            images={(filteredImages as Image[]) || []}
            selectedImageId={undefined}
            onSelect={selectImage}
            onProperties={editImage}
            onDelete={tryToDeleteImage}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const ImagesPage: IAppPage = {
  title: 'Images',
  icon: <FaImageIcon />,
  path: () => AppRoute.Images,
  page: <Images />,
  require: [ApiRole.Admin],
};

export default ImagesPage;
