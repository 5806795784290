import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  EntryPoint,
  useEntryPointDetailQuery,
  useLazyEntryPointCurrentTemplateIdQuery,
  useLazyEntryPointStatisticsDetailQuery,
  useLazyValidateEntryPointQuery,
} from '../../api/generated';

import { AppRoute, IAppPage } from '../../route/AppRoute';
import FetchInProgress from '../../components/FetchInProgress';
import useAppNavigate from '../../hooks/useAppNavigate';
import useToast from '../../hooks/useToast';
import EntryPointEditor from '../../components/EntryPointEditor';
import { useShowStatistics } from '../EntryPoints/useShowStatistics';

function EntryPointEditorPage() {
  const { id } = useParams();
  const [showStatistics] = useShowStatistics();

  const { data: entryPoint, isLoading } = useEntryPointDetailQuery({ id: id || '' });
  const [getEntryPointStatisticsDetail] = useLazyEntryPointStatisticsDetailQuery({});

  const [entryPointCurrentTemplateId] = useLazyEntryPointCurrentTemplateIdQuery({});
  const [validateEntryPoint] = useLazyValidateEntryPointQuery({});
  const navigate = useAppNavigate();
  const toast = useToast();

  const entryPointParameters = useMemo(
    () => entryPoint?.entryPointById?.entryPointParameters ?? [],
    [entryPoint?.entryPointById?.entryPointParameters],
  );
  const [currentTemplateId, setCurrentTemplateId] = useState<number>();

  if (!id || entryPoint?.entryPointById === null) {
    navigate(AppRoute.EntryPoints);
  }

  useEffect(() => {
    if (showStatistics) {
      getEntryPointStatisticsDetail({ id: id || '', input: { parameters: [] } });
    }
  }, [getEntryPointStatisticsDetail, id, showStatistics]);

  useEffect(() => {
    const templateId = async () => {
      await entryPointCurrentTemplateId({ id: id || '' })
        .unwrap()
        .then((result) => setCurrentTemplateId(result.entryPointCurrentTemplateId))
        .catch(toast.error);
    };
    templateId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryPoint]);

  useEffect(() => {
    const validate = async () => {
      await validateEntryPoint({ id: id || '' })
        .then((isValid) => {
          if (!isValid) {
            toast.error(`Validation failed. Please check the used dependencies.`);
          }
        })
        .catch(toast.error);
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return isLoading ? (
    <FetchInProgress />
  ) : (
    <EntryPointEditor
      className="page-entry-point-editor"
      path={AppRoute.EntryPointEditor}
      contentId={entryPoint?.entryPointById?.entryPointId || 0}
      currentTemplateId={currentTemplateId}
      content={entryPoint?.entryPointById as EntryPoint}
      contentParameters={entryPointParameters}
      isLoading={isLoading}
      require={entryPointEditorPage.require}
    />
  );
}

const entryPointEditorPage: IAppPage = {
  title: 'Entry Point Editor',
  path: () => AppRoute.EntryPointEditor,
  page: <EntryPointEditorPage />,
  require: [],
  isHidden: true,
};

export default entryPointEditorPage;
