import { faArrowTurnDownRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import TreeNode from 'primereact/treenode';
import { useCallback, useState } from 'react';
import { TreeItemData } from './TreeItemData';
import { getIconByObjectType } from './TreeItemsHeader';

type Props = {
  node: TreeNode;
  onObjectSelect: (object: TreeItemData) => void;
  showObjectType: boolean;
  isDisabled?: boolean;
};

export default function TreeItem(props: Props) {
  const isParent = props.node.data.isParent;
  const [isHover, setIsHover] = useState(false);

  const node = props.node;

  const handleMouseOver = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleOnObjectSelect = useCallback(() => {
    if (props.isDisabled) return;
    isParent || props.onObjectSelect(node.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParent, props]);

  return (
    <>
      <div
        className={classNames(['parameter', isParent ? 'parent' : 'item'])}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleOnObjectSelect}
      >
        <div className={classNames(['name', isParent ? 'parent' : 'item'])}>
          {node.data.description && (
            <Tooltip target={'.param-' + node.key} mouseTrack mouseTrackLeft={10} content={node.data.description} />
          )}
          <div className="d-flex align-items-center gap-1">
            {props.showObjectType && !props.node.data.isParent && getIconByObjectType(props.node.data.type)}
            <span className={'param-' + node.key}>{node.data.name}</span>
            {node.data.isUsed && <span className="used-variable fa-duotone fa-check-circle"></span>}
          </div>
        </div>
        <div className="action">
          {!isParent && isHover && (
            <Button disabled={props.isDisabled}>
              <FontAwesomeIcon size="1x" icon={faArrowTurnDownRight} />
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
