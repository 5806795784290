import { createContext, PropsWithChildren, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export interface ITokenContext {
  token: string;
  saveToken: (token: string) => void;
  clearToken: () => void;
}

export const TokenContext = createContext<ITokenContext>({
  token: '',
  saveToken: () => {},
  clearToken: () => {},
});

export function TokenProvider(props: PropsWithChildren) {
  const [token, setToken] = useLocalStorage<string>('token', '');

  const providerValue: ITokenContext = {
    token: token,
    saveToken: (token: string) => {
      setToken(token);
    },
    clearToken: () => {
      setToken('');
    },
  };

  return <TokenContext.Provider value={providerValue}>{props.children}</TokenContext.Provider>;
}

export const useTokenContext = () => useContext(TokenContext);
