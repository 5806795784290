import { addCustomYupMethods, ValidationMessage } from '../../../../common/validationUtils';
import * as Yup from 'yup';

addCustomYupMethods();

const basicSchema = (workflowNames: string[]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(256)
      //@ts-ignore
      .uniqueIn(workflowNames)
      .required(ValidationMessage.REQUIRED),
    description: Yup.string().max(256),
  });

export function useValidationSchema(workflowNames: string[]) {
  return basicSchema(workflowNames);
}
