// src/route/AppRoute.ts
import { ApiRole } from '../api/ApiRole.types';
import entryPointsPage from '../pages/EntryPoints';
import parametersPage from '../pages/Parameters';
import snippetsPage from '../pages/Snippets';
import configurationPage from '../pages/Configuration';
import entryPointEditorPage from '../pages/EntryPointEditor';
import snippetEditorPage from '../pages/SnippetEditor';
import subTemplatesPage from '../pages/SubTemplates';
import subTemplateEditorPage from '../pages/SubTemplateEditor';
import workflowsPage from '../pages/Workflows';
import masterTemplatesPage from '../pages/MasterTemplates';
import React from 'react';
import masterTemplateEditorPage from '../pages/MasterTemplateEditor';
import ImagesPage from '../pages/Images';
import LinksPage from '../pages/Links';
import linkEditorPage from '../pages/LinkEditor';

export interface IAppPage {
  title: string;
  icon?: React.ReactNode;
  path: () => AppRoute;
  page: React.ReactNode;
  require?: ApiRole[];
  isHidden?: boolean;
}

export enum AppRoute {
  Home = '/',
  Logout = '/logout',
  Configuration = '/configuration',
  EntryPoints = '/entry-points',
  EntryPointEditor = '/entry-point/:id',
  Parameters = '/parameters',
  Snippets = '/snippets',
  MasterTemplates = '/master-templates',
  MasterTemplateEditor = '/master-template/:id',
  SnippetEditor = '/snippet/:id',
  SubTemplates = '/sub-templates',
  SubTemplateEditor = '/sub-template/:id',
  Workflows = '/workflows',
  Images = '/images',
  Links = '/links',
  LinkEditor = '/link/:id',
}

export const appRoutes: IAppPage[] = [
  entryPointsPage,
  entryPointEditorPage,
  parametersPage,
  snippetsPage,
  snippetEditorPage,
  masterTemplatesPage,
  masterTemplateEditorPage,
  subTemplatesPage,
  subTemplateEditorPage,
  workflowsPage,
  ImagesPage,
  LinksPage,
  linkEditorPage,
  configurationPage,
];
