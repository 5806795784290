import { classNames } from 'primereact/utils';
import { useCallback } from 'react';
import { EntryPointEditorStore, SafeContentParameters } from '../../editorTypes';
import { convertCountryCode } from '../../../../common/countryUtils';
import { useAppSelector } from '../../../../app/store';
import {
  EntryPointParametersStatistics,
  selectParameterStatistics,
} from '../../../../pages/EntryPointEditor/Slices/EntryPointParametersSlice';
import SelectButton from '../../../SelectButton';
import StatisticsSemaphore from '../../../StatisticsSemaphore';

type Props = {
  langs: SafeContentParameters;
  store: EntryPointEditorStore;
  onChange: (id: number, value: string) => void;
  isDisabled?: boolean;
};

function LanguagesSection(props: Props) {
  const updateLanguage = useCallback(
    (lang: string) => {
      props.onChange(1, lang);
    },
    [props],
  );

  return (
    <div className={classNames({ disabled: props.isDisabled })}>
      <SpecificLangs
        lang={props.store.decisions?.get(1) || ''}
        langs={
          props.langs?.parameter?.enumItems
            ?.filter((lang) => !props.store.langsUsingDefault.includes(lang.key))
            .map((lang) => lang.key) || []
        }
        parameterId={props.langs?.parameterId}
        onChange={updateLanguage}
      />
      {props.store.langsUsingDefault.length > 0 && (
        <LangsUsingDefault
          lang={props.store.decisions?.get(1) || ''}
          langs={props.store.langsUsingDefault || []}
          onChange={updateLanguage}
        />
      )}
    </div>
  );
}

function SpecificLangs(props: {
  lang: string;
  langs: string[];
  parameterId?: number;
  onChange: (value: string) => void;
}) {
  const statistics = useAppSelector(selectParameterStatistics(props.parameterId));
  return <Section title="Languages with specific content:" statistics={statistics} disabled={false} {...props} />;
}
function LangsUsingDefault(props: { lang: string; langs: string[]; onChange: (value: string) => void }) {
  return <Section title="Languages using default language content:" disabled={true} {...props} />;
}

function Section({
  title,
  lang,
  langs,
  statistics,
  onChange,
  disabled,
}: {
  title: string;
  lang: string;
  langs: string[];
  statistics?: EntryPointParametersStatistics;
  onChange: (value: string) => void;
  disabled: boolean;
}) {
  return (
    <div className="field">
      <div className="title">{title}</div>
      <div className="options languages-section">
        {langs.map((item) => {
          const languageStatistics = statistics?.values.find((v) => v.value === item);

          return (
            <SelectButton
              key={item}
              value={item}
              onClick={(value: string) => onChange(value)}
              selected={item === lang}
              disabled={disabled}
              unselectable={languageStatistics !== undefined}
            >
              <div className="d-flex gap-2">
                <span className={`fi fi-${convertCountryCode(item)}`} />
                {item.toUpperCase()}
              </div>
              {statistics && !languageStatistics && <Statistics maxContentCount={0} missingContentCount={0} />}
              {languageStatistics && <Statistics {...languageStatistics} />}
            </SelectButton>
          );
        })}
      </div>
    </div>
  );
}

function Statistics({
  maxContentCount,
  missingContentCount,
}: {
  maxContentCount: number;
  missingContentCount: number;
}) {
  return (
    <div className="language-semaphore">
      <StatisticsSemaphore maxContentCount={maxContentCount} missingContentCount={missingContentCount} />
    </div>
  );
}

export default LanguagesSection;
