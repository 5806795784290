import { EntryPoint, useMasterTemplatesQuery, useWorkflowsQuery } from '../../../../api/generated';
import ValueOrPlaceholder from '../../../../components/ValueOrPlaceholder';

type Props = {
  entryPoint: EntryPoint;
};

function DetailsTab(props: Props) {
  const { data: masterTemplates } = useMasterTemplatesQuery();
  const { data: workflows } = useWorkflowsQuery();

  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.entryPoint.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.entryPoint.description} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="queryStringName">Technical name</label>
          <div>
            <ValueOrPlaceholder value={props.entryPoint.queryStringName} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="masterTemplate">Master template</label>
          <div>
            <ValueOrPlaceholder
              value={
                masterTemplates?.allMasterTemplates?.find(
                  (template) => template?.masterTemplateId === props.entryPoint.masterTemplateId,
                )?.name
              }
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="workflow">Workflow</label>
          <div>
            <ValueOrPlaceholder
              value={
                workflows?.allWorkflows?.find((workflow) => workflow?.workflowId === props.entryPoint.workflowId)?.name
              }
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="parameters">Selected parameters</label>
          <div>
            {props.entryPoint.entryPointParameters ? (
              props.entryPoint.entryPointParameters.map((eP, index) => {
                return <div key={`parameter-${index}`}>{eP?.parameter.name}</div>;
              })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
        <div className="field">
          <label htmlFor="decisions">Decisions</label>
          <div>
            {props.entryPoint.entryPointParameters ? (
              props.entryPoint.entryPointParameters
                .filter((eP) => eP?.isDecision || eP?.parameter.name.startsWith('Sys'))
                .map((eP, index) => {
                  return <div key={`decision-${index}`}>{eP?.parameter.name}</div>;
                })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsTab;
