import { Parameter, ParameterUpdateInput, useParameterQuery, useUpdateParameterMutation } from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import ParameterEditForm from './ParameterEditForm';

type Props = {
  parameterId: number;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function ParameterEdit({ parameterId, sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const { data: parameter, isLoading } = useParameterQuery({ id: parameterId.toString() });
  const [updateParameter] = useUpdateParameterMutation();

  const toast = useToast();

  return (
    <SidePanel title="Properties" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <ParameterEditForm
        parameter={parameter?.parameterById as Parameter}
        isNew={false}
        isLoading={isLoading}
        onConfirm={async (parameter: Parameter) => {
          await updateParameter({
            id: parameterId.toString(),
            input: {
              name: parameter.name,
              description: parameter.description,
              sampleValue: parameter.sampleValue,
              enumItems: parameter.enumItems?.map((item) => ({ key: item.key, value: item.value })),
              queryStringDataTypeId: parameter?.queryStringDataType?.name,
              queryStringName: parameter?.queryStringName,
              sysDataTypeId: parameter?.sysDataType?.name,
              sysParameterTypeId: parameter.sysParameterTypeId,
              sysCategory: parameter.sysCategory,
              anonymize: parameter.anonymize,
              mandatory: parameter.mandatory,
            } as ParameterUpdateInput,
          })
            .unwrap()
            .then(() => {
              toast.success(`Parameter '${parameter.name}' has been updated successfully.`);
              onConfirm();
            })
            .catch(toast.error);
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default ParameterEdit;
