import { useCallback } from 'react';
import {
  useLazyValidateCodeBlockInsertToContentQuery,
  useLazyValidateLinkInsertToContentQuery,
  useLazyValidateSubTemplateInsertToContentQuery,
} from '../api/generated';
import { TreeItemData } from '../components/Editor/components/tree-items/TreeItemData';
import useToast from './useToast';
import { AvailableObjectType } from '../components/Editor/components/tree-items/AvailableObjectType';

export default function useValidateObjectInsert() {
  const toast = useToast();

  const [validateCodeBlockInsert] = useLazyValidateCodeBlockInsertToContentQuery({});
  const [validateSubTemplateInsert] = useLazyValidateSubTemplateInsertToContentQuery({});
  const [validateLinkInsert] = useLazyValidateLinkInsertToContentQuery({});

  const validateObjectInsert = useCallback(
    async (object: TreeItemData, contentId: number) => {
      switch (object.type) {
        case AvailableObjectType.CodeBlock:
          await validateCodeBlockInsert({ contentId: contentId || 0, id: object.id.toString() })
            .unwrap()
            .then((result) => {
              if (!result.validateCodeBlockInsertToContent) {
                toast.error(`Validation failed. Please check the CodeBlock ${object.name} references.`);
              }
            })
            .catch(toast.error);
          break;
        case AvailableObjectType.SubTemplate:
          await validateSubTemplateInsert({ contentId: contentId || 0, id: object.id.toString() })
            .unwrap()
            .then((result) => {
              if (result.validateSubTemplateInsertToContent) {
                toast.error(
                  `Validation failed. Please check the Sub-template ${object.name} references. ${result.validateSubTemplateInsertToContent}`,
                );
              }
            })
            .catch(toast.error);
          break;
        case AvailableObjectType.Link:
          await validateLinkInsert({ contentId: contentId || 0, id: object.id })
            .unwrap()
            .then((result) => {
              if (result.validateLinkInsertToContent) {
                toast.error(
                  `Validation failed. Please check the Link ${object.name} references. ${result.validateLinkInsertToContent}`,
                );
              }
            })
            .catch(toast.error);
          break;
        default:
      }
    },
    [toast, validateCodeBlockInsert, validateLinkInsert, validateSubTemplateInsert],
  );

  return validateObjectInsert;
}
