import { ConfirmDialog } from 'primereact/confirmdialog';

type Props = {
  accept?: () => void;
  acceptLabel: string;
  reject?: () => void;
  message: string;
  visible: boolean;
};

export default function UnsavedChangesDialog(props: Props) {
  const accept = () => {
    props.accept && props.accept();
    props.reject && props.reject();
  };

  const reject = () => {
    props.reject && props.reject();
  };

  return (
    <ConfirmDialog
      message={props.message}
      header="Unsaved changes"
      accept={accept}
      reject={reject}
      onHide={reject}
      visible={props.visible}
      acceptLabel={props.acceptLabel}
      rejectLabel="Cancel"
    />
  );
}
