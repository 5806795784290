import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { useState } from 'react';

import FaBinaryIcon from '../../../components/Icons/FaBinaryIcon';
import { Parameter, useParameterQuery } from '../../../api/generated';
import Overlay from '../../../components/Overlay';
import { ParameterViewTab } from './ParametersTypes';
import DetailsTab from './DetailsTab';
import ReferencesTab from './ReferencesTab';

type Props = {
  id: number;
};

function ParameterView(props: Props) {
  const { data, isLoading } = useParameterQuery({ id: props.id.toString() });
  const parameter = data?.parameterById;

  const [activeIndex, setActiveIndex] = useState<number>(ParameterViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (parameter) {
    return (
      <div className="parameter-view">
        <div className="header">
          <div className="name">
            <div className="icon-only">
              <FaBinaryIcon />
            </div>
            <span>{parameter.name}</span>
          </div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === ParameterViewTab.Details && <DetailsTab parameter={parameter as Parameter} />}
          {activeIndex === ParameterViewTab.References && <ReferencesTab id={props.id.toString()} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading parameter" /> : <>Parameter not found!</>;
  }
}

export default ParameterView;
