import { useEffect } from 'react';
import { ActionType } from '../../../common/actionTypes';

export default function useFetchContentPreview(action: ActionType, dispatch: () => void) {
  /** Fetch template preview */
  useEffect(() => {
    dispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
}
