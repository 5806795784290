import React from 'react';

type Props = {
  subject?: string;
  body?: string;
};

export default function EmailWithSubjectPreview(props: Props) {
  return (
    <div className="email-preview">
      <div className="mb-3">
        <div className="title">Subject</div>
        <div className="mt-2"></div>
        <div className="text-border" dangerouslySetInnerHTML={{ __html: props.subject || '' }}></div>
      </div>
      <div className="title">Body</div>
      <div className="mt-2"></div>
      <div className="text-border">
        <div className="email-body" dangerouslySetInnerHTML={{ __html: props.body || '' }}></div>
      </div>
    </div>
  );
}
