function FaCrownIcon() {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.75 2C17.0312 2 16.5 2.5625 16.5 3.25C16.5 3.53125 16.5938 3.78125 16.75 4L13.9375 6.25C13.75 6.40625 13.5312 6.46875 13.3125 6.46875C12.9688 6.46875 12.5938 6.25 12.4375 5.90625L10.625 2.3125C11 2.09375 11.2188 1.71875 11.2188 1.25C11.2188 0.5625 10.6875 0 10 0C9.28125 0 8.71875 0.5625 8.71875 1.25C8.71875 1.71875 8.96875 2.09375 9.3125 2.3125L7.53125 5.90625C7.375 6.25 7 6.46875 6.65625 6.46875C6.4375 6.46875 6.21875 6.40625 6.03125 6.25L3.21875 4C3.375 3.78125 3.46875 3.53125 3.46875 3.25C3.46875 2.5625 2.90625 2 2.21875 2C1.53125 2 0.96875 2.5625 0.96875 3.25C0.96875 3.96875 1.53125 4.5 2.21875 4.5L3.8125 13.1875C3.90625 13.6562 4.34375 14 4.8125 14H15.1562C15.625 14 16.0625 13.6562 16.125 13.1875L17.7188 4.5C17.7188 4.5 17.7188 4.5 17.75 4.5C18.4375 4.5 19 3.96875 19 3.25C19 2.5625 18.4375 2 17.75 2ZM14.7188 12.5H5.25L4.1875 6.6875L5.09375 7.40625C5.53125 7.78125 6.09375 7.96875 6.65625 7.96875C7.59375 7.96875 8.46875 7.4375 8.875 6.59375L10 4.375L11.0938 6.59375C11.5312 7.4375 12.375 7.96875 13.3125 7.96875C13.9062 7.96875 14.4375 7.78125 14.875 7.40625L15.7812 6.6875L14.7188 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaCrownIcon;
