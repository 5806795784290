import { CodeBlock, useAssignCodeBlockParametersMutation, useCreateCodeBlockMutation } from '../../../api/generated';
import SidePanel from '../../../components/SidePanel';
import useToast from '../../../hooks/useToast';
import SnippetEditForm from './SnippetEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function SnippetAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createCodeBlock, { isLoading }] = useCreateCodeBlockMutation();
  const [assignCodeBlockParameters] = useAssignCodeBlockParametersMutation();

  const toast = useToast();

  const defaultCodeBlock: Partial<CodeBlock> = {
    codeBlockId: 0,
    name: '',
    description: '',
    codeBlockParameters: [],
  };

  return (
    <SidePanel title="Create Snippet" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <SnippetEditForm
        isLoading={isLoading}
        codeBlock={defaultCodeBlock as CodeBlock}
        isNew={true}
        onConfirm={async (codeBlock: any) => {
          try {
            await createCodeBlock({
              input: {
                name: codeBlock.name,
                description: codeBlock.description,
              },
            })
              .unwrap()
              .then(async (response) => {
                await assignCodeBlockParameters({
                  id: response.createCodeBlock?.codeBlockId.toString() || '',
                  input: {
                    parameters: codeBlock.parameters.map((param: any) => ({
                      parameterId: param.id as number,
                    })),
                  },
                })
                  .then(() => {
                    toast.success(`Snippet '${codeBlock.name}' has been created successfully.`);
                    onConfirm();
                  })
                  .catch(toast.error);
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default SnippetAdd;
