function FaEmptySetIcon() {
  return (
    <svg width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M91.5625 1.9375C89.7656 0.140625 87.0703 0.140625 85.4531 1.9375L71.9766 15.2344C65.3281 9.66406 56.5234 6.25 47 6.25C24.7188 6.25 6.75 24.3984 6.75 46.5C6.75 56.2031 9.98438 64.8281 15.5547 71.8359L2.25781 85.1328C0.460938 86.9297 0.460938 89.625 2.25781 91.2422C2.97656 92.1406 4.05469 92.5 5.3125 92.5C6.39062 92.5 7.46875 92.1406 8.1875 91.2422L21.6641 77.9453C28.4922 83.5156 37.2969 86.75 47 86.75C69.1016 86.75 87.25 68.7812 87.25 46.5C87.25 36.9766 83.8359 28.1719 78.2656 21.3438L91.5625 8.04688C93.3594 6.25 93.3594 3.55469 91.5625 1.9375ZM15.375 46.5C15.375 29.0703 29.3906 14.875 47 14.875C54.0078 14.875 60.6562 17.3906 66.0469 21.3438L21.6641 65.7266C17.7109 60.3359 15.375 53.6875 15.375 46.5ZM78.625 46.5C78.625 64.1094 64.4297 78.125 47 78.125C39.8125 78.125 33.1641 75.7891 27.7734 71.8359L72.1562 27.4531C76.1094 32.8438 78.625 39.4922 78.625 46.5Z"
        fill="#CFD2D6"
      />
    </svg>
  );
}

export default FaEmptySetIcon;
