import { Jodit } from 'jodit-react';
import { VariablePlugin } from './plugins/placeholderPlugin';
import { useCallback, useRef, useState } from 'react';
import { AvailableObjectType } from '../tree-items/AvailableObjectType';
import { IReplacePlugin } from './plugins/replacePluginBase';
import { ContentPlugin } from './plugins/contentPlugin';

const replacePlugins: IReplacePlugin[] = [new VariablePlugin(), new ContentPlugin()];

export type Props = {
  onChange?: () => void;
  enabledVariableTypes?: AvailableObjectType[];
};

export type JoditEditorConnector = {
  getEditor: () => Jodit | null;
  setEditor: (editor: Jodit) => void;
  setValue: (value: string | null) => void;
  setEditorValue: (value: string | null) => void;
  getValue: () => string;
  getEditorValue: () => string | null;
  dataRevisionIndex: number;
  insertVariable: (value: string) => boolean;
  getVariables: () => string[];
  getEnabledVariableTypes?: () => AvailableObjectType[] | undefined;
};

export function useJoditEditorConnector(props: Props): JoditEditorConnector {
  const editorRef = useRef<Jodit | null>(null);
  const _value = useRef<string | null>(null);
  const [dataRevisionIndex, _setDataRevisionIndex] = useState(0);

  const getEditor = useCallback((): Jodit | null => editorRef.current, []);

  const setEditor = useCallback((editor: Jodit): void => {
    editorRef.current = editor;
  }, []);

  const setValue = useCallback((value: string | null): void => {
    _value.current = replacePlugins.reduce((acc, plugin) => plugin.htmlFromValue(acc), value || '');
    _setDataRevisionIndex((prev) => prev + 1);
  }, []);

  // This avoid re-rendering the editor when the value is set without changing the revision index
  // (we lost cursor position when the editor re-renders)
  const setEditorValue = useCallback(
    (value: string | null): void => {
      _value.current = value;
      props.onChange?.();
    },
    [props],
  );

  const getEditorValue = useCallback((): string => {
    return _value.current || '';
  }, []);

  const getValue = useCallback((): string => {
    return replacePlugins.reduce((acc, plugin) => plugin.valueFromHtml(acc), _value.current || '');
  }, []);

  const insertVariable = useCallback((value: string): boolean => {
    const editor = editorRef.current;
    if (!editor) {
      return false;
    }

    const variablePlugin = replacePlugins.find((plugin) => plugin instanceof VariablePlugin);
    const variableHTML = (variablePlugin as VariablePlugin | undefined)?.createVariableHTMLFromValue(value);
    if (!variableHTML) {
      return false;
    }
    editor.selection.insertHTML(variableHTML, true);
    return true;
  }, []);

  const getVariables = useCallback((): string[] => {
    const editor = editorRef.current;
    if (!editor) {
      return [];
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = getEditorValue();
    const variables = tempDiv.querySelectorAll('span.jodit-variable');
    return Array.from(variables).map((variable) => variable.getAttribute('data-value') || '');
  }, [getEditorValue]);

  const getEnabledVariableTypes = useCallback((): AvailableObjectType[] | undefined => {
    return props.enabledVariableTypes || undefined;
  }, [props.enabledVariableTypes]);

  return {
    getEditor,
    setEditor,
    setValue,
    setEditorValue,
    getValue,
    getEditorValue,
    dataRevisionIndex,
    insertVariable,
    getVariables,
    getEnabledVariableTypes,
  };
}
