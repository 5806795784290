import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import React, { useCallback } from 'react';
import { AppRoute } from '../../../route/AppRoute';
import { ActionType } from '../../../common/actionTypes';
import useAppNavigate from '../../../hooks/useAppNavigate';
import FaEditIcon from '../../Icons/FaEditIcon';
import FaEyeIcon from '../../Icons/FaEyeIcon';
import { PacketaLogo } from '../../PacketaLogo';

type Props = {
  previousPath: AppRoute;
  title: string;
  icon: React.ReactElement;
  contentName: string;
  previewVisible: boolean;
  isLoading: boolean;
  setAction?: (actionType: ActionType) => void;
  actionButton?: React.ReactElement;
  leftActions?: React.ReactElement;
  customActions?: MenuItem[];
  activeIndex?: number;
};

export default function TopBar(props: Props) {
  const navigate = useAppNavigate();
  const onBackToInventory = useCallback(() => {
    navigate(props.previousPath, { replace: true });
  }, [navigate, props.previousPath]);

  const tabItems: MenuItem[] = [
    {
      label: 'Editor',
      icon: <FaEditIcon />,
      command: () => props.setAction && props.setAction(ActionType.Edit),
    },
    {
      label: 'Preview',
      icon: <FaEyeIcon />,
      command: () => props.setAction && props.setAction(ActionType.View),
      visible: props.previewVisible,
    },
    ...(props.customActions || []),
  ];

  return (
    <div className="top-bar">
      <div className="logo-with-action">
        <PacketaLogo />
        <Button type="button" icon="fa-regular fa-arrow-left" onClick={onBackToInventory}>
          Back
        </Button>
      </div>
      {!props.isLoading && (
        <>
          <div className="header">
            <div className="entry-point">
              <div className="type">
                {
                  <>
                    {props.icon} {props.title}
                  </>
                }
              </div>
              <div className="name">{props.contentName}</div>
            </div>
            <div className="flex gap-4 ml-5">{props.leftActions}</div>
            <div className="actions">
              {props.activeIndex !== undefined ? (
                <TabMenu model={tabItems} activeIndex={props.activeIndex} onTabChange={() => undefined} />
              ) : (
                <TabMenu model={tabItems} />
              )}
            </div>
            {props.actionButton && <div className="actions-right">{props.actionButton}</div>}
          </div>
        </>
      )}
    </div>
  );
}
