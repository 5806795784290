import { MasterTemplate, Workflow } from '../../../../api/generated';
import { FormikField } from '../../../../components/FormikField';
import DropdownField from '../../../../components/formik-custom-fields/DropdownField/DropdownField';

type BasicInfoTabContentProps = {
  masterTemplate: number | undefined;
  setMasterTemplate: (masterTemplate: number | undefined) => void;
  workflow: number | undefined;
  setWorkflow: (workflow: number | undefined) => void;
  allWorkflows: Workflow[];
  allMasterTemplates: MasterTemplate[];
};

export default function BasicInfoTab(props: BasicInfoTabContentProps) {
  return (
    <>
      <FormikField label="Name" type="text" name="name" />
      <FormikField label="Description" type="textarea" name="description" />
      <FormikField label="Technical name" type="text" name="queryStringName" />
      <DropdownField
        name="masterTemplateId"
        label="Master template"
        placeholder="Select value"
        options={props.allMasterTemplates?.map((template) => {
          return { value: template?.masterTemplateId, label: template?.name };
        })}
      />
      <DropdownField
        name="workflowId"
        label="Workflow"
        placeholder="Select value"
        options={props.allWorkflows?.map((workflow) => {
          return { value: workflow?.workflowId, label: workflow?.name };
        })}
      />
    </>
  );
}
