import { FormikField } from '../../../../../components/FormikField';

function BasicInfoTab() {
  return (
    <>
      <FormikField label="Name" type="text" name="name" />
      <FormikField label="Description" type="textarea" name="description" />
      <FormikField label="Alternate text" type="text" name="alternateText" />
      <FormikField label="Width" type="number" name="width" />
      <FormikField label="Height" type="number" name="height" />
      <FormikField
        label="Image"
        type="image"
        name="source"
        nameMimeType="mimeType"
        nameHeight="height"
        nameWidth="width"
      />
    </>
  );
}

export default BasicInfoTab;
