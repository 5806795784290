import Page from '../../components/Page';
import { PacketaLogoLarge } from '../../components/Icons/PacketaLogoLarge';
import useRedirectToDefaultPage from '../../hooks/useRedirectToDefaultPage';

function Home() {
  useRedirectToDefaultPage();

  return (
    <Page className="page-home">
      <PacketaLogoLarge />
    </Page>
  );
}

export default Home;
