import Overlay from '../Overlay';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
};

function MainLayout(props: Props) {
  return (
    <div className="main-layout">
      <div className="main-content">
        {props.isLoading ? <Overlay isFullscreen label="Loading data..." /> : props.children}
      </div>
    </div>
  );
}

export default MainLayout;
