import useAppNavigate from '../hooks/useAppNavigate';
import { AppRoute } from '../route/AppRoute';
import { PacketaLogoSmall } from './Icons/PacketaLogoSmall';

export function PacketaLogo() {
  const navigate = useAppNavigate();
  return (
    <div className="cursor-pointer flex justify-center items-center" onClick={() => navigate(AppRoute.Home)}>
      <PacketaLogoSmall />
    </div>
  );
}
