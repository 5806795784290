import { Image, useCreateImageMutation } from '../../../../api/generated';
import SidePanel from '../../../../components/SidePanel';
import useToast from '../../../../hooks/useToast';
import ImageEditForm from './ImageEditForm';

type Props = {
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  onConfirm: () => void;
};

function ImageAdd({ sidebarVisible, onSidebarClose, onConfirm }: Props): JSX.Element | null {
  const [createImage, { isLoading }] = useCreateImageMutation();

  const toast = useToast();

  const defaultImage: Partial<Image> = {
    imageId: 0,
    name: '',
    description: '',
    alternateText: '',
    source: '',
  };

  return (
    <SidePanel title="Create Image" onClose={onSidebarClose} visible={sidebarVisible} size="wide">
      <ImageEditForm
        isLoading={isLoading}
        image={defaultImage as Image}
        isNew={true}
        onConfirm={async (image: Image) => {
          try {
            await createImage({
              input: {
                name: image.name,
                description: image.description,
                alternateText: image.alternateText,
                width: image.width,
                height: image.height,
                source: image.source,
              },
            })
              .unwrap()
              .then(async (_response) => {
                toast.success(`Image '${image.name}' has been created successfully.`);
                onConfirm();
              })
              .catch(toast.error);
          } catch (ex) {
            toast.error(ex);
          }
        }}
        onClose={onSidebarClose}
      />
    </SidePanel>
  );
}

export default ImageAdd;
