import React from 'react';
import MobileIcon from '../../../../Icons/MobileIcon';

type Props = {
  body?: string;
};

export default function SmsPreview(props: Props) {
  return (
    <>
      <MobileIcon />
      <div className="sms-preview">
        <div className="sms-bubble" dangerouslySetInnerHTML={{ __html: props.body || '' }}></div>
      </div>
    </>
  );
}
