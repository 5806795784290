import { Link } from '../../../api/generated';
import EntryItem from '../../../components/EntryItem';
import FaLinkIcon from '../../../components/Icons/FaLinkIcon';

type Props = {
  link: Link;
  setSelected: () => void;
  isSelected: boolean;
  onEditor: () => void;
  onProperties: () => void;
  onDelete: () => void;
};

function LinkEntry(props: Props) {
  return (
    <EntryItem
      name={props.link.name}
      description={props.link.description}
      deleteTitle="Delete Link"
      deleteMessage={
        <>
          Are you sure you want to delete link <span className="font-bold">{props.link.name}</span>?
          <br />
          This action can not be undone.
        </>
      }
      tags={
        <>
          <div className="tag">
            <FaLinkIcon />
            Link
          </div>
        </>
      }
      {...props}
    />
  );
}

export default LinkEntry;
