import { useCallback, useMemo, useState } from 'react';
import { ApiRole } from '../../api/ApiRole.types';
import { Link, useDeleteLinkMutation, useLinksQuery } from '../../api/generated';
import FaLinkIcon from '../../components/Icons/FaLinkIcon';
import Page from '../../components/Page';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import { ActionType } from '../../common/actionTypes';
import useToast from '../../hooks/useToast';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import LinkSidebar from './components/Sidebar/LinkSidebar';
import HeaderPanel from '../../components/HeaderPanel';
import { Button } from 'primereact/button';
import LinkList from './components/LinkList';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import { generatePath, useNavigate } from 'react-router-dom';

function Links() {
  const { data: links, isLoading, refetch, error } = useLinksQuery();
  const [selectedLinkId, selectLinkId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteLink] = useDeleteLinkMutation();

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const navigate = useNavigate();
  const toast = useToast();

  const selectLink = useCallback((linkId: number) => {
    selectLinkId(linkId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addLink = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editLink = useCallback((linkId: number) => {
    selectLinkId(linkId);
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const openLinkEditor = useCallback(
    (linkId: number) => {
      navigate(generatePath(AppRoute.LinkEditor, { id: linkId.toString() }));
    },
    [navigate],
  );

  const tryToDeleteLink = useCallback(
    async (linkId: number) => {
      try {
        await deleteLink({
          id: linkId,
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Link '${links?.allLinks?.find((item) => item?.linkId === linkId)?.name}' has been deleted successfully.`,
            );
            refetch();
            selectLinkId(undefined);
          });
      } catch (error) {
        toast.error('Failed to delete link. Please try again.');
      }
    },
    [deleteLink, links, refetch, selectLinkId, toast],
  );

  const filteredLinks = useMemo(() => {
    if (searchString) {
      return links?.allLinks?.filter((link) => link?.name.toLowerCase().includes(searchString.toLowerCase()));
    }
    return links?.allLinks;
  }, [links?.allLinks, searchString]);

  return (
    <Page className="page-links" windowTitle={LinksPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <LinkSidebar
            linkId={selectedLinkId}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectLinkId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={isLoading}
      >
        <HeaderPanel titleText={LinksPage.title} showSearch onSearch={setSearchString}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addLink} />
        </HeaderPanel>
        <div className="links-content">
          <LinkList
            links={(filteredLinks as Link[]) || []}
            selectedLinkId={undefined}
            onSelect={selectLink}
            onEditor={openLinkEditor}
            onProperties={editLink}
            onDelete={tryToDeleteLink}
          />
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const LinksPage: IAppPage = {
  title: 'Links',
  icon: <FaLinkIcon />,
  path: () => AppRoute.Links,
  page: <Links />,
  require: [ApiRole.Admin],
};

export default LinksPage;
