import * as Yup from 'yup';

export enum ValidationMessage {
  REQUIRED = 'Required',
  UNIQUE = 'Name is already used',
  SYSTEM_PARAMETER = 'Parameter name cannot start with the SYS',
  INVALID_QUERY_STRING_NAME = 'The allowed characters are: a-z A-Z 0-9 "." "_" and "-"',
  INVALID_BASE64 = 'Invalid image format',
}

export function addCustomYupMethods() {
  Yup.addMethod(Yup.array, 'unique', function (field: string, message: string) {
    return this.test('unique', message, function (array: any[] = []) {
      const uniqueData = Array.from(new Set(array.map((row) => row[field])));
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex((row, i) => row[field] !== uniqueData[i]);
      if (array[index][field] === '') {
        return true;
      }

      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    });
  });
  Yup.addMethod(Yup.mixed, 'uniqueIn', function (array: string[] = []) {
    return this.test('uniqueIn', ValidationMessage.UNIQUE, function (value = '') {
      if (typeof value === 'string' && array.map((v) => v.toLowerCase()).includes(value.toLowerCase())) {
        return false;
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, 'nonSystemParameter', function () {
    return this.test('nonSystemParameter', ValidationMessage.SYSTEM_PARAMETER, function (value) {
      if (value?.toLowerCase().startsWith('sys')) {
        return false;
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, 'validQueryStringName', function (message: string, _mapper = (a: any) => a) {
    return this.matches(/^[0-9a-zA-Z-._]{1,256}$/, message || ValidationMessage.INVALID_QUERY_STRING_NAME);
  });
  Yup.addMethod(Yup.string, 'base64', function () {
    return this.test('base64', ValidationMessage.INVALID_BASE64, function (value) {
      const base64Regex = /^data:image\/(png|jpg|jpeg);base64,/;
      if (value && base64Regex.test(value)) {
        return true;
      }
      return false;
    });
  });
  Yup.addMethod(Yup.string, 'phone', function (forcedInternational = false) {
    let invalidMessage = 'Invalid phone number';
    if (forcedInternational) {
      invalidMessage += '. The number must be in the international format, starting with "+".';
    }
    return this.test('phone', invalidMessage, function (value) {
      const phoneRegExp = forcedInternational ? /^\+\d{1,3}\d{9,15}$/ : /^(\+\d{1,3}[- ]?)?\d{9,15}$/;
      if (value && phoneRegExp.test(value)) {
        return true;
      }
      return false;
    });
  });
}
