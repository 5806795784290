import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { useState } from 'react';

import FaLocationDotIcon from '../../../components/Icons/FaLocationDotIcon';
import { EntryPoint, useEntryPointQuery } from '../../../api/generated';
import { EntrypointViewTab } from './EntryPointsTypes';
import Overlay from '../../../components/Overlay';
import IssuesTab from './IssuesTab';
import DetailsTab from './DetailsTab';

type Props = {
  id: number;
};

function EntryPointView(props: Props) {
  const { data, isLoading } = useEntryPointQuery({ id: props.id.toString() });
  const entryPoint = data?.entryPointById;

  const [activeIndex, setActiveIndex] = useState<number>(EntrypointViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Issues' }, { label: 'Details' }];

  if (entryPoint) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaLocationDotIcon />
          </div>
          <div className="name">{entryPoint.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === EntrypointViewTab.Issues && <IssuesTab />}
          {activeIndex === EntrypointViewTab.Details && <DetailsTab entryPoint={entryPoint as EntryPoint} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading entry point" /> : <>Entry Point not found!</>;
  }
}

export default EntryPointView;
