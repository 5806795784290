import { RequestError } from '../api/graphqlBaseQueryTypes';
import { useToastContext } from '../providers/ToastProvider';

function useToast() {
  const mode = process.env.REACT_APP_MODE as string;
  const toast = useToastContext();

  const success = (message: string) => {
    toast.show({
      severity: 'success',
      detail: message,
      life: 4000,
    });
  };
  const error = (error: unknown) => {
    let message = typeof error === 'string' ? error : 'Operation failed. Please, try it later.';

    if (mode.toLowerCase() !== 'production') {
      console.error(error);
    }

    const err = error as RequestError;
    if (err.statusCode === 200 && err.errors.length > 0) {
      message = err.errors[0].message;
    }

    toast.show({
      severity: 'error',
      detail: message,
      life: 4000,
    });
  };

  return { success, error };
}

export default useToast;
