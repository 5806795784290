import { CodeBlock } from '../../../../api/generated';
import ValueOrPlaceholder from '../../../../components/ValueOrPlaceholder';

type Props = {
  codeBlock: CodeBlock;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.codeBlock.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.codeBlock.description} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="parameters">Selected parameters</label>
          <div>
            {props.codeBlock.codeBlockParameters ? (
              props.codeBlock.codeBlockParameters.map((eP, index) => {
                return <div key={`parameter-${index}`}>{eP?.parameter.name}</div>;
              })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsTab;
