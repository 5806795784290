export const PacketaLogoLarge = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="150.000000pt"
      height="127.000000pt"
      viewBox="0 0 300.000000 254.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,254.000000) scale(0.100000,-0.100000)" fill="#ba1b02" stroke="none">
        <path
          d="M1210 2416 c-146 -56 -274 -106 -285 -110 -18 -7 -17 -10 19 -41 l38
  -34 47 18 c25 10 142 56 259 101 l212 82 213 -82 c116 -45 233 -91 258 -101
  l47 -18 38 34 39 33 -30 12 c-16 6 -146 56 -287 111 -141 54 -267 99 -280 99
  -13 -1 -142 -47 -288 -104z"
        />
        <path
          d="M1426 2353 l-69 -27 29 -13 c16 -8 119 -53 229 -101 l200 -88 77 35
  c42 20 74 39 70 42 -7 6 -459 180 -464 178 -2 0 -34 -12 -72 -26z"
        />
        <path
          d="M1137 2241 c-53 -20 -98 -39 -101 -42 -3 -3 97 -56 223 -118 170 -85
  235 -112 252 -108 13 4 57 24 98 46 l75 39 -155 77 c-85 43 -186 92 -225 110
  l-71 32 -96 -36z"
        />
        <path
          d="M880 2226 c0 -47 69 -576 77 -590 4 -7 121 -115 258 -239 269 -242
  261 -238 264 -150 1 29 -18 48 -224 233 -124 111 -225 204 -225 206 0 16 -61
  511 -64 520 -2 5 -22 17 -45 26 -41 16 -41 16 -41 -6z"
        />
        <path
          d="M2073 2229 c-24 -10 -43 -22 -43 -26 0 -4 -14 -123 -31 -265 l-32
  -256 -228 -204 -228 -203 6 -40 c3 -22 9 -44 14 -49 7 -8 494 420 512 451 10
  16 79 559 75 589 -3 21 -3 21 -45 3z"
        />
        <path
          d="M1030 2163 c0 -10 11 -110 25 -223 14 -113 25 -210 25 -216 0 -9 291
  -277 394 -363 18 -16 18 -9 11 259 -4 151 -11 282 -15 290 -8 14 -419 270
  -434 270 -3 0 -6 -8 -6 -17z"
        />
        <path
          d="M1894 2143 c-58 -34 -62 -39 -68 -81 -3 -25 -6 -70 -7 -101 -1 -109
  -10 -137 -54 -168 -22 -16 -46 -33 -54 -37 -11 -7 -12 15 -10 128 2 75 2 136
  -1 136 -18 0 -170 -103 -174 -118 -2 -9 -8 -138 -12 -287 -6 -263 -6 -270 12
  -254 102 85 394 354 394 363 0 6 11 103 25 216 27 227 28 240 18 240 -5 -1
  -36 -17 -69 -37z"
        />
        <path
          d="M20 380 l0 -350 55 0 55 0 0 125 0 125 117 0 c98 0 124 3 160 21 161
  78 164 320 4 402 -53 26 -59 27 -223 27 l-168 0 0 -350z m361 204 c57 -58 47
  -154 -21 -189 -20 -10 -59 -15 -130 -15 l-100 0 0 121 0 121 110 -4 c107 -3
  110 -4 141 -34z"
        />
        <path
          d="M1470 380 l0 -350 50 0 50 0 0 88 c0 74 3 92 18 106 18 16 23 11 98
  -88 l79 -105 63 -1 63 0 -93 121 c-51 66 -99 128 -106 138 -11 17 -2 31 88
  129 55 61 100 113 100 116 0 3 -29 6 -64 6 l-64 0 -88 -102 -89 -101 -3 196
  -2 197 -50 0 -50 0 0 -350z"
        />
        <path
          d="M2340 413 c0 -249 1 -261 23 -303 27 -54 68 -80 142 -87 l55 -6 0 52
  c0 50 0 51 -30 51 -19 0 -40 9 -55 25 -24 23 -25 29 -25 165 l0 140 50 0 50 0
  0 45 0 45 -50 0 c-55 0 -57 3 -62 85 l-3 40 -47 3 -48 3 0 -258z"
        />
        <path
          d="M1104 535 c-77 -38 -94 -77 -94 -207 0 -146 13 -204 53 -246 81 -84
  262 -75 325 15 35 52 34 59 -12 77 -54 21 -53 21 -80 -10 -32 -38 -109 -45
  -148 -14 -28 21 -28 23 -28 140 0 116 1 119 26 139 42 33 108 29 145 -9 l30
  -29 42 16 c23 9 43 17 45 19 7 5 -27 60 -50 81 -62 58 -170 70 -254 28z"
        />
        <path
          d="M645 535 c-16 -7 -41 -23 -55 -34 l-25 -20 30 -31 31 -30 39 20 c46
  24 83 25 130 6 38 -16 55 -42 55 -84 l0 -29 -87 4 c-78 4 -92 2 -127 -19 -61
  -36 -81 -70 -81 -138 0 -70 21 -105 81 -135 47 -24 179 -27 262 -6 l52 13 0
  193 c0 183 -1 194 -23 226 -34 52 -97 79 -182 79 -38 -1 -83 -7 -100 -15z
  m168 -286 l37 -10 0 -55 c0 -50 -2 -55 -27 -65 -39 -15 -119 -6 -143 16 -36
  33 -24 90 24 111 38 16 60 17 109 3z"
        />
        <path
          d="M1996 536 c-51 -19 -94 -62 -106 -105 -13 -47 -13 -246 0 -293 30
  -108 210 -155 313 -81 22 15 46 37 53 48 13 19 10 23 -30 44 l-44 24 -27 -21
  c-62 -49 -165 -16 -165 54 l0 34 146 0 146 0 -4 103 c-3 117 -19 151 -85 183
  -49 23 -147 28 -197 10z m159 -111 c17 -16 25 -35 25 -60 l0 -35 -95 0 -95 0
  0 39 c0 52 34 81 97 81 33 0 50 -6 68 -25z"
        />
        <path
          d="M2663 529 c-66 -32 -72 -45 -37 -80 l30 -29 39 20 c46 24 83 25 130
  6 38 -16 55 -42 55 -83 l0 -28 -99 0 c-85 0 -103 -3 -128 -21 -103 -76 -97
  -215 12 -269 48 -24 180 -27 263 -6 l53 13 -3 198 c-3 226 -7 236 -88 278 -60
  30 -167 31 -227 1z m197 -284 c6 -3 10 -32 10 -64 0 -52 -2 -59 -22 -65 -54
  -14 -133 0 -150 27 -4 7 -8 27 -8 45 0 47 47 75 113 68 26 -3 52 -8 57 -11z"
        />
      </g>
    </svg>
  );
};
