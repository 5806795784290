import { classNames } from 'primereact/utils';

type HtmlPreviewProps = {
  title?: string;
  html: string;
};

export function HtmlPreview({ title, html }: HtmlPreviewProps) {
  return (
    <div className="editor-preview">
      {title && <div className="preview-title">{title}</div>}
      <div
        className={classNames('preview-content', {
          'with-title': title,
        })}
      >
        <iframe
          title="html-preview"
          srcDoc={html}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
          }}
        />
      </div>
    </div>
  );
}
