import { FormikField } from '../../../../../components/FormikField';

export default function BasicInfoTab() {
  return (
    <>
      <FormikField label="Name" type="text" name="name" />
      <FormikField label="Description" type="textarea" name="description" />
    </>
  );
}
