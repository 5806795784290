function FaBinaryIcon() {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.25 6.5H11.25C11.6562 6.5 12 6.84375 12 7.25C12 7.6875 11.6562 8 11.25 8H7.75C7.3125 8 7 7.6875 7 7.25C7 6.84375 7.3125 6.5 7.75 6.5H8.75V2.8125L8.21875 2.96875C7.84375 3.09375 7.40625 2.90625 7.28125 2.5C7.15625 2.125 7.34375 1.6875 7.75 1.5625L9.25 1.0625C9.46875 0.96875 9.71875 1.03125 9.9375 1.15625C10.125 1.3125 10.25 1.53125 10.25 1.75V6.5ZM3.25 15.5H4.25C4.65625 15.5 5 15.8438 5 16.25C5 16.6875 4.65625 17 4.25 17H0.75C0.3125 17 0 16.6875 0 16.25C0 15.8438 0.3125 15.5 0.75 15.5H1.75V11.8125L1.21875 11.9688C0.84375 12.0938 0.40625 11.9062 0.28125 11.5C0.15625 11.0938 0.34375 10.6875 0.75 10.5625L2.25 10.0625C2.46875 9.96875 2.71875 10 2.9375 10.1562C3.125 10.2812 3.25 10.5312 3.25 10.75V15.5ZM0 3.25C0 2.03125 1 1 2.25 1H3.75C4.96875 1 6 2.03125 6 3.25V5.75C6 7 4.96875 8 3.75 8H2.25C1 8 0 7 0 5.75V3.25ZM2.25 2.5C1.8125 2.5 1.5 2.84375 1.5 3.25V5.75C1.5 6.1875 1.8125 6.5 2.25 6.5H3.75C4.15625 6.5 4.5 6.1875 4.5 5.75V3.25C4.5 2.84375 4.15625 2.5 3.75 2.5H2.25ZM9.75 10C10.9688 10 12 11.0312 12 12.25V14.75C12 16 10.9688 17 9.75 17H8.25C7 17 6 16 6 14.75V12.25C6 11.0312 7 10 8.25 10H9.75ZM7.5 14.75C7.5 15.1875 7.8125 15.5 8.25 15.5H9.75C10.1562 15.5 10.5 15.1875 10.5 14.75V12.25C10.5 11.8438 10.1562 11.5 9.75 11.5H8.25C7.8125 11.5 7.5 11.8438 7.5 12.25V14.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaBinaryIcon;
