import { MasterTemplate, useMasterTemplateQuery } from '../../../../api/generated';
import { useState } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { MasterTemplateViewTab } from './MasterTemplateTypes';
import { TabMenu } from 'primereact/tabmenu';
import Overlay from '../../../../components/Overlay';
import FaCrownIcon from '../../../../components/Icons/FaCrownIcon';
import DetailsTab from './DetailsTab';
import ReferencesTab from './ReferencesTab';

type Props = {
  id: number;
};

export default function MasterTemplateView(props: Props) {
  const { data, isLoading } = useMasterTemplateQuery({ id: props.id.toString() });
  const masterTemplate = data?.masterTemplateById;

  const [activeIndex, setActiveIndex] = useState<number>(MasterTemplateViewTab.Details);
  const tabItems: MenuItem[] = [{ label: 'Details' }, { label: 'References' }];

  if (masterTemplate) {
    return (
      <div className="sidebar-entry-view">
        <div className="header">
          <div className="icon-only">
            <FaCrownIcon />
          </div>
          <div className="name">{masterTemplate.name}</div>
        </div>
        <div className="content-tabs">
          <TabMenu model={tabItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
        <div className="content">
          {activeIndex === MasterTemplateViewTab.Details && (
            <DetailsTab masterTemplate={masterTemplate as MasterTemplate} />
          )}
          {activeIndex === MasterTemplateViewTab.References && <ReferencesTab id={masterTemplate.masterTemplateId} />}
        </div>
      </div>
    );
  } else {
    return isLoading ? <Overlay label="Loading master template" /> : <>Master Template not found!</>;
  }
}
