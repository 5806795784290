import { Image as ImageDto } from '../../../../../api/generated';

type Props = {
  image: ImageDto;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <Field label="Name" value={props.image.name} />
        <Field label="Description" value={props.image.description} />
        <Field label="Alternate text" value={props.image.alternateText} />
        <Field label="Width" value={props.image.width} />
        <Field label="Height" value={props.image.height} />
        <Field
          label="Image"
          value={
            <img
              src={props.image.source!}
              alt={props.image.alternateText}
              style={{ maxWidth: '457px', marginRight: '1rem' }}
            />
          }
        />
      </div>
    </>
  );
}

function Field(props: { label: string; value: any }) {
  return (
    <div className="field">
      <label>{props.label}</label>
      <div>{props.value}</div>
    </div>
  );
}

export default DetailsTab;
