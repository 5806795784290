import { faEnvelopeOpen, faMobile, faQuestion, faSms } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../../app/store';
import { selectParameterStatistics } from '../../../../pages/EntryPointEditor/Slices/EntryPointParametersSlice';
import { SafeContentParameters } from '../../editorTypes';
import SelectButton from '../../../SelectButton';
import StatisticsSemaphore from '../../../StatisticsSemaphore';

function NotificationTypeSection({
  notificationType,
  value,
  onChange,
}: {
  notificationType?: SafeContentParameters;
  value: string;
  onChange: (id: number, value: string) => void;
}) {
  const enumItems = notificationType?.parameter?.enumItems || [];
  const statistics = useAppSelector(selectParameterStatistics(notificationType?.parameterId));

  return enumItems.length > 0 ? (
    <div className="field">
      <div className="title">Notification type</div>
      <div className="options">
        {enumItems?.map((i) => {
          const messageTypeStatistics = statistics?.values.find((v) => v.value === i.key);

          return (
            i.enabled && (
              <SelectButton
                key={i.key}
                value={i.key}
                onClick={(value: string) => onChange(2, value)}
                selected={i.key === value}
                unselectable={messageTypeStatistics !== undefined}
                icon={<FontAwesomeIcon icon={getMessageTypeIcon(i.key)} />}
              >
                {i.value}
                {statistics && !messageTypeStatistics && <Statistics maxContentCount={0} missingContentCount={0} />}
                {messageTypeStatistics && <Statistics {...messageTypeStatistics} />}
              </SelectButton>
            )
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
}

function Statistics({
  maxContentCount,
  missingContentCount,
}: {
  maxContentCount: number;
  missingContentCount: number;
}) {
  return (
    <div className="semaphore">
      <StatisticsSemaphore maxContentCount={maxContentCount} missingContentCount={missingContentCount} />
    </div>
  );
}

function getMessageTypeIcon(type: string) {
  switch (type) {
    case 'ntf':
      return faMobile;
    case 'email':
      return faEnvelopeOpen;
    case 'sms':
      return faSms;
    default:
      return faQuestion;
  }
}

export default NotificationTypeSection;
