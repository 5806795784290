import { ActionType } from '../../../common/actionTypes';
import FaMousePointerIcon from '../../../components/Icons/FaMousePointerIcon';
import NoItemSelected from '../../../components/NoItemSelected';
import EntryPointView from './EntryPointView';
import EntryPointAdd from './EntryPointAdd';
import EntryPointEdit from './EntryPointEdit';

type Props = {
  entrypointId: number | undefined;
  sidebarVisible: boolean;
  onSidebarClose: () => void;
  action: ActionType;
  setAction: (action: ActionType) => void;
  refetch: () => void;
};

function EntryPointSidebar({ entrypointId, sidebarVisible, onSidebarClose, action, setAction, refetch }: Props) {
  if (action === ActionType.Add) {
    return (
      <EntryPointAdd
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (entrypointId && action === ActionType.Edit) {
    return (
      <EntryPointEdit
        entryPointId={entrypointId}
        sidebarVisible={sidebarVisible}
        onSidebarClose={onSidebarClose}
        onConfirm={() => {
          setAction(ActionType.View);
          refetch();
        }}
      />
    );
  } else if (entrypointId && action === ActionType.View) {
    return <EntryPointView id={entrypointId} />;
  } else {
    return (
      <NoItemSelected
        icon={<FaMousePointerIcon />}
        title={'No entry point selected'}
        note={'Select any to see its details'}
      />
    );
  }
}

export default EntryPointSidebar;
