import Overlay from '../Overlay';

type Props = {
  sidebar?: React.ReactNode;
  sidebarPosition?: 'left' | 'right';
  children: React.ReactNode;
  isLoading?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
};

function MainLayoutWithSidebar(props: Props) {
  return (
    <div className="main-layout-with-sidebar">
      {props.sidebarPosition === 'left' ? (
        <>
          <div className="main-sidebar left">{props.sidebar}</div>
          <div className="main-content" ref={props.contentRef}>
            {props.isLoading ? <Overlay isFullscreen label="Loading data..." /> : props.children}
          </div>
        </>
      ) : (
        <>
          <div className="main-content" ref={props.contentRef}>
            {props.isLoading ? <Overlay isFullscreen label="Loading data..." /> : props.children}
          </div>
          <div className="main-sidebar right">{props.sidebar}</div>
        </>
      )}
    </div>
  );
}

export default MainLayoutWithSidebar;
