import { useMap } from 'usehooks-ts';
import { Parameter, ParameterInput } from '../../../../api/generated';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRotate } from '@fortawesome/pro-regular-svg-icons';
import ParameterSampleValue from './parameterSampleValue';
import { SafeContentParameters } from '../../editorTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDataHolderNonDecisionParams } from '../../editorUtils';

type Props = {
  templateId: number;
  parameters: SafeContentParameters[];
  onParameterValueChanged: (parameterInputs: ParameterInput[]) => void;
  initialParameterInputs: ParameterInput[];
};

export default function ParameterSampleValues(props: Props) {
  const [customSampleValues, { reset, set, remove }] = useMap<number, string>();
  const [update, setUpdate] = useState<boolean>(false);

  const combinedParameters = useMemo(() => {
    const combinedParamMap = new Map();

    getDataHolderNonDecisionParams(props.parameters).forEach((param) => {
      if (param?.parameter) {
        const parameter = param.parameter as Parameter;
        combinedParamMap.set(parameter.parameterId, parameter);
      }
    });

    return Array.from(combinedParamMap.values());
  }, [props.parameters]);

  const handleParameterValueChanged = useCallback(() => {
    props.onParameterValueChanged(Array.from(customSampleValues, ([key, value]) => ({ parameterId: key, value })));
  }, [customSampleValues, props]);

  useEffect(() => {
    props.initialParameterInputs.forEach(({ parameterId, value }) => value && set(parameterId, value));
  }, [props.initialParameterInputs, set]);

  /*
  // This useEffect will take the effect just when the update state is true to prevent unnecessary calls when typing.
   */
  useEffect(() => {
    if (update) {
      handleParameterValueChanged();
      setUpdate(false);
    }
  }, [customSampleValues, handleParameterValueChanged, update]);

  return (
    <div className="flex flex-column h-full">
      <div className="flex p-3 gap-3 border-bottom-gray">
        <Button
          type="button"
          className="p-button-sm"
          icon={<FontAwesomeIcon icon={faCheck} />}
          disabled={!customSampleValues.size}
          onClick={handleParameterValueChanged}
        >
          Apply sample values
        </Button>

        <Button
          type="button"
          className="p-button-sm"
          icon={<FontAwesomeIcon icon={faRotate} />}
          disabled={!customSampleValues.size}
          onClick={() => {
            reset();
            setUpdate(true);
          }}
        >
          Use default values
        </Button>
      </div>
      <div className="flex-grow-1 overflow-auto">
        {combinedParameters &&
          combinedParameters.map((parameter) => {
            return (
              <ParameterSampleValue
                key={parameter.parameterId}
                parameter={parameter}
                isHighlighted={customSampleValues.has(parameter.parameterId)}
                customSampleValue={customSampleValues.get(parameter.parameterId)}
                onValueChange={(value) => set(parameter.parameterId, value)}
                onValueReset={() => {
                  remove(parameter.parameterId);
                  setUpdate(true);
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
