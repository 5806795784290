import { MasterTemplate } from '../../../../../api/generated';
import ValueOrPlaceholder from '../../../../../components/ValueOrPlaceholder';

type Props = {
  masterTemplate: MasterTemplate;
};

export default function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.masterTemplate.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.masterTemplate.description} />
          </div>
        </div>
      </div>
    </>
  );
}
